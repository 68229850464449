import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import AuthContext from '../../store/auth-context';
import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import WorkflowMessage from '../UI/WorkflowMessage';
import PageTitleAndButtons from '../UI/PageTitleAndButtons';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/styled-table.module.css';

const UserList = (props) => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const roleFilterRef = useRef();
  const statusFilterRef = useRef();
  const searchRef = useRef();

  const { onAddNew, onEdit, onShowDetails, pageTitle = 'Users' } = props;

  const defaultStatusFilter = 'Active';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'users/include/orginfo', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(responseData.data.users);

        const transformedUsers = responseData.data.users.map((user) => {
          return {
            userUuid: user.userUuid,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            status: user.status,
            roleType: user.roleType,
            orgName: user.orgName ? user.orgName : '',
          };
        });

        setUsers(transformedUsers);

        const filteredUsers = transformedUsers.filter((user) => user.status === 'Active');
        setDisplayedUsers(filteredUsers);

        // setDisplayedUsers(transformedUsers);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, refreshData, dashboardUrl, history]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Team/Restaurant</th>
        <th>Email</th>
        <th>Role</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedUsers.map((user, index) => (
      <tr key={index}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.orgName}</td>
        <td>{user.email}</td>
        <td>{user.roleType}</td>
        <td>{user.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            {/* <Link to={`/admin-dashboard/users/details/${user.userUuid}`} className='button small'>
              Details
            </Link> */}
            <button
              onClick={() => {
                onShowDetails(user.userUuid);
              }}
              className='button small'
            >
              Details
            </button>
            <button
              onClick={() => {
                onEdit(user.userUuid);
              }}
              className='button small'
            >
              Edit
            </button>
            {/* <Link to={`/admin-dashboard/users/edit/${user.userUuid}`} className='button small'>
              Edit
            </Link> */}
            {user.status !== 'Deleted' && (
              // <button data-user-uuid={user.userUuid} onClick={deleteUserHandler} className='small'>
              //   Delete
              // </button>
              <button
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure you want to delete this record.',
                    // subTitle: 'It will be permanently deleted.',
                    subTitle:
                      'It will NOT be permanently deleted.  You can edit the Status later if need be.',
                    onConfirm: () => {
                      deleteHandler(user.userUuid);
                    },
                  });
                }}
                className='small'
              >
                Delete
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const deleteHandler = (userUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    // console.log(' - handleDelete - userUuid = ' + userUuid);
    deleteUserHandler(userUuid);
    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteUserHandler = (userUuid) => {
    console.log(' -- deleteUserHandler -- ');
    // const userUuid = event.target.getAttribute('data-user-uuid');
    console.log('userUuid = ' + userUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);

        setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'success' });

        setRefreshData(true);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    deleteData().catch(console.error);
  };

  const clearFilterHandler = () => {
    roleFilterRef.current.value = 'Show All';
    statusFilterRef.current.value = defaultStatusFilter;

    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let roleFilter = roleFilterRef.current.value;
    let statusFilter = statusFilterRef.current.value;

    let searchValue = searchRef.current ? searchRef.current.value.toLowerCase() : '';

    // console.log(' -- filterDisplayedRecords -- ');
    // console.log({ roleFilter: roleFilter, statusFilter: statusFilter });

    if (roleFilter === 'Show All' && statusFilter === 'Show All' && searchValue === '') {
      setDisplayedUsers(users);
    } else {
      let filteredUsers = users;
      let roleFilteredUsers = users;

      if (roleFilter === 'Show All') {
        roleFilteredUsers = users;
      } else {
        roleFilteredUsers = filteredUsers.filter((user) => user.roleType === roleFilter);
      }

      if (statusFilter === 'Show All') {
        filteredUsers = roleFilteredUsers;
      } else {
        filteredUsers = roleFilteredUsers.filter((user) => user.status === statusFilter);
      }

      if (searchValue !== '') {
        filteredUsers = filteredUsers.filter(
          (user) =>
            (user.firstName !== null && user.firstName.toLowerCase().includes(searchValue)) ||
            (user.lastName !== null && user.lastName.toLowerCase().includes(searchValue)) ||
            (user.orgName !== null && user.orgName.toLowerCase().includes(searchValue))
        );
      }

      setDisplayedUsers(filteredUsers);
    }
  };

  const clearSearchHandler = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    filterDisplayedRecords();
  };

  // const addUserHandler = () => {
  //   console.log('addUserHandler');
  //   history.push('/admin-dashboard/users/add');
  // };

  return (
    <Box>
      {!isLoading && !isError ? (
        <PageTitleAndButtons pageTitle={pageTitle}>
          <Box className='button-holder-no-pb'>
            <Button variant='contained' size='custom' color='accent' onClick={onAddNew}>
              Create New Admin
            </Button>
          </Box>
        </PageTitleAndButtons>
      ) : null}

      {!isLoading && !isError ? (
        <Box className='mb-20'>
          <Box>
            <label htmlFor='search'>
              Search <span className='small-text'>(by First Name, Last Name, Team/Restaurant)</span>
            </label>
          </Box>

          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              minWidth: '300px',
              height: '40px',
              // ml: '10px',
              // border: '1px solid red',
            }}
          >
            <FormControls.InputControl
              type='text'
              name='search'
              id='search'
              // label='Search'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ flexGrow: '1', mr: '2rem' }}
              onChange={filterDisplayedRecords}
              inputRef={searchRef}
            />
            <FormControls.ButtonControl
              name='clearSearch'
              text='Clear'
              variant='contained'
              size='small'
              endIcon={<SearchIcon />}
              onClick={clearSearchHandler}
              sx={{ p: '2rem', fontWeight: 'bold', height: '4rem' }}
            />
          </Box>
        </Box>
      ) : null}

      {!isLoading && !isError ? (
        <Box className='button-holder'>
          <Box>
            <Box>
              <label htmlFor='filter-by-role'>Role</label>
            </Box>
            <select id='filter-by-role' ref={roleFilterRef} onChange={filterDisplayedRecords}>
              <option>Show All</option>
              <option>User</option>
              <option>Vendor</option>
              <option>Admin</option>
            </select>
          </Box>
          <Box>
            <Box>
              <label htmlFor='filter-by-status'>Status</label>
            </Box>
            {displayListFilterSelect(
              'filter-by-status',
              statusFilterRef,
              filterDisplayedRecords,
              Constants.STATUS_ARRAY,
              defaultStatusFilter
            )}
          </Box>
          <Box className='align-bottom'>
            <button className='small' onClick={clearFilterHandler}>
              Clear Filters
            </button>
          </Box>
        </Box>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError && users.length === 0 ? (
        <WorkflowMessage type='nodata' message='No Users Found...' />
      ) : null}

      {!isLoading && users.length > 0 ? (
        <div className='table-holder'>
          <table className={`${classes['styled-table']} ${classes['full-width']}`}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </div>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default UserList;

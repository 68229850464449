import React, { useContext, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';

const ImageUpload = (props) => {
  const { linkedUuid, linkType, imageUuid = null, onCancel } = props;
  const authCtx = useContext(AuthContext);

  // console.log('linkedUuid', linkedUuid);

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  // const orgFolder = authCtx.orgDetails.orgUuid;
  // baseApiUploadedImagesPath += orgFolder + '/';
  baseApiUploadedImagesPath += linkedUuid + '/';
  // console.log(authCtx.orgDetails);
  // console.log('baseApiUploadedImagesPath');
  // console.log(baseApiUploadedImagesPath);

  const displayUploadedImage = false;
  const displayCancelButton = false;

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('No Image File Selected');
  const [uploadedFilename, setUploadedFilename] = useState('');
  const [uploadImageButtonDisabled, setUploadImageButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const onFileChange = (event) => {
    // console.log('-- onFileChange -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    setSelectedImageName(files[0].name);
    setUploadImageButtonDisabled(false);

    fileReader.onload = (event) => {
      setSelectedImage(event.target.result);
    };

    // fileReader.onload = () => {
    //   setSelectedImage(fileReader.result);
    // }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setIsLoading(true);
    setIsError(false);

    // const enteredName = nameRef.current.value;
    // const enteredDescription = descriptionRef.current.value;
    // const enteredSortOrder = sortOrderRef.current.value;
    // const enteredStatus = statusRef.current ? statusRef.current.value : 'Active';

    // const linkType = 'ReviewImage';
    const name = 'Restaurant Review Image';

    // Todo:  Add Validation.

    let apiURL = '';
    let apiMethod = '';

    apiURL = authCtx.baseApiUrl + 'imageupload';
    apiMethod = 'POST';

    if (imageUuid !== '' && imageUuid !== undefined && imageUuid !== null) {
      apiURL = authCtx.baseApiUrl + 'imageupload/' + imageUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'imageupload';
      apiMethod = 'POST';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);
    // console.log(' - linkType = ' + linkType);
    // console.log(' - linkedUuid = ' + linkedUuid);
    // console.log(' - selectedImage = ' + selectedImage);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        link_type: linkType,
        linked_uuid: linkedUuid,
        name: name,
        selected_image: selectedImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          setUploadedFilename(responseData.data.image_upload.filename);

          // console.log('responseData.data.image_upload.filename');
          // console.log(responseData.data.image_upload.filename);

          onCancel();
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            let errorMessage = responseData.messages[0]
              ? responseData.messages[0]
              : 'Ooops, something happened.  Try again.';
            // console.log(responseData.messages);
            // console.log('errorMessage');
            // console.log(errorMessage);
            setNotify({
              isOpen: true,
              message: errorMessage,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            // console.log('**** ERROR *****');
            setNotify({
              isOpen: true,
              message: 'Ooops, something happened.  Try again.',
              type: 'error',
            });
          }
        });
      }
    });

    setIsLoading(false);
  };

  const cancelHandler = () => {
    onCancel();
  };

  return (
    <Box sx={{ py: '1rem', height: '80vh' }}>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant='contained' component='label' size='small'>
          Select Image File
          <input
            hidden
            // accept='image/*'
            accept='image/png, image/jpeg'
            multiple
            type='file'
            name='image'
            onChange={(event) => onFileChange(event)}
          />
        </Button>
      </Box>

      {/* <form encType='multiport/form-data' onSubmit={submitHandler}>
        <Box sx={{ pb: '2rem' }}>
          <Typography variant='labelInline'>Select Image File</Typography>
          <input type='file' name='image' onChange={onFileChange} />
        </Box>

        <Box>
          <button type='submit' className='small'>
            Upload Image
          </button>
        </Box>
      </form> */}

      <Box sx={{ pt: '2rem' }}>
        <Typography variant='labelInline'>Seletected Image:</Typography>
        <Typography>{selectedImageName}</Typography>
      </Box>

      <Box align='center' sx={{ pt: '2rem' }}>
        <Button
          variant='contained'
          size='small'
          onClick={submitHandler}
          disabled={uploadImageButtonDisabled}
        >
          Upload Image
        </Button>
      </Box>

      {displayCancelButton ? (
        <Box align='center' sx={{ pt: '2rem' }}>
          <Button variant='contained' size='small' onClick={cancelHandler}>
            Cancel
          </Button>
        </Box>
      ) : null}

      {displayUploadedImage && uploadedFilename ? (
        <Box sx={{ pt: '4rem', maxWidth: '80rem' }}>
          <img src={baseApiUploadedImagesPath + uploadedFilename} alt='Upload Test' />
          {/* <Typography>{uploadedFilename}</Typography> */}
        </Box>
      ) : null}

      {/* <Box>
        <Typography>linkedUuid: {linkedUuid}</Typography>
      </Box> */}

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default ImageUpload;

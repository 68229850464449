import { Box, Paper, Typography } from '@mui/material';

const StandardPageWrapper = (props) => {
  const { title, usePaper = false } = props;

  // console.log('StandardPageWrapper - title = ' + title + ' --- usePaper = ' + usePaper);

  return (
    <Box className='content-container'>
      {usePaper ? (
        <Paper elevation={8} sx={{ maxWidth: '1200px', p: '2rem' }}>
          {title ? (
            <Typography variant='h1' component='h1' className='header'>
              {title}
            </Typography>
          ) : null}

          {props.children}
        </Paper>
      ) : null}

      {!usePaper ? (
        <>
          {title ? (
            <Typography variant='h1' component='h1' className='header'>
              {title}
            </Typography>
          ) : null}
          {props.children}
        </>
      ) : null}
    </Box>
  );
};

export default StandardPageWrapper;

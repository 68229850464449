import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import Notification from '../UI/Notification';
import FormControls from '../FormControls/FormControls';
import classes from '../../CSS/standard-form.module.css';

const ChangePasswordForm = (props) => {
  const authCtx = useContext(AuthContext);

  const userUuid = authCtx.userUuid;

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const newPasswordConfirmRef = useRef();

  // Temp
  const displayOriginalForm = false;

  useEffect(() => {
    if (props.setFocus) {
      currentPasswordRef.current.focus();
    }
  }, [props.setFocus]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({ isOpen: true, message: 'Sending Request...', type: 'info' });

    const enteredCurrentPassword = currentPasswordRef.current.value;
    const enteredNewPassword = newPasswordRef.current.value;
    const enteredNewPasswordConfirm = newPasswordConfirmRef.current.value;

    // console.log(' - enteredCurrentPassword = ' + enteredCurrentPassword);
    // console.log(' - enteredNewPassword = ' + enteredNewPassword);
    // console.log(' - enteredNewPasswordConfirm = ' + enteredNewPasswordConfirm);

    // Todo:  Add Validation.
    // Make sure that the Passwords Match.
    if (enteredNewPassword !== enteredNewPasswordConfirm) {
      setNotify({ isOpen: true, message: 'The Passwords entered do not match.', type: 'error' });
      return;
    }

    fetch(authCtx.baseApiUrl + 'changepassword/' + userUuid, {
      method: 'PATCH',
      body: JSON.stringify({
        current_password: enteredCurrentPassword,
        new_password: enteredNewPassword,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        currentPasswordRef.current.value = '';
        newPasswordRef.current.value = '';
        newPasswordConfirmRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ** ');
          // console.log(responseData);
          // console.log(' -- responseData.messages = ' + responseData.messages);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'success' });
          }
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(responseData);

          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({ isOpen: true, message: 'Failed to Reset Password', type: 'error' });
          }
        });
      }
    });
  };

  return (
    <>
      <Paper elevation={4} sx={{ maxWidth: '60rem', p: '2rem', m: '0 auto' }}>
        <Typography variant='h5' className={classes['mui-form-header']}>
          Change Password
        </Typography>
        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', pt: '4rem' }}>
            <FormControls.InputControl
              type='password'
              name='current_password'
              label='Current Password'
              inputRef={currentPasswordRef}
              autoComplete='current-password'
              required
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='password'
              name='new_password'
              label='New Password'
              inputRef={newPasswordRef}
              autoComplete='new-password'
              required
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='password'
              name='new_password_confirm'
              label='Confirm New Password'
              inputRef={newPasswordConfirmRef}
              autoComplete='new-password'
              required
              className={classes['mui-control']}
            />
          </Box>
          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl text='Change Password' type='submit' />
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />

      {displayOriginalForm ? (
        <div className='form-holder'>
          <div className={classes['form-container']}>
            <h1>Change Password</h1>
            <form onSubmit={submitHandler}>
              <div className={classes.control}>
                <label htmlFor='current_password'>Current Password</label>
                <input
                  type='password'
                  id='current_password'
                  ref={currentPasswordRef}
                  autoComplete='current-password'
                  required
                />
              </div>
              <div className={classes.control}>
                <label htmlFor='new_password'>New Password</label>
                <input
                  type='password'
                  id='new_password'
                  ref={newPasswordRef}
                  autoComplete='new-password'
                  required
                />
              </div>
              <div className={classes.control}>
                <label htmlFor='new_password_confirm'>Confirm New Password</label>
                <input
                  type='password'
                  id='new_password_confirm'
                  ref={newPasswordConfirmRef}
                  autoComplete='new-password'
                  required
                />
              </div>
              <div className={classes.actions}>
                <button>Change Password</button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChangePasswordForm;

import React from 'react';

const OrderContext = React.createContext({
  orderMode: '',
  isReorder: false,
  // orderUuid: '',
  // orderNumber: '',
  // restaurantUuid: '',
  // restaurantName: '',
  // deliveryLocationUuid: '',
  // deliveryLocationName: '',
  // deliveryCity: '',
  // deliveryState: '',
  // deliveryZipcode: '',
  // deliveryDate: '',
  // deliveryTime: '',
  // headcount: 1,
  // status: 'Draft',
  orderDetails: {},
  orderContacts: [],
  deliveryLocation: {},
  restaurant: {},
  restaurantContacts: [],
  items: [],
  totalAmount: 0,
  subtotal: 0,
  tax: 0,
  total: 0,
  taxRate: 0.0,
  teamCharge: 0,
  orderMessages: [],
  updateOrderData: (orderData) => {},
  resetOrder: () => {},
  addOrderItem: (item) => {},
  removeOrderItem: (orderItemUuid) => {},
  updateOrderItem: (item) => {},
  deleteOrderItem: (orderItemUuid) => {},
  clearOrderItems: () => {},
  replaceOrderItems: (items) => {},
  setOrderDetails: (orderDetails) => {},
  setDeliveryLocation: (deliveryLocation) => {},
  setRestaurant: (restaurant) => {},
  setOrderMessages: (orderMessages) => {},
});

export default OrderContext;

import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import classes from '../CSS/Table.module.css';

export function useTable(records, headerCells, filterFunction) {
  // console.log('############ useTable ##########');
  const pages = [5, 10, 25, 50];
  const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    setPage(0);
  }, [records.length, rowsPerPage]);

  const TblContainer = (props) => {
    return <Table className={classes['table']}>{props.children}</Table>;
  };

  const TblHeader = (props) => {
    const handleSortRecords = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headerCells.map((heading) => (
            <TableCell
              key={heading.id}
              sortDirection={orderBy === heading.id ? order : false}
              align={heading.align ? heading.align : 'left'}
            >
              {heading.disableSorting ? (
                heading.label
              ) : (
                <TableSortLabel
                  active={orderBy === heading.id}
                  direction={orderBy === heading.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRecords(heading.id);
                  }}
                >
                  {heading.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => {
    return (
      <TablePagination
        component='div'
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={records.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    );
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPagingAndSorting = () => {
    // return records.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    // return stableSort(records, getComparator(order, orderBy)).slice(
    //   page * rowsPerPage,
    //   (page + 1) * rowsPerPage
    // );
    return stableSort(filterFunction.function(records), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  return { TblContainer, TblHeader, TblPagination, recordsAfterPagingAndSorting };
}

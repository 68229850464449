import InputControl from './InputControl';
import RadioGroupControl from './RadioGroupControl';
import SelectControl from './SelectControl';
import SimpleSelectControl from './SimpleSelectControl';
import CheckboxControl from './CheckboxControl';
import CheckboxListControl from './CheckboxListControl';
import DatePickerControl from './DatePickerControl';
import ButtonControl from './ButtonControl';
import ActionButtonControl from './ActionButtonControl';
import AutocompleteControl from './AutocompleteControl';
import AutocompleteMultipleControl from './AutocompleteMultipleControl';

const FormControls = {
  InputControl,
  RadioGroupControl,
  SelectControl,
  SimpleSelectControl,
  CheckboxControl,
  CheckboxListControl,
  DatePickerControl,
  ButtonControl,
  ActionButtonControl,
  AutocompleteControl,
  AutocompleteMultipleControl,
};

export default FormControls;

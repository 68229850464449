import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import classes from './CartItem.module.css';

const CartItem = (props) => {
  const { item, onAdd, onRemove } = props;

  // console.log('item', item);

  const price = +item.price;
  let formattedPrice = typeof price === 'number' ? price.toFixed(2) : price;
  formattedPrice = `$${formattedPrice}`;

  return (
    <Box component='div' className={classes['cart-item']}>
      <Typography variant='h3' component='h2'>
        {item.name}
        {item.servingSize ? (
          // <span className={classes['extra-info']}>{'(' + item.servingSize + ')'}</span>
          <Typography className='small-text pt-5'>
            {'(Feeds ' + item.feeds + ' - ' + item.servingSize + ')'}
          </Typography>
        ) : (
          <Typography className='small-text pt-5'>{'(Feeds ' + item.feeds + ')'}</Typography>
        )}
      </Typography>

      <Box component='div' className={classes['details']}>
        <Box component='div' className={classes['summary']}>
          <Typography className={classes['price']}>{formattedPrice}</Typography>
          <Typography variant='body1' component='span' className={classes['quantity']}>
            x {item.quantity}
          </Typography>
        </Box>
        <Box component='div' className={classes['actions']}>
          <Button variant='outlined' color='primary' size='small' onClick={onRemove}>
            <RemoveIcon />
          </Button>
          <Button variant='outlined' color='primary' size='small' onClick={onAdd}>
            <AddIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CartItem;

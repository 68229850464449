import TeamDetailsTable from './TeamDetailsTable';
import TeamDetailsCard from './TeamDetailsCard';

const TeamDetails = (props) => {
  const { teamDetails, onEdit, onEditInPopup, baseApiUploadedImagesPath } = props;

  const displayDetailsUsing = 'Card';

  // console.log('######## TeamDetails ########');
  // console.log('displayDetailsUsing', displayDetailsUsing);

  return (
    <>
      {teamDetails && displayDetailsUsing === 'Table' ? (
        <>
          <div className='button-holder'>
            <button onClick={() => onEdit(`${teamDetails.teamUuid}`)} className='small'>
              Edit
            </button>
            <button onClick={() => onEditInPopup()} className='small'>
              Edit In Popup
            </button>
          </div>
          <TeamDetailsTable details={teamDetails} />
        </>
      ) : null}

      {teamDetails && displayDetailsUsing === 'Card' ? (
        <TeamDetailsCard
          teamDetails={teamDetails}
          onEdit={onEdit}
          onEditInPopup={onEditInPopup}
          baseApiUploadedImagesPath={baseApiUploadedImagesPath}
        />
      ) : null}
    </>
  );
};

export default TeamDetails;

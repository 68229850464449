import React from 'react';
import { Box, Typography } from '@mui/material';

import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';

const DeliveryHoursTable = ({ deliveryHours, textSize = 'normal' }) => {
  const { FormatTimeForDisplay } = useFormatDateAndTime();

  let tableClasses = 'table-simple table-simple-nowrap table-simple-px-10';
  let headerVariant = 'h3';
  if (textSize === 'small') {
    tableClasses = tableClasses + ' small-text';
    headerVariant = 'labelSmall';
  }

  let details = { ...deliveryHours };

  // if (Array.isArray(details) && details.length) {
  if (details) {
    details.mondayStartDisplay = details.mondayStart
      ? FormatTimeForDisplay(details.mondayStart)
      : null;
    details.mondayEndDisplay = details.mondayEnd ? FormatTimeForDisplay(details.mondayEnd) : null;
    details.tuesdayStartDisplay = details.tuesdayStart
      ? FormatTimeForDisplay(details.tuesdayStart)
      : null;
    details.tuesdayEndDisplay = details.tuesdayEnd
      ? FormatTimeForDisplay(details.tuesdayEnd)
      : null;
    details.wednesdayStartDisplay = details.wednesdayStart
      ? FormatTimeForDisplay(details.wednesdayStart)
      : null;
    details.wednesdayEndDisplay = details.wednesdayEnd
      ? FormatTimeForDisplay(details.wednesdayEnd)
      : null;
    details.thursdayStartDisplay = details.thursdayStart
      ? FormatTimeForDisplay(details.thursdayStart)
      : null;
    details.thursdayEndDisplay = details.thursdayEnd
      ? FormatTimeForDisplay(details.thursdayEnd)
      : null;
    details.fridayStartDisplay = details.fridayStart
      ? FormatTimeForDisplay(details.fridayStart)
      : null;
    details.fridayEndDisplay = details.fridayEnd ? FormatTimeForDisplay(details.fridayEnd) : null;
    details.saturdayStartDisplay = details.saturdayStart
      ? FormatTimeForDisplay(details.saturdayStart)
      : null;
    details.saturdayEndDisplay = details.saturdayEnd
      ? FormatTimeForDisplay(details.saturdayEnd)
      : null;
    details.sundayStartDisplay = details.sundayStart
      ? FormatTimeForDisplay(details.sundayStart)
      : null;
    details.sundayEndDisplay = details.sundayEnd ? FormatTimeForDisplay(details.sundayEnd) : null;
  } else {
    details.mondayStartDisplay = '10:15 AM';
    details.mondayEndDisplay = '11:15 PM';
    details.tuesdayStartDisplay = '10:15 AM';
    details.tuesdayEndDisplay = '11:15 PM';
    details.wednesdayStartDisplay = '10:15 AM';
    details.wednesdayEndDisplay = '11:15 PM';
    details.thursdayStartDisplay = '10:15 AM';
    details.thursdayEndDisplay = '11:15 PM';
    details.fridayStartDisplay = '10:15 AM';
    details.fridayEndDisplay = '11:15 PM';
    details.saturdayStartDisplay = '10:15 AM';
    details.saturdayEndDisplay = '11:15 PM';
    details.sundayStartDisplay = '10:15 AM';
    details.sundayEndDisplay = '11:15 PM';
  }

  return (
    <Box>
      <Typography variant={headerVariant} align='center'>
        Delivery Hours
      </Typography>

      <table className={tableClasses}>
        <tbody>
          <tr>
            <td className='table-td-label'>Monday</td>
            {details.mondayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.mondayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.mondayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Tuesday</td>
            {details.tuesdayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.tuesdayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.tuesdayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Wednesday</td>
            {details.wednesdayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.wednesdayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.wednesdayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Thursday</td>
            {details.thursdayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.thursdayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.thursdayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Friday</td>
            {details.fridayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.fridayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.fridayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Saturday</td>
            {details.saturdayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.saturdayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.saturdayEndDisplay}</td>
              </>
            )}
          </tr>
          <tr>
            <td className='table-td-label'>Sunday</td>
            {details.sundayClosed ? (
              <td colSpan='3'>CLOSED</td>
            ) : (
              <>
                <td className='table-td-number'>{details.sundayStartDisplay}</td>
                <td className='table-td-text'>-</td>
                <td className='table-td-number'>{details.sundayEndDisplay}</td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </Box>
  );
};

export default DeliveryHoursTable;

import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AuthContext from '../store/auth-context';
import Notification from '../components/MUI/UI/Notification';
import WorkflowMessage from '../components/UI/WorkflowMessage';
import LoginForm from '../components/MUI/Login/LoginForm';
import ForgotPasswordForm from '../components/MUI/Login/ForgotPasswordForm';
import ResetPasswordForm from '../components/MUI/Login/ResetPasswordForm';

const LoginPage = ({ code = '' }) => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();
  const params = useParams();
  const orderUuid = params.orderUuid ? params.orderUuid : '';
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : '';

  // console.log(
  //   '######## -- LoginPage - orderUuid = ' +
  //     orderUuid +
  //     ' -- restaurantUuid = ' +
  //     restaurantUuid +
  //     ' -- ########'
  // );

  const currentRestaurantUuid = authCtx.selectedRestaurantUuid
    ? authCtx.selectedRestaurantUuid
    : authCtx.orgDetails && authCtx.orgDetails.orgUuid
    ? authCtx.orgDetails.orgUuid
    : '';

  // console.log('### currentRestaurantUuid = ' + currentRestaurantUuid + ' ###');

  // console.log('### authCtx.selectedRestaurantUuid = ' + authCtx.selectedRestaurantUuid + ' ###');
  // console.log('orgDetails', authCtx.orgDetails);
  // console.log('### authCtx.orgDetails.orgUuid = ' + authCtx.orgDetails.orgUuid);

  // if (currentRestaurantUuid && currentRestaurantUuid !== restaurantUuid) {
  //   console.log('##### Restaurants do not match - must load #####');
  // } else {
  //   console.log('##### Restaurants Match #####');
  // }

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [showForm, setShowForm] = useState(isLoggedIn ? '' : 'Login');

  const updateSelectedRestaurantHandler = useCallback(
    (uuid, name, city, state) => {
      // console.log('updateSelectedRestaurantHandler - uuid = ' + uuid + ' -- name = ' + name);
      authCtx.updateSelectedRestaurantValues(uuid, name, city, state);
    },
    [authCtx]
  );

  useEffect(() => {
    const fetchData = async () => {
      // console.log('### RestaurantDetails - useEffect - fetchData ###');
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'restaurants/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(' -- response.ok -- ');
        // console.log('responseData.data.restaurants');
        // console.log(responseData.data.restaurants);
        // console.log(responseData.data);

        const name = responseData.data.restaurants[0].name;
        const city = responseData.data.restaurants[0].city;
        const state = responseData.data.restaurants[0].state;

        updateSelectedRestaurantHandler(restaurantUuid, name, city, state);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    if (
      isLoggedIn &&
      orderUuid &&
      restaurantUuid &&
      currentRestaurantUuid &&
      currentRestaurantUuid !== restaurantUuid
    ) {
      fetchData().catch(console.error);
    }
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    isLoggedIn,
    orderUuid,
    restaurantUuid,
    currentRestaurantUuid,
    updateSelectedRestaurantHandler,
    history,
    dashboardUrl,
  ]);

  useEffect(() => {
    if (isLoggedIn && orderUuid && restaurantUuid) {
      console.log(
        '### - REDIRECT to orders with orderUuuid = ' +
          orderUuid +
          ' -- restaurantUuid = ' +
          restaurantUuid +
          ' - ###'
      );

      history.push(dashboardUrl + 'orders/' + orderUuid);
    }
  }, [history, isLoggedIn, dashboardUrl, orderUuid, restaurantUuid]);

  const toggleShowForm = (form) => {
    // console.log('toggleShowForm - form = ' + form);
    setShowForm(form);
  };

  return (
    <div className='page-container'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <div className='content-container'>
        {showForm === 'Login' ? (
          <LoginForm toggleShowForm={toggleShowForm} setNotify={setNotify} />
        ) : null}
        {showForm === 'ForgotPassword' ? (
          <ForgotPasswordForm toggleShowForm={toggleShowForm} setNotify={setNotify} />
        ) : null}
        {showForm === 'ResetPassword' ? (
          <ResetPasswordForm toggleShowForm={toggleShowForm} setNotify={setNotify} />
        ) : null}

        {showForm === 'Login' && code === 'unauth' ? (
          <div className='message-holder-inline bold-text'>
            Your Session has expired, please log back into Elite Eats.
          </div>
        ) : null}
      </div>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default LoginPage;

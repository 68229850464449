export const displayListFilterSelect = (
  selectId,
  filterRef,
  onChange,
  arrayValues,
  defaultValue = ''
) => {
  // console.log(' -- Helper Function -- ' + selectId + ' ****');
  return (
    <select
      id={selectId}
      ref={filterRef}
      defaultValue={defaultValue}
      onChange={onChange}
      className='filter-control-m'
    >
      <option>Show All</option>
      {arrayValues.map((value, index) => (
        <option key={index}>{value}</option>
      ))}
    </select>
  );
};

// export const displayCitiesFilterSelect = (cityFilterRef, filterDisplayedRecords, cities) => {
//   console.log(' -- Helper Function -- displayCitiesFilterSelect');
//   return (
//     <select id='filter-by-city' ref={cityFilterRef} onChange={filterDisplayedRecords}>
//       <option>Show All</option>
//       {cities.map((city, index) => (
//         <option key={index}>{city}</option>
//       ))}
//     </select>
//   );
// };

// export const displayStatesFilterSelect = (stateFilterRef, filterDisplayedRecords, states) => {
//   console.log(' -- Helper Function -- displayStatesFilterSelect');
//   return (
//     <select id='filter-by-state' ref={stateFilterRef} onChange={filterDisplayedRecords}>
//       <option>Show All</option>
//       {states.map((state, index) => (
//         <option key={index}>{state}</option>
//       ))}
//     </select>
//   );
// };

// export const displayStatusFilterSelect = (statusFilterRef, filterDisplayedRecords, Constants) => {
//   console.log(' -- Helper Function -- displayStatusFilterSelect');
//   return (
//     <select id='filter-by-status' ref={statusFilterRef} onChange={filterDisplayedRecords}>
//       <option>Show All</option>
//       {Constants.STATUS_ARRAY.map((status, index) => (
//         <option key={index}>{status}</option>
//       ))}
//     </select>
//   );
// };

import React from 'react';
import { Alert, Box, Typography } from '@mui/material';

import Team from '../Team/Team';

const TeamSignupComplete = ({ teamUuid }) => {
  return (
    <>
      <Box className='pb-20'>
        <Alert severity='success'>
          <Typography variant='h1' component='h1'>
            Thank you for creating your Elite Eats account.
          </Typography>
          <Typography>
            A member of our onboarding team will be in touch to help finalize your Elite Eats
            Account.
          </Typography>
          <Typography>
            Click the EDIT PROFILE button to enter some Team Catering Instructions or to upload your
            Team Logo.
          </Typography>
        </Alert>
      </Box>

      <Team teamUuid={teamUuid} />
    </>
  );
};

export default TeamSignupComplete;

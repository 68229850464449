import React, { useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import FormControls from '../FormControls/FormControls';
import classes from '../../CSS/standard-form.module.css';

const LoginForm = (props) => {
  const { toggleShowForm, setNotify } = props;

  const authCtx = useContext(AuthContext);
  const emailRef = useRef();
  const passwordRef = useRef();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current.value;

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    fetch(authCtx.baseApiUrl + 'sessions', {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail,
        password: enteredPassword,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.ok) {
          emailRef.current.value = '';
          passwordRef.current.value = '';

          return res.json().then((responseData) => {
            // console.log(' -- login -- ');
            // console.log(responseData);
            const expiresInMilliseconds = +responseData.data.access_token_expires_in * 1000;
            const expiryDateTime = new Date(new Date().getTime() + expiresInMilliseconds);

            const refreshTokenExpiresInMilliseconds =
              +responseData.data.refresh_token_expires_in * 1000;
            const refreshTokenExpiryDateTime = new Date(
              new Date().getTime() + refreshTokenExpiresInMilliseconds
            );

            authCtx.login(
              responseData.data.user_uuid,
              responseData.data.user_name,
              responseData.data.user_status,
              responseData.data.user_role_type,
              responseData.data.session_id,
              responseData.data.access_token,
              expiryDateTime,
              responseData.data.refresh_token,
              refreshTokenExpiryDateTime,
              responseData.data.org_uuid,
              responseData.data.org_name,
              responseData.data.org_type,
              responseData.data.org_status,
              responseData.data.location_uuid,
              responseData.data.location_name,
              responseData.data.location_city,
              responseData.data.location_state,
              responseData.data.location_status,
              responseData.data.user_details,
              responseData.data.org_details,
              responseData.data.multiple_restaurants
            );
          });
        } else {
          return res.json().then((responseData) => {
            if (
              responseData &&
              Array.isArray(responseData.messages) &&
              responseData.messages.length
            ) {
              const messageText = responseData.messages.map((message) => `${message}`).join(', ');
              setNotify({ isOpen: true, message: messageText, type: 'error' });
            } else {
              setNotify({
                isOpen: true,
                message: 'Login Failed - Email or Password Not Valid',
                type: 'error',
              });
            }
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: 'Login Failed - Email or Password Not Valid',
          type: 'error',
        });
      });
  };

  return (
    <>
      <Paper elevation={4} className='standard-form'>
        <Typography variant='h5' className={classes['mui-form-header']}>
          Login
        </Typography>
        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', pt: '4rem' }}>
            <FormControls.InputControl
              type='email'
              name='email'
              label='Email'
              inputRef={emailRef}
              autoComplete='username'
              required
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='password'
              name='password'
              label='Password'
              inputRef={passwordRef}
              autoComplete='current-password'
              required
              className={classes['mui-control']}
            />
          </Box>
          {/* <Box component='div' className={classes.actions}>
            <Link to='/forgot-password'>
              <Typography>Forgot your Password?</Typography>
            </Link>
          </Box> */}

          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl text='Login' type='submit' />
          </Box>

          <Box align='center' sx={{ pt: '2rem' }}>
            <Button variant='text' onClick={() => toggleShowForm('ForgotPassword')}>
              Forgot your Password?
            </Button>
          </Box>
        </form>
        <Divider sx={{ my: '3rem' }} />
        <Box component='div'>
          <Typography align='center'>Don't have an Account?</Typography>
        </Box>
        <Box
          component='div'
          sx={{
            display: 'flex',
            gap: '2rem',
            justifyContent: 'center',
          }}
        >
          <Link to='/team-main'>
            <Typography component='div'>Create a Team Account</Typography>
          </Link>
          <Typography component='div'>or</Typography>
          <Link to='/vendor-main'>
            <Typography component='div'>Create a Vendor Account</Typography>
          </Link>
        </Box>
      </Paper>

      {/* <Notification notify={notify} setNotify={setNotify} /> */}
    </>
  );
};

export default LoginForm;

import React from 'react';
import { Box, FormControl, FormControlLabel, Checkbox } from '@mui/material';

const CheckboxControl = (props) => {
  const { name, label, value, onChange } = props;

  const convertToDefaultEventParameter = (name, value) => {
    // console.log(' - convertToDefaultEventParameter - ');
    // console.log(' - name = ' + name + ' -- value = ' + value + ' - ');
    return { target: { name, value } };
  };

  return (
    <Box>
      <FormControl>
        <FormControlLabel
          label={label}
          labelPlacement='end'
          control={
            <Checkbox
              name={name}
              color='primary'
              checked={value}
              onChange={(event) =>
                onChange(convertToDefaultEventParameter(name, event.target.checked))
              }
              sx={{ pt: '0', pr: '1rem' }}
            />
          }
        />
      </FormControl>
    </Box>
  );
};

export default CheckboxControl;

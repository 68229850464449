import React from 'react';

import { Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const SelectControl = (props) => {
  const { name, label, value, onChange, options, error, initialOptionValue, makeRequired } = props;

  // console.log('SelectControl');
  // console.log('options');
  // console.log(options);
  // console.log('initialOptionValue');
  // console.log(initialOptionValue);

  return (
    <>
      {makeRequired ? (
        <Box>
          <FormControl fullWidth {...(error ? { error: true } : null)} size='small' required>
            <InputLabel>{label}</InputLabel>
            <Select variant='outlined' label={label} name={name} value={value} onChange={onChange}>
              {/* {includeNone ? <MenuItem value=''>None</MenuItem> : null} */}
              {initialOptionValue ? <MenuItem value=''>{initialOptionValue}</MenuItem> : null}
              {options.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
            {error ? <FormHelperText>{error}</FormHelperText> : null}
          </FormControl>
        </Box>
      ) : null}

      {!makeRequired ? (
        <Box>
          <FormControl fullWidth {...(error ? { error: true } : null)} size='small'>
            <InputLabel shrink>{label}</InputLabel>
            <Select
              variant='outlined'
              label={label}
              name={name}
              value={value}
              onChange={onChange}
              notched
            >
              {/* {includeNone ? <MenuItem value=''>None</MenuItem> : null} */}
              {initialOptionValue ? <MenuItem value=''>{initialOptionValue}</MenuItem> : null}
              {options.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
            {error ? <FormHelperText>{error}</FormHelperText> : null}
          </FormControl>
        </Box>
      ) : null}
    </>
  );
};

export default SelectControl;

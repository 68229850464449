import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
// import * as Constants from '../../helpers/Constants';
import { displayListFilterSelect } from '../../helpers/FilterListHelperFunctions';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from '../CSS/styled-table.module.css';

const VendorDeliveryLocationList = (props) => {
  // console.log(' ***** VendorDeliveryLocationList ***** ');
  const { restaurantUuid, restaurantDetails } = props;
  const authCtx = useContext(AuthContext);
  const selectedRestaurantState = authCtx.selectedRestaurantState
    ? authCtx.selectedRestaurantState
    : null;

  const dashboardUrl = authCtx.dashboardUrl;

  const defaultState = restaurantDetails ? restaurantDetails.state : '';

  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('defaultState = ' + defaultState);

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [displayedDeliveryLocations, setDisplayedDeliveryLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);

  const cityFilterRef = useRef();
  const stateFilterRef = useRef();

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(
        authCtx.baseApiUrl + 'restaurantdeliverylocations/' + restaurantUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        // console.log('response.ok');
        // console.log(responseData);
        // console.log('responseData.data.delivery_locations');
        // console.log(responseData.data.delivery_locations);

        const transformedLocations = responseData.data.delivery_locations.map((location) => {
          return {
            deliveryLocationUuid: location.deliveryLocationUuid,
            name: location.name,
            description: location.description,
            leagues: location.leagues,
            address:
              location.city !== '' && location.state !== ''
                ? location.city + ', ' + location.state
                : location.city !== ''
                ? location.city
                : location.state !== ''
                ? location.state
                : '',
            address1: location.address1,
            address2: location.address2,
            city: location.city,
            state: location.state,
            email: location.email,
            phone: location.phone,
            deliveryInstructions: location.delivery_instructions,
            status: location.status,
            isGlobal: location.isGlobal,
            willDeliverTo: location.willDeliverTo,
          };
        });

        setDeliveryLocations(transformedLocations);

        // console.log('transformedLocations');
        // console.log(transformedLocations);

        let filteredLocations = transformedLocations.filter(
          (location) => location.status === 'Active'
        );

        const uniqueCities = responseData.data.delivery_location_cities;
        const uniqueStates = responseData.data.delivery_location_states;

        // Check to see if the defaultState is included in the uniqueStates.
        if (defaultState && uniqueStates.includes(defaultState)) {
          if (defaultState) {
            filteredLocations = transformedLocations.filter(
              (location) => location.state === defaultState
            );
          }
        } else {
          // console.log('** NOTHING FOR THIS STATE ** ' + defaultState);
        }

        setDisplayedDeliveryLocations(filteredLocations);
        setCities(uniqueCities);
        setStates(uniqueStates);
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid, defaultState, history, dashboardUrl]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>Will Delivery To</th>
        <th>Name</th>
        <th>Address</th>
        <th>City</th>
        <th>State</th>
        <th>Leagues</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return displayedDeliveryLocations.map((record, index) => (
      <tr key={index}>
        <td className='text-centered'>
          {record.willDeliverTo ? (
            <input type='checkbox' defaultChecked='checked' value={record.deliveryLocationUuid} />
          ) : null}
          {!record.willDeliverTo ? (
            <input type='checkbox' value={record.deliveryLocationUuid} />
          ) : null}
        </td>
        <td>{record.name}</td>
        <td>
          {record.address1}
          {record.address2 ? ', ' + record.address2 : ''}
        </td>
        <td>{record.city}</td>
        <td>{record.state}</td>
        <td>{record.leagues}</td>
      </tr>
    ));
  };

  const clearFilterHandler = () => {
    cityFilterRef.current.value = 'Show All';
    // stateFilterRef.current.value = 'Show All';
    stateFilterRef.current.value = selectedRestaurantState ? selectedRestaurantState : 'Show All';
    filterDisplayedRecords();
  };

  const filterDisplayedRecords = () => {
    let cityFilter = cityFilterRef.current.value;
    let stateFilter = stateFilterRef.current.value;

    if (cityFilter === 'Show All' && stateFilter === 'Show All') {
      setDisplayedDeliveryLocations(deliveryLocations);
    } else {
      let filteredLocations = deliveryLocations;

      if (cityFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.city === cityFilter);
      }

      if (stateFilter !== 'Show All') {
        filteredLocations = filteredLocations.filter((record) => record.state === stateFilter);
      }

      setDisplayedDeliveryLocations(filteredLocations);
    }
  };

  const saveDeliveryLocationsHandler = () => {
    let selected = [];
    const checked = document.querySelectorAll('input[type="checkbox"]:checked');
    selected = Array.from(checked).map((x) => x.value);

    // console.log(selected);

    const selectedDeliveryLocationUuidsString = selected.join(', ');
    // console.log(' - selectedDeliveryLocationUuidsString - ');
    // console.log(selectedDeliveryLocationUuidsString);
    // setDietaryOptionsString(dietaryOptionsString);

    const apiURL = authCtx.baseApiUrl + 'restaurantdeliverylocations';
    const apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: restaurantUuid,
        delivery_location_uuids: selectedDeliveryLocationUuidsString,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Delivery Location(s) Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Selected Delivery Locations Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  // const submitHandler = (event) => {
  //   event.preventDefault();

  //   setNotify({
  //     isOpen: true,
  //     message: 'Sending Request...',
  //     type: 'info',
  //   });

  //   let apiURL = '';
  //   let apiMethod = '';

  // apiURL = authCtx.baseApiUrl + 'restaurantdeliverylocations/' + restaurantUuid;
  // apiMethod = 'PATCH';

  //   // console.log(' - apiURL = ' + apiURL);
  //   // console.log(' - apiMethod = ' + apiMethod);

  //   // Todo:  Add Validation.

  //   fetch(apiURL, {
  //     method: apiMethod,
  //     body: JSON.stringify({}),
  //     headers: {
  //       Authorization: authCtx.token,
  //       'Content-Type': 'application/json',
  //     },
  //   }).then((res) => {
  //     if (res.ok) {
  //       setNotify({
  //         isOpen: true,
  //         message: 'Delivery Location Successfully Saved.',
  //         type: 'success',
  //       });

  //       return res.json().then((responseData) => {
  //         console.log(' ** SUCCESS ** ');
  //         console.log(responseData);
  //       });
  //     } else {
  //       return res.json().then((responseData) => {
  //         if (
  //           responseData &&
  //           Array.isArray(responseData.messages) &&
  //           responseData.messages.length
  //         ) {
  //           const messageText = responseData.messages.map((message) => `${message}`).join(', ');
  //           setNotify({ isOpen: true, message: messageText, type: 'error' });
  //         } else {
  //           setNotify({
  //             isOpen: true,
  //             message: 'Selected Delivery Locations Not Saved',
  //             type: 'error',
  //           });
  //         }
  //       });
  //     }
  //   });
  // };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}
      {/* {!isLoading && !isError && deliveryLocations.length === 0 ? (
        <WorkflowMessage type='error' message={'No Delivery Locations Found *****'} />
      ) : null} */}

      <div className='content-container'>
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            Delivery Locations
          </Typography>
        </Box>

        {!isLoading && !isError ? (
          <Box className='button-holder'>
            {/* <label>Filter By: </label> */}
            <Box>
              <Box>
                <label htmlFor='filter-by-city'>City</label>
              </Box>

              {displayListFilterSelect(
                'filter-by-city',
                cityFilterRef,
                filterDisplayedRecords,
                cities
              )}
            </Box>
            <Box>
              <Box>
                <label htmlFor='filter-by-state'>State</label>
              </Box>
              {displayListFilterSelect(
                'filter-by-state',
                stateFilterRef,
                filterDisplayedRecords,
                states,
                defaultState
              )}
            </Box>
            <Box className='align-bottom'>
              <button className='small' onClick={clearFilterHandler}>
                Clear Filters
              </button>
            </Box>
            <Box className='align-bottom'>
              <button className='small green' onClick={saveDeliveryLocationsHandler}>
                Save Delivery Locations
              </button>
            </Box>
          </Box>
        ) : null}

        {!isLoading && deliveryLocations.length > 0 && (
          <div className='table-holder'>
            {/* <form onSubmit={submitHandler}> */}
            <table className={`${classes['styled-table']} ${classes['full-width']}`}>
              <thead>{displayTableHeader()}</thead>
              <tbody>{displayTableBody()}</tbody>
            </table>
            {/* </form> */}
          </div>
        )}

        {!isLoading && !isError && deliveryLocations.length === 0 ? (
          <Typography variant='h2' component='h2' color='error'>
            No Delivery Locations Found
          </Typography>
        ) : null}

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default VendorDeliveryLocationList;

import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import OrderContext from '../../../store/order-context';
import CartItem from './CartItem';
import classes from './Cart.module.css';

const Cart = (props) => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const history = useHistory();
  const location = useLocation();

  const orderSubtotal = orderCtx.orderDetails.subtotalFormatted;
  const hasItems = orderCtx.items.length > 0;

  const saveOrderItemData = (orderItemUuid, quantity) => {
    const apiURL = authCtx.baseApiUrl + 'orderitems/' + orderItemUuid;
    const apiMethod = 'PATCH';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        order_uuid: orderCtx.orderDetails.orderUuid,
        order_item_uuid: orderItemUuid,
        quantity: quantity,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          const orderItem = responseData.data.order_items[0];

          orderCtx.updateOrderItem(orderItem);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
          } else {
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  const deleteOrderItemData = async (orderItemUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + orderItemUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log(responseData);

      orderCtx.deleteOrderItem(orderItemUuid);
    } else {
      // setIsError(true);
      console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
    }
  };

  const cartItemAddHandler = (item) => {
    const newQuantity = item.quantity + 1;

    saveOrderItemData(item.orderItemUuid, newQuantity);
  };

  const cartItemRemoveHandler = (item) => {
    const newQuantity = item.quantity - 1;

    if (newQuantity < 1) {
      deleteOrderItemData(item.orderItemUuid);
    } else {
      saveOrderItemData(item.orderItemUuid, newQuantity);
    }
  };

  const closeCart = () => {
    props.setOpenPopup(false);
  };

  const showOrderHandler = () => {
    closeCart();
    location.pathname === '/order'
      ? history.replace('/checkout')
      : history.replace('/order/restaurant-menu');
  };

  const cartItems = orderCtx.items.map((item, index) => (
    <CartItem
      key={index}
      item={item}
      name={item.name}
      onAdd={cartItemAddHandler.bind(null, item)}
      onRemove={cartItemRemoveHandler.bind(null, item)}
    />
  ));

  return (
    <Box>
      <Box className={classes['cart-items-holder']}>{cartItems}</Box>
      <Box className={classes['total']}>
        <Typography variant='h5' component='span'>
          Subtotal
        </Typography>
        <Typography variant='h5' component='span'>
          {orderSubtotal}
        </Typography>
      </Box>
      <Box className={classes['actions']}>
        <Button variant='contained' color='neutral200' onClick={closeCart}>
          Close
        </Button>
        {hasItems ? (
          <Button variant='contained' color='primary' onClick={showOrderHandler}>
            Show Order
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default Cart;

import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import CopyrightIcon from '@mui/icons-material/Copyright';

import { environmentTitleFooter, buildVersion, buildVersionDate } from '../../constants/global';

import * as Constants from '../../helpers/Constants';
import classes from '../CSS/Footer.module.css';

const Footer = () => {
  const displayEnvironmentTitle = true;

  return (
    <Box textAlign='center' className={`${classes['footer-container']}`}>
      <Box className={classes['copyright-holder']}>
        <img
          src={Constants.IMAGE_FOLDER_PATH + 'logos/elite_eats_e_100.png'}
          alt='Elite Eats Logo'
          className={classes['logo-img']}
        />
        <Box component='span' className={classes['copyright-text-holder']}>
          <CopyrightIcon sx={{ fontSize: '20px' }} />
          <Typography className={classes['copyright-text']}>
            2022 - {`${new Date().getFullYear()}`} by Elite Eats Inc.
          </Typography>
        </Box>
      </Box>

      <Box className={classes['bottom-footer-row']}>
        <Typography className={classes['version-text']}>
          {buildVersion} - Last Updated on {buildVersionDate}
          {displayEnvironmentTitle ? ' - ' + environmentTitleFooter : null}
        </Typography>

        <Stack direction='row' alignItems='center' gap={2}>
          <a href='https://elite-eats.com' target='_blank' rel='noreferrer'>
            Elite Eats Website
          </a>
          <Link to='../terms'>Terms of Use</Link>
          <Link to='../privacy-policy'>Privacy Policy</Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;

import React from 'react';
import { Box, Button, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import FormControls from '../MUI/FormControls/FormControls';

const OrdersListMenu = (props) => {
  const { filterByStatus, onSearch, onClearSearch, searchRef, userRole, onFilterRecords } = props;

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        gap: '2rem',
        justifyContent: 'space-between',
        mb: '5px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '1rem', pb: '1rem' }}>
        {userRole === 'Vendor' ? (
          <Button
            onClick={() => onFilterRecords('New')}
            color={filterByStatus === 'New' ? 'error' : 'neutral1000'}
          >
            New
          </Button>
        ) : null}

        {userRole === 'User' ? (
          <Button
            onClick={() => onFilterRecords('Placed')}
            color={filterByStatus === 'Placed' ? 'error' : 'neutral1000'}
          >
            Placed
          </Button>
        ) : null}

        {userRole === 'Admin' ? (
          <Button
            onClick={() => onFilterRecords('Placed')}
            color={filterByStatus === 'Placed' ? 'error' : 'neutral1000'}
          >
            New/Placed
          </Button>
        ) : null}

        <Button
          onClick={() => onFilterRecords('Scheduled')}
          color={filterByStatus === 'Scheduled' ? 'error' : 'neutral1000'}
        >
          Scheduled
        </Button>

        <Button
          onClick={() => onFilterRecords('Completed')}
          color={filterByStatus === 'Completed' ? 'error' : 'neutral1000'}
        >
          Completed
        </Button>

        {userRole !== 'Vendor' ? (
          <Button
            onClick={() => onFilterRecords('Draft')}
            color={filterByStatus === 'Draft' ? 'error' : 'neutral1000'}
          >
            Draft
          </Button>
        ) : null}

        <Button
          onClick={() => onFilterRecords('Canceled')}
          color={filterByStatus === 'Canceled' ? 'error' : 'neutral1000'}
        >
          Canceled
        </Button>

        <Button
          onClick={() => onFilterRecords('Rejected')}
          color={filterByStatus === 'Rejected' ? 'error' : 'neutral1000'}
        >
          Rejected
        </Button>

        {/* <Button
          onClick={() => onFilterRecords('All')}
          color={filterByStatus === 'All' ? 'error' : 'neutral1000'}
        >
          All
        </Button> */}

        {userRole === 'Admin' ? (
          <Button
            onClick={() => onFilterRecords('Deleted')}
            color={filterByStatus === 'Deleted' ? 'error' : 'neutral1000'}
          >
            Deleted
          </Button>
        ) : null}
      </Box>

      <Box
        component='div'
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minWidth: '300px',
          height: '40px',
          ml: '10px',
          // border: '1px solid red',
        }}
      >
        <FormControls.InputControl
          type='text'
          name='search'
          label='Search'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ flexGrow: '1', mr: '2rem' }}
          onChange={onSearch}
          inputRef={searchRef}
        />
        <FormControls.ButtonControl
          name='clearSearch'
          text='Clear'
          variant='contained'
          size='small'
          endIcon={<SearchIcon />}
          onClick={onClearSearch}
          sx={{ p: '2rem', fontWeight: 'bold', height: '4rem' }}
        />
      </Box>
    </Box>
  );
};

export default OrdersListMenu;

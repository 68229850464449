import Header from './Header';
import Footer from './Footer';

const Layout = (props) => {
  return (
    <div className='app-container'>
      <div className='main-container'>
        <Header />
        <main>{props.children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

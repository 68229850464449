import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import PageTitleAndButtons from '../UI/PageTitleAndButtons';
import FormControls from '../MUI/FormControls/FormControls';
// import classes from '../CSS/standard-form.module.css';
import classes from '../CSS/standard-mui-form.module.css';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Deleted', title: 'Deleted' },
];

const UserForm = (props) => {
  const { onCancel, userUuid, pageTitle = 'Edit User' } = props;
  const authCtx = useContext(AuthContext);

  const notificationPreferenceItems = authCtx.notificationPreferencesArray;

  // console.log('** userUuid = ' + userUuid);

  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [notificationPreference, setNotificationPreference] = useState('Email');
  const [userStatus, setUserStatus] = useState('Active');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const positionRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      // get the data from the api
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const returnedUserDetails = {
          userUuid: responseData.data.users[0].userUuid,
          firstName: responseData.data.users[0].firstName,
          lastName: responseData.data.users[0].lastName,
          position: responseData.data.users[0].position,
          phone: responseData.data.users[0].phone,
          email: responseData.data.users[0].email,
          notificationPreference: responseData.data.users[0].notificationPreference,
          status: responseData.data.users[0].status,
          roleType: responseData.data.users[0].roleType,
          orgType: responseData.data.users[0].orgType,
          orgName: responseData.data.users[0].orgName,
          dateLastLogin: responseData.data.users[0].dateLastLogin,
          dateAdded: responseData.data.users[0].dateAdded,
        };
        // console.log(' -- returnedUserDetails -- ');
        // console.log(returnedUserDetails);

        setUserDetails(returnedUserDetails);
        setNotificationPreference(returnedUserDetails.notificationPreference);
        setUserStatus(returnedUserDetails.status);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // Doing this so I can use this component to add a new user.
    if (userUuid !== '' && userUuid !== undefined) {
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }

    // call the function
    // fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, userUuid]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredFirstName = firstNameRef.current.value;
    const enteredLastName = lastNameRef.current.value;
    const enteredPosition = positionRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current ? passwordRef.current.value : '';
    const enteredPasswordConfirm = passwordConfirmRef.current
      ? passwordConfirmRef.current.value
      : '';

    // console.log('enteredFirstName = ' + enteredFirstName);
    // console.log('enteredLastName = ' + enteredLastName);
    // console.log('enteredPosition = ' + enteredPosition);
    // console.log('enteredPhone = ' + enteredPhone);
    // console.log('enteredEmail = ' + enteredEmail);
    // return;

    // Todo:  Add Validation.
    // Make sure that the Passwords Match.
    if (enteredPassword !== enteredPasswordConfirm) {
      setNotify({
        isOpen: true,
        message: 'The Passwords entered do not match.',
        type: 'warning',
      });
      return;
    }

    let apiURL = '';
    let apiMethod = '';

    if (userUuid !== '' && userUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'users/' + userUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'users';
      apiMethod = 'POST';
    }

    // console.log('apiURL = ' + apiURL);
    // console.log('apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        first_name: enteredFirstName,
        last_name: enteredLastName,
        position: enteredPosition,
        phone: enteredPhone,
        email: enteredEmail,
        // password: enteredPassword,
        ...(enteredPassword && { password: enteredPassword }),
        notification_preference: notificationPreference,
        status: userStatus,
        org_type: authCtx.orgType,
        org_uuid: authCtx.orgUuid,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Data successfully updated.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          firstNameRef.current.value = '';
          lastNameRef.current.value = '';
          positionRef.current.value = '';
          phoneRef.current.value = '';
          emailRef.current.value = '';
          if (passwordRef.current) {
            passwordRef.current.value = '';
          }
          if (passwordConfirmRef.current) {
            passwordConfirmRef.current.value = '';
          }
          setNotificationPreference('Both');
          setUserStatus('');

          // const returnedData = {
          //   userUuid: responseData.data.users[0].userUuid,
          //   firstName: responseData.data.users[0].firstName,
          //   lastName: responseData.data.users[0].lastName,
          //   position: responseData.data.users[0].position,
          //   phone: responseData.data.users[0].phone,
          //   email: responseData.data.users[0].email,
          //   notificationPreference: responseData.data.users[0].notificationPreference,
          //   status: responseData.data.users[0].status,
          //   roleType: currentRoleType,
          //   dateLastLogin: responseData.data.users[0].dateLastLogin,
          //   dateAdded: responseData.data.users[0].dateAdded,
          // };

          // If the record being updated is the current user then we need to update data in the auth-context.
          // if (userUuid === userUuid) {
          //   authCtx.updateUserValues(responseData.data.users[0].firstName);
          // }

          // onCancel(returnedData);
          onCancel();
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Data update failed.',
              type: 'error',
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (!isLoading) {
      firstNameRef.current.focus();
    }
  }, [isLoading]);

  const notificationPreferenceChangeHandler = (event) => {
    // console.log('notificationPreferenceChangeHandler = event.target.value = ' + event.target.value);
    setNotificationPreference(event.target.value);
  };

  const statusChangeHandler = (event) => {
    setUserStatus(event.target.value);
  };

  return (
    <Box>
      {!isLoading && !isError ? (
        <PageTitleAndButtons pageTitle={pageTitle}>
          <Box className='button-holder-no-pb'>
            <Button variant='contained' color='accent' onClick={onCancel}>
              Users
            </Button>
          </Box>
        </PageTitleAndButtons>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading ? (
        // <Paper elevation={4} className='standard-form'>
        <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem', m: '0 auto' }}>
          {userDetails.orgType ? (
            <>
              <Typography variant='subtitle2'>{userDetails.orgType} Name:</Typography>
              <Typography variant='h1' component='h1'>
                {userDetails.orgName}
              </Typography>
            </>
          ) : null}

          {!userDetails.orgType && authCtx.orgType ? (
            <>
              <Typography variant='subtitle2'>{authCtx.orgType} Name:</Typography>
              <Typography variant='h1' component='h1'>
                {authCtx.orgName}
              </Typography>
            </>
          ) : null}

          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', pt: '3rem' }}>
              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='first_name'
                  label='First Name'
                  defaultValue={userDetails.firstName}
                  inputRef={firstNameRef}
                  required
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
                <FormControls.InputControl
                  type='text'
                  name='last_name'
                  label='Last Name'
                  defaultValue={userDetails.lastName}
                  inputRef={lastNameRef}
                  required
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='position'
                  label='Position'
                  defaultValue={userDetails.position}
                  inputRef={positionRef}
                  required
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='phone'
                  label='Phone'
                  defaultValue={userDetails.phone}
                  inputRef={phoneRef}
                  required
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 20 }}
                />
                <FormControls.InputControl
                  type='email'
                  name='email'
                  label='Email'
                  defaultValue={userDetails.email}
                  inputRef={emailRef}
                  required
                  autoComplete='username'
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>

              {!userUuid ? (
                <Box className={classes['grid-2']}>
                  <FormControls.InputControl
                    type='password'
                    name='password'
                    label='Password'
                    inputRef={passwordRef}
                    required
                    autoComplete='new-password'
                    className={classes['mui-control']}
                    inputProps={{ maxLength: 50 }}
                  />
                  <FormControls.InputControl
                    type='password'
                    name='password_confirm'
                    label='Confirm Password'
                    inputRef={passwordConfirmRef}
                    required
                    autoComplete='new-password'
                    className={classes['mui-control']}
                    inputProps={{ maxLength: 50 }}
                  />
                </Box>
              ) : null}

              <Box className={classes['grid-2']}>
                <FormControls.RadioGroupControl
                  name='notification_preference'
                  label='Notification Preference *'
                  value={notificationPreference}
                  onChange={notificationPreferenceChangeHandler}
                  items={notificationPreferenceItems}
                />
                <Box sx={{ width: '15rem', pt: '10px' }}>
                  <FormControls.SelectControl
                    name='status'
                    label='Status'
                    value={userStatus}
                    onChange={statusChangeHandler}
                    options={statusOptions}
                    // initialOptionValue='New'
                  />
                </Box>
              </Box>
            </Box>

            <Box className={classes['actions-container']}>
              <FormControls.ButtonControl text='Save' size='custom' type='submit' />
              <FormControls.ButtonControl
                text='Cancel'
                size='custom'
                onClick={onCancel}
                type='button'
              />
            </Box>
          </form>
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default UserForm;

import { useState, useRef, useContext } from 'react';
// import { useHistory } from 'react-router-dom';
import { Box, Button, Rating, Typography } from '@mui/material';
import Popup from '../MUI/UI/Popup';

import AuthContext from '../../store/auth-context';
// import OrderContext from '../../store/order-context';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import ImageUpload from '../FormControls/ImageUpload';
import RestaurantReviewImages from './RestaurantReviewImages';
import classes from '../CSS/standard-form.module.css';

const RestaurantReviewForm = (props) => {
  const {
    orderUuid,
    restaurantUuid,
    popup = false,
    onCancel,
    // restaurantReviewUuid,
    reviewImages,
    baseApiUploadedImagesPath,
  } = props;
  const authCtx = useContext(AuthContext);
  // const orderCtx = useContext(OrderContext);

  // const history = useHistory();

  // console.log('RestaurantReviewForm');
  // console.log('props');
  // console.log(props);
  // console.log('props.rating = ' + props.rating);

  // console.log('orderCtx.orderDetails');
  // console.table(orderCtx.orderDetails);

  // const restaurantUuid = orderCtx.orderDetails.restaurantUuid;
  // const orderUuid = orderCtx.orderDetails.orderUuid;
  // const review = orderCtx.orderDetails.review;
  // const review = 'Not entered';

  // console.log('restaurantReviewUuid = ' + restaurantReviewUuid);
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('orderUuid = ' + orderUuid);
  // console.log('review');
  // console.log(review);

  // const [restaurantReviewUuid, setRestaurantReviewUuid] = useState(restaurantReviewUuid);
  const [rating, setRating] = useState(props.rating);
  const [review, setReview] = useState(props.review);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [openPopupImageUpload, setOpenPopupImageUpload] = useState(false);
  const [restaurantReviewUuid, setRestaurantReviewUuid] = useState(
    props.restaurantReviewUuid ? props.restaurantReviewUuid : ''
  );
  // const [linkedUuid, setLinkedUuid] = useState(restaurantReviewUuid ? restaurantReviewUuid : '');
  const [showUploadImageButton, setShowUploadImageButton] = useState(
    props.restaurantReviewUuid ? true : false
  );

  const reviewRef = useRef();

  const showImageUploadForm = () => {
    // console.log('** showImageUploadForm **');
    setOpenPopupImageUpload((current) => !current);
  };

  const saveReviewData = (enteredRating, enteredReview) => {
    // const apiURL = authCtx.baseApiUrl + 'restaurantreviews/' + restarantReviewUuid;

    // console.log('restaurantReviewUuid = ' + restaurantReviewUuid);
    // console.log('enteredRating = ' + enteredRating);
    // console.log('enteredReview = ' + enteredReview);
    // console.log('orderUuid = ' + orderUuid);
    // console.log('restaurantUuid = ' + restaurantUuid);

    // const testRating = enteredRating ? enteredRating : rating;

    setIsLoading(true);
    setIsError(false);

    let apiURL = '';
    let apiMethod = '';

    console.log('restaurantUuid', restaurantUuid);

    if (
      restaurantReviewUuid !== '' &&
      restaurantReviewUuid !== undefined &&
      restaurantReviewUuid !== null
    ) {
      apiURL = authCtx.baseApiUrl + 'restaurantreviews/' + restaurantReviewUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'restaurantreviews';
      apiMethod = 'POST';
    }

    console.log(' - apiURL = ' + apiURL);
    console.log(' - apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        order_uuid: orderUuid,
        restaurant_uuid: restaurantUuid,
        rating: enteredRating,
        // rating: 4.5,
        // rating: testRating,
        review: enteredReview,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setIsError(false);
        setShowUploadImageButton(true);

        setNotify({
          isOpen: true,
          message: 'Review Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          // const recordDetails = responseData.data.restaurant_reviews[0].restaurantReviewUuid;
          const returnedRestaurantReviewUuid =
            responseData.data.restaurant_reviews[0].restaurantReviewUuid;

          // console.log(
          //   'responseData.data.restaurant_reviews[0]',
          //   responseData.data.restaurant_reviews[0]
          // );

          // console.log(
          //   'responseData.data.restaurant_reviews[0].restaurantReviewUuid',
          //   responseData.data.restaurant_reviews[0].restaurantReviewUuid
          // );

          // console.log('returnedRestaurantReviewUuid', returnedRestaurantReviewUuid);

          // setLinkedUuid(returnedRestaurantReviewUuid);
          setRestaurantReviewUuid(returnedRestaurantReviewUuid);

          // reviewRef.current.value = '';
          setNotify({
            isOpen: true,
            message: 'Review Successfully Saved',
            type: 'success',
          });
        });
      } else {
        setIsError(true);
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Review Not Saved',
              type: 'error',
            });
          }
        });
      }
    });

    setIsLoading(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    let wasDataUpdated = false;
    const enteredRating = rating;
    const enteredReview = reviewRef.current.value;

    const currentReview = review;

    // setRating(enteredRating);  // I thought that might help. It didn't.
    setReview(enteredReview);

    // wasDataUpdated = enteredReview !== review;
    wasDataUpdated = enteredReview !== currentReview;
    // wasDataUpdated = enteredRating !== props.rating;
    if (!wasDataUpdated) {
      // wasDataUpdated = enteredRating !== rating;
      wasDataUpdated = enteredRating !== props.rating;
    }

    // console.log('wasDataUpdated = ' + wasDataUpdated);
    // console.log('enteredRating = ' + enteredRating);
    // console.log('rating = ' + rating);
    // console.log('enteredReview');
    // console.log(enteredReview);
    // console.log('review');
    // console.log(review);

    if (!wasDataUpdated) {
      setNotify({
        isOpen: true,
        message: 'Rating and Review were not changed.',
        type: 'success',
      });
      // history.replace('/checkout/review-order');
      // return;
    } else {
      saveReviewData(enteredRating, enteredReview);
    }

    if (restaurantReviewUuid) {
      onCancel();
    }

    // if (wasDataUpdated) {
    //   saveReviewData(enteredRating, enteredReview);
    // }
  };

  const ratingChangedHandler = (rating) => {
    // console.log(' ^^^^^ ratingChangedHandler - rating = ' + rating + ' ^^^^^^ ');
    setRating(rating);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Box sx={{ height: '100%' }}>
          {!popup ? (
            <Typography variant='h5' align='center' sx={{ pb: '2rem' }}>
              Review Your Order
            </Typography>
          ) : null}

          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Rating
                  name='rating'
                  value={rating}
                  precision={0.5}
                  size='large'
                  // onChange={(event, newValue) => {
                  //   setRating(newValue);
                  // }}
                  onChange={(event, newValue) => {
                    ratingChangedHandler(newValue);
                  }}
                />
              </Box>

              <FormControls.InputControl
                type='text'
                name='review'
                label='Review'
                inputRef={reviewRef}
                defaultValue={review}
                className={classes['mui-control']}
                multiline
                minRows={3}
                maxRows={12}
              />
            </Box>
            <Box component='div' className={classes.actions}>
              <FormControls.ButtonControl text='Save Review' type='submit' color='primary' />
              <FormControls.ButtonControl text='Cancel' color='neutral200' onClick={onCancel} />
            </Box>
          </form>

          <Box sx={{ p: '0.2rem' }}>
            <RestaurantReviewImages
              baseApiUploadedImagesPath={baseApiUploadedImagesPath}
              reviewImages={reviewImages}
              displayDeleteImageLink={true}
              onCancel={onCancel}
            />
          </Box>

          {showUploadImageButton ? (
            <Box align='center'>
              <Button variant='contained' size='small' onClick={showImageUploadForm}>
                Upload Image
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />

      <Popup
        openPopup={openPopupImageUpload}
        setOpenPopup={setOpenPopupImageUpload}
        maxWidth='md'
        title='Image Upload'
      >
        <ImageUpload linkedUuid={restaurantReviewUuid} linkType='ReviewImage' onCancel={onCancel} />
      </Popup>
    </>
  );
};

export default RestaurantReviewForm;

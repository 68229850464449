import { useState, useContext } from 'react';
import { Alert, Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import RestaurantHeaderImage from '../FormControls/RestaurantHeaderImage';
import RestaurantListImage from '../FormControls/RestaurantListImage';
import classes from '../CSS/standard-mui-form.module.css';

const RestaurantImagesForm = ({ restaurantDetails, onCancel, onEditImages }) => {
  // console.log('***** RestaurantImagesForm *****');
  // console.log('restaurantDetails');
  // console.log(restaurantDetails);

  const authCtx = useContext(AuthContext);
  // const userRole = authCtx.userRole;
  // const dashboardUrl = authCtx.dashboardUrl;

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const restaurantFolder = restaurantDetails.restaurantUuid;
  baseApiUploadedImagesPath += restaurantFolder + '/';
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  const [headerImage, setHeaderImage] = useState(restaurantDetails.headerImage);
  const [listImage, setListImage] = useState(restaurantDetails.listImage);

  const [selectedHeaderImage, setSelectedHeaderImage] = useState(null);
  const [selectedListImage, setSelectedListImage] = useState(null);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const headerImageChangeHandler = (event) => {
    // console.log('-- headerImageChangeHandler -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    const filename = files[0].name;
    // setSelectedHeaderImageName(filename);
    console.log('filename');
    console.log(filename);

    fileReader.onload = (event) => {
      setSelectedHeaderImage(event.target.result);
    };
  };

  const listImageChangeHandler = (event) => {
    // console.log('-- listImageChangeHandler -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    const filename = files[0].name;
    // setSelectedListImageName(filename);
    console.log('filename');
    console.log(filename);

    fileReader.onload = (event) => {
      setSelectedListImage(event.target.result);
    };
  };

  // const cancelHandler = () => {
  //   console.log('cancelHandler');
  // };

  const submitHandler = (event) => {
    event.preventDefault();

    let apiURL = '';
    let apiMethod = '';

    if (restaurantDetails.restaurantUuid !== '' && restaurantDetails.restaurantUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'restaurants/images/' + restaurantDetails.restaurantUuid;
      apiMethod = 'PATCH';
    }

    // console.log('apiURL = ' + apiURL);
    // console.log('apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        header_image: selectedHeaderImage,
        list_image: selectedListImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          setHeaderImage(responseData.data.restaurant.header_image);
          setListImage(responseData.data.restaurant.list_image);

          setSelectedHeaderImage('');
          setSelectedListImage('');
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box>
      <Paper elevation={4} sx={{ maxWidth: '120rem' }}>
        <Box className={classes['form-container']}>
          {restaurantDetails.restaurantUuid ? (
            <form onSubmit={submitHandler}>
              <Box sx={{ pt: '20px' }}>
                <RestaurantHeaderImage
                  headerImage={headerImage}
                  baseApiUploadedImagesPath={baseApiUploadedImagesPath}
                  onHeaderImageChange={headerImageChangeHandler}
                  displayButtons={true}
                />
              </Box>
              <Box sx={{ py: '20px' }}>
                <RestaurantListImage
                  listImage={listImage}
                  baseApiUploadedImagesPath={baseApiUploadedImagesPath}
                  onListImageChange={listImageChangeHandler}
                  displayButtons={true}
                />
              </Box>

              <Box className={classes['actions-container']}>
                <FormControls.ButtonControl text='Save' type='submit' />
                <FormControls.ButtonControl text='Cancel' type='button' onClick={onCancel} />
              </Box>
            </form>
          ) : null}

          {selectedHeaderImage || selectedListImage ? (
            <Box align='center'>
              <Alert severity='info' align='center' sx={{ width: '500px' }}>
                Image file(s) selected. Click the SAVE button to upload the image.
              </Alert>
            </Box>
          ) : null}
        </Box>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default RestaurantImagesForm;

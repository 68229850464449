import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import Notification from '../MUI/UI/Notification';
import classes from '../CSS/styled-table.module.css';

const UserList = (props) => {
  // console.log('######## UserList ########');
  const {
    onAddNew,
    onEdit,
    onShowContact,
    pageTitle = 'Contacts',
    orgType = '',
    orgUuid = '',
  } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const selectedRestaurantUuid = authCtx.selectedRestaurantUuid
    ? authCtx.selectedRestaurantUuid
    : '';

  // console.log('authCtx.selectedRestaurantUuid', authCtx.selectedRestaurantUuid);
  // console.log('selectedRestaurantUuid', selectedRestaurantUuid);
  // console.log('orgType', orgType);
  // console.log('orgUuid', orgUuid);

  const [users, setUsers] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const transformRecords = (users) => {
    const transformedUsers = users.map((user) => {
      return {
        userUuid: user.userUuid,
        firstName: user.firstName,
        lastName: user.lastName,
        position: user.position,
        phone: user.phone,
        email: user.email,
        notificationPreference: user.notificationPreference,
        status: user.status,
        roleType: user.roleType,
        orgName: user.orgName ? user.orgName : '',
      };
    });

    setUsers(transformedUsers);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let apiUrl = authCtx.baseApiUrl + 'users/include/orginfo';
      if (userRole === 'Restaurant' && orgUuid) {
        apiUrl = authCtx.baseApiUrl + 'users/restaurant/' + orgUuid;
      } else if (orgType === 'Team' && orgUuid) {
        apiUrl = authCtx.baseApiUrl + 'users/team/' + orgUuid;
      } else if (userRole === 'Admin' && selectedRestaurantUuid) {
        apiUrl = authCtx.baseApiUrl + 'users/restaurant/' + selectedRestaurantUuid;
      }

      // console.log('apiUrl', apiUrl);

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(responseData.data.users);

        transformRecords(responseData.data.users);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    userRole,
    selectedRestaurantUuid,
    refreshData,
    orgType,
    orgUuid,
  ]);

  const displayTableHeader = () => {
    return (
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Position</th>
        <th>Phone</th>
        <th>Email</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return users.map((user, index) => (
      <tr key={index}>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.position}</td>
        <td>{user.phone}</td>
        <td>{user.email}</td>
        <td>{user.status}</td>
        <td>
          <div className={classes['table-actions-container']}>
            <button
              onClick={() => {
                onShowContact(user.userUuid);
              }}
              className='button small'
            >
              Details
            </button>
            <button
              onClick={() => {
                onEdit(user.userUuid);
              }}
              className='button small'
            >
              Edit
            </button>
            {user.status !== 'Deleted' && (
              <button
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure you want to delete this record.',
                    subTitle: 'It will be permanently deleted.',
                    onConfirm: () => {
                      deleteHandler(user.userUuid);
                    },
                  });
                }}
                className='small'
              >
                Delete
              </button>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  const deleteHandler = (userUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteUserHandler(userUuid);
  };

  const deleteUserHandler = (userUuid) => {
    // console.log(' -- deleteUserHandler -- ');
    // console.log('userUuid = ' + userUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        // console.log(responseData);

        setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'success' });

        setRefreshData(true);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    deleteData().catch(console.error);
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Box className='title-and-button-holder'>
          <Typography variant='h1' component='h1'>
            {pageTitle}
          </Typography>

          <Box className='button-holder-no-pb'>
            <Button variant='contained' size='custom' color='accent' onClick={onAddNew}>
              Add Contact
            </Button>
          </Box>
        </Box>
      ) : null}

      {!isLoading && users && users.length > 0 && (
        <Box className='table-holder'>
          <table className={`${classes['styled-table']} ${classes['full-width']}`}>
            <thead>{displayTableHeader()}</thead>
            <tbody>{displayTableBody()}</tbody>
          </table>
        </Box>
      )}

      {!isLoading && !isError && users.length === 0 ? (
        <Typography variant='h2' component='h2' color='error'>
          No Users Found
        </Typography>
      ) : null}

      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default UserList;

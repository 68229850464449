import React from 'react';
import { Box, Typography } from '@mui/material';

const RestaurantBasicInformationCard = ({ restaurantDetails }) => {
  return (
    <Box>
      <Typography variant='h2' component='h2'>
        {restaurantDetails.name}
      </Typography>

      <Typography component='div'>
        {restaurantDetails.address1}
        {restaurantDetails.address2 ? ', ' + restaurantDetails.address2 : ''}

        {restaurantDetails.city ? (
          <>
            {restaurantDetails.city ? ', ' + restaurantDetails.city : ''}
            {restaurantDetails.state ? ', ' + restaurantDetails.state : ''}
            {restaurantDetails.zipcode ? ', ' + restaurantDetails.zipcode : ''}
          </>
        ) : null}
      </Typography>

      {restaurantDetails.phone ? (
        <Box sx={{ pt: '5px' }}>
          <Typography component='div'>{restaurantDetails.phone}</Typography>
        </Box>
      ) : null}

      {restaurantDetails.email ? (
        <Box sx={{ pt: '5px' }}>
          <Typography component='div'>{restaurantDetails.email}</Typography>
        </Box>
      ) : null}

      {restaurantDetails.description ? (
        <Box sx={{ pt: '30px', pb: '10px' }}>
          <Typography component='pre'>{restaurantDetails.description}</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default RestaurantBasicInformationCard;

import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import OrderContext from '../../store/order-context';
import classes from './HeaderCartButton.module.css';

const HeaderCartButton = (props) => {
  const orderCtx = useContext(OrderContext);

  const [buttonIsHighlighted, setButtonIsHightlighted] = useState(false);

  const numberOfCartItems = orderCtx.items.reduce((currentNumber, item) => {
    return currentNumber + item.quantity;
  }, 0);

  const buttonClasses = `${classes['cart-button']} ${buttonIsHighlighted ? classes['bump'] : ''}`;

  useEffect(() => {
    if (orderCtx.items.length === 0) {
      return;
    }
    setButtonIsHightlighted(true);

    const timer = setTimeout(() => {
      setButtonIsHightlighted(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [orderCtx.items]);

  return (
    <>
      {numberOfCartItems > 0 ? (
        <Button size='small' className={buttonClasses} onClick={props.onShowCart}>
          <ShoppingCartIcon className={classes['icon']} />
          <Typography color='white'>Your Cart</Typography>
          <span className={classes['badge']}>{numberOfCartItems}</span>
        </Button>
      ) : null}
    </>
  );
};

export default HeaderCartButton;

import React from 'react';
import { Box, Typography } from '@mui/material';

const SalesTaxMessage = ({ textAlign = 'center' }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: '14px' }} align={textAlign}>
        * Sales Tax will be calculated at payment
      </Typography>
    </Box>
  );
};

export default SalesTaxMessage;

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatData } from '../Hooks/useFormatData';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import classes from '../CSS/styled-table.module.css';

import { deliveryFeeLabel, deliveryFeesLabel } from '../../constants/global';

const DeliveryFeesListCard = ({
  mode = 'details',
  restaurantDetails = {},
  displayButton = true,
  displayAddButton = false,
  onDelete = null,
}) => {
  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const rolesAbleToEditDeliveryFees = authCtx.rolesAbleToEditDeliveryFees;

  const history = useHistory();

  const restaurantUuid = restaurantDetails.restaurantUuid;
  const deliveryFees = restaurantDetails.deliveryFees ? restaurantDetails.deliveryFees : {};

  // const displayAddOrEditButton =
  //   restaurantDetails.status !== 'New' && restaurantDetails.status !== 'Deleted' ? true : false;
  const displayAddOrEditButton =
    mode === 'details' && restaurantDetails.status !== 'Deleted' ? true : false;

  // console.log('DeliveryFeesListCard');
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('mode = ' + mode);
  // console.log('deliveryFees');
  // console.log(deliveryFees);
  // console.log('rolesAbleToEditDeliveryFees = ' + rolesAbleToEditDeliveryFees);

  const { FormatCurrencyForDisplay } = useFormatData();

  // const [deliveryFees, setDeliveryFees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const displaySimpleTable = true;

  const buttonText =
    deliveryFees.length === 0 ? 'Add ' + deliveryFeeLabel : 'Edit ' + deliveryFeeLabel;

  useEffect(() => {
    setIsLoading(false);
    setIsError(false);
  }, []);

  const addDeliveryFeeHandler = () => {
    // console.log('addDeliveryFeeHandler');
    // history.push(dashboardUrl + 'restaurants/delivery-fee/' + restaurantUuid);
    history.push(dashboardUrl + 'restaurants/delivery-fee/' + restaurantUuid + '/' + null);
  };

  const editDeliveryFeesHandler = () => {
    // console.log('editDeliveryFeesHandler');
    history.push(dashboardUrl + 'restaurants/delivery-fees/' + restaurantUuid);
  };

  const cancelHandler = () => {
    // console.log('cancelHandler');
    if (userRole === 'Vendor') {
      history.push(dashboardUrl + 'vendor-profile');
    } else {
      history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
    }
  };

  const editDeliveryFeeHandler = (event) => {
    // console.log(' -- editDeliveryFeeHandler -- ');
    const deliveryFeeUuid = event.target.getAttribute('data-fee-uuid');
    // console.log('deliveryFeeUuid = ' + deliveryFeeUuid);
    // history.push(dashboardUrl + 'restaurants/delivery-fee/' + deliveryFeeUuid);
    history.push(
      dashboardUrl + 'restaurants/delivery-fee/' + restaurantUuid + '/' + deliveryFeeUuid
    );
  };

  const deleteDeliveryFeeHandler = (deliveryFeeUuid) => {
    // console.log(' -- deleteDeliveryFeeHandler -- ');
    // console.log('deliveryFeeUuid = ' + deliveryFeeUuid);

    setConfirmDialog({ ...confirmDialog, isOpen: false });

    onDelete(deliveryFeeUuid);
  };

  // const deleteDeliveryFeeHandler = (event) => {
  //   console.log(' -- deleteDeliveryFeeHandler -- ');
  //   const deliveryFeeUuid = event.target.getAttribute('data-fee-uuid');
  //   console.log('deliveryFeeUuid = ' + deliveryFeeUuid);

  //   setConfirmDialog({ ...confirmDialog, isOpen: false });

  //   onDelete(deliveryFeeUuid);
  // };

  const displayTableHeader = () => {
    return (
      <tr>
        <th>From</th>
        <th>To</th>
        <th>Fee</th>
      </tr>
    );
  };

  const displayTableBody = () => {
    return deliveryFees.map((record, index) => (
      <tr key={index}>
        <td className={`${classes['text-right']}`}>
          {FormatCurrencyForDisplay(record.rangeStart)}
        </td>
        <td className={`${classes['text-right']}`}>{FormatCurrencyForDisplay(record.rangeEnd)}</td>
        <td className={`${classes['text-right']}`}>
          {record.percentFormatted ? record.percentFormatted : record.feeFormatted}
        </td>
      </tr>
    ));
  };

  const displayTableRowsHeader = () => {
    return (
      <tr>
        <th className='label-right'>Order Amount</th>
        <th className='label-right'>Fee</th>
        {!displayButton ? <th className='label-right'></th> : null}
      </tr>
    );
  };

  const displayTableRows = () => {
    return deliveryFees.map((record, index) => (
      <tr key={index}>
        <td className='table-td-number'>
          {FormatCurrencyForDisplay(record.rangeStart) +
            ' - ' +
            FormatCurrencyForDisplay(record.rangeEnd)}
        </td>
        <td className='table-td-number'>
          {record.percentFormatted ? record.percentFormatted : record.feeFormatted}
        </td>
        {!displayButton ? (
          <td>
            <div className={classes['table-actions-container']}>
              <button
                data-fee-uuid={record.deliveryFeeUuid}
                onClick={editDeliveryFeeHandler}
                className='small'
              >
                Edit
              </button>

              <button
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: 'Are you sure you want to delete this record.',
                    subTitle: 'It will be permanently deleted.',
                    onConfirm: () => {
                      deleteDeliveryFeeHandler(record.deliveryFeeUuid);
                    },
                  });
                }}
                className='small'
              >
                Delete
              </button>
            </div>
          </td>
        ) : null}
      </tr>
    ));
  };

  const displayDeliveryFeesInSimpleTable = () => {
    return (
      <Box>
        <table className='table-simple table-simple-with-padding'>
          <thead>{displayTableRowsHeader()}</thead>
          <tbody>{displayTableRows()}</tbody>
        </table>
      </Box>
    );
  };

  return (
    <Box align='center'>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <>
          {mode === 'details' ? (
            <Box>
              <Typography variant='h3' textAlign='center' sx={{ pb: '10px' }}>
                {deliveryFeesLabel}
              </Typography>

              {deliveryFees.length === 0 ? (
                <Typography color='error'>No {deliveryFeesLabel}</Typography>
              ) : null}

              {deliveryFees.length > 0 ? <>{displayDeliveryFeesInSimpleTable()}</> : null}

              {displayAddOrEditButton &&
              displayButton &&
              rolesAbleToEditDeliveryFees.includes(userRole) ? (
                <Box align='center' className='pt-10'>
                  {deliveryFees.length === 0 ? (
                    <Button variant='contained' size='small' onClick={addDeliveryFeeHandler}>
                      {buttonText}
                    </Button>
                  ) : (
                    <Button variant='contained' size='small' onClick={editDeliveryFeesHandler}>
                      {buttonText}
                    </Button>
                  )}
                </Box>
              ) : null}
            </Box>
          ) : null}

          {mode === 'main' ? (
            <Paper elevation={4} className='p-20'>
              {deliveryFees.length === 0 ? (
                <Typography color='error'>No {deliveryFeesLabel}</Typography>
              ) : null}

              {deliveryFees.length > 0 ? (
                <>
                  {displaySimpleTable ? displayDeliveryFeesInSimpleTable() : null}

                  {!displaySimpleTable ? (
                    <table className={`${classes['styled-table']}`}>
                      <thead>{displayTableHeader()}</thead>
                      <tbody>{displayTableBody()}</tbody>
                    </table>
                  ) : null}
                </>
              ) : null}

              {displayAddButton && userRole !== 'User' ? (
                <Box align='center' className='pt-20 button-holder-center'>
                  <Button variant='contained' size='small' onClick={addDeliveryFeeHandler}>
                    Add {deliveryFeeLabel}
                  </Button>
                  <Button variant='contained' size='small' color='error' onClick={cancelHandler}>
                    Cancel
                  </Button>
                </Box>
              ) : null}
            </Paper>
          ) : null}
        </>
      ) : null}

      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default DeliveryFeesListCard;

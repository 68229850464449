import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';

const RestaurantHeaderImage = (props) => {
  const {
    headerImage = '',
    baseApiUploadedImagesPath,
    onHeaderImageChange,
    displayButtons = false,
  } = props;

  // console.log('---- RestaurantHeaderImage ---- ');
  // console.log('headerImage = ' + headerImage);

  return (
    <div>
      <Box>
        <Typography variant='h2' component='h1'>
          Header Image
          <Typography variant='caption' component='span' sx={{ ml: '10px' }}>
            (Dimensions: 1200px width, 200px height)
          </Typography>
        </Typography>

        <Box sx={{ pb: '20px' }}>
          {headerImage ? (
            <Tooltip title='Click to open the full size Header Image in a New Tab'>
              <a href={baseApiUploadedImagesPath + headerImage} target='_blank' rel='noreferrer'>
                <img
                  src={baseApiUploadedImagesPath + headerImage}
                  alt='Current Header'
                  className='uploaded-header-image-cover'
                />
              </a>
            </Tooltip>
          ) : (
            <Box className='uploaded-header-image-filler'>NO IMAGE</Box>
          )}
        </Box>

        {displayButtons ? (
          <Box align='center'>
            <Button variant='contained' component='label' size='small'>
              Select Header Image File
              <input
                hidden
                accept='image/*'
                multiple
                type='file'
                name='header_image'
                onChange={(event) => onHeaderImageChange(event)}
              />
            </Button>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default RestaurantHeaderImage;

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import FormControls from '../FormControls/FormControls';
import classes from '../../CSS/standard-form.module.css';

const ForgotPasswordForm = (props) => {
  const { toggleShowForm, setNotify } = props;

  const authCtx = useContext(AuthContext);
  const emailRef = useRef();

  const [displayMessage, setDisplayMessage] = useState(null);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredEmail = emailRef.current.value;

    fetch(authCtx.baseApiUrl + 'forgotpassword', {
      method: 'POST',
      body: JSON.stringify({
        email: enteredEmail,
      }),
      headers: {
        Authorization: authCtx.signupToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.ok) {
          emailRef.current.value = '';

          return res.json().then((responseData) => {
            toggleShowForm('ResetPassword');
          });
        } else {
          return res.json().then((responseData) => {
            if (
              responseData &&
              Array.isArray(responseData.messages) &&
              responseData.messages.length
            ) {
              setDisplayMessage(responseData.messages);
            } else {
              setDisplayMessage(['Email Not Found']);
            }
            // console.log(responseData);
          });
        }
      })
      .catch((err) => {
        setDisplayMessage(['Email Not Found']);
        // console.log(err);
      });
  };

  return (
    <>
      <Paper elevation={4} sx={{ maxWidth: '60rem', p: '2rem', m: '0 auto' }}>
        <Typography variant='h5' className={classes['mui-form-header']}>
          Forgot Password
        </Typography>
        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4rem', pt: '4rem' }}>
            <FormControls.InputControl
              type='email'
              name='email'
              label='Email'
              inputRef={emailRef}
              autoComplete='username'
              required
              className={classes['mui-control']}
            />
          </Box>
          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl text='Send' type='submit' />
          </Box>

          <Box align='center'>
            <Button variant='text' onClick={() => toggleShowForm('Login')}>
              Back to Login
            </Button>
          </Box>
        </form>
      </Paper>
      {displayMessage && (
        <Box component='div' className='messages'>
          {displayMessage.map((message, index) => (
            <p className={classes.messages__error} key={index}>
              {message}
            </p>
          ))}
        </Box>
      )}
    </>
  );
};

export default ForgotPasswordForm;

import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const UserDetailsCard = ({ details, userRole = '' }) => {
  const displayOrgNameAndPostion = true;

  return (
    <Paper elevation={4} sx={{ p: '20px' }}>
      <Box className='flex-wrap-container'>
        {displayOrgNameAndPostion ? (
          <Box>
            <Typography variant='h2' component='h2'>
              {details.firstName + ' ' + details.lastName}
            </Typography>
            <Typography variant='h3' component='h3'>
              {details.orgName}
              <Typography component='span' sx={{ pl: '10px' }}>
                ({details.position})
              </Typography>
            </Typography>
            {/* <Typography component='div'>{details.position}</Typography> */}
            <Typography component='div'>{details.phone}</Typography>
            <Typography component='div'>{details.email}</Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant='h2' component='h2'>
              {details.firstName + ' ' + details.lastName}
              <Typography component='span' sx={{ pl: '10px' }}>
                ({details.position})
              </Typography>
            </Typography>
            <Typography component='div'>{details.phone}</Typography>
            <Typography component='div'>{details.email}</Typography>
          </Box>
        )}

        <Box>
          <table className='table-simple'>
            <tbody>
              <tr>
                <td className='table-td-label'>Notification Preference</td>
                <td className='table-td-text'>{details.notificationPreference}</td>
              </tr>
              <tr>
                <td className='table-td-label'>Role</td>
                <td className='table-td-text'>{details.roleType}</td>
              </tr>
              <tr>
                <td className='table-td-label'>Status</td>
                <td className='table-td-text'>{details.status}</td>
              </tr>
              <tr>
                <td className='table-td-label'>Created On</td>
                <td className='table-td-text'>{details.dateAdded}</td>
              </tr>
              {userRole === 'Admin' ? (
                <tr>
                  <td className='table-td-label'>Last Logged In</td>
                  <td className='table-td-text'>{details.dateLastLogin}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </Box>
      </Box>
    </Paper>
  );
};

export default UserDetailsCard;

import { useState, useRef, useContext } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const PaymentAccountFormAdmin = ({ restaurantDetails }) => {
  const authCtx = useContext(AuthContext);

  // console.log('pRestaurantDetails');
  // console.log(pRestaurantDetails);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // const [restaurantDetails, setRestaurantDetails] = useState(pRestaurantDetails);

  const paymentAccountIdRef = useRef();
  const paymentTaxRateIdRef = useRef();

  const submitHandler = (event) => {
    event.preventDefault();
    console.log('submitHandler');
    setIsLoading(true);
    setIsError(false);

    const restaurantUuid = restaurantDetails.restaurantUuid;

    const enteredPaymentAccountId = paymentAccountIdRef.current
      ? paymentAccountIdRef.current.value
      : null;
    const enteredPaymentTaxRateId = paymentTaxRateIdRef.current
      ? paymentTaxRateIdRef.current.value
      : null;

    console.log('restaurantUuid', restaurantUuid);
    console.log('enteredPaymentAccountId', enteredPaymentAccountId);
    console.log('enteredPaymentTaxRateId', enteredPaymentTaxRateId);

    const makeApiCall = true;

    if (!makeApiCall) {
      return;
    }

    const apiURL = authCtx.baseApiUrl + 'restaurants/' + restaurantDetails.restaurantUuid;
    const apiMethod = 'PATCH';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        restaurant_uuid: restaurantUuid,
        payment_account_id: enteredPaymentAccountId,
        payment_tax_rate_id: enteredPaymentTaxRateId,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          const restaurant = responseData.data.restaurants[0];

          const returnedRestaurantDetails = {
            restaurantUuid: restaurant.restaurantUuid,
            name: restaurant.name,
            email: restaurant.email,
            phone: restaurant.phone,
            status: restaurant.status,
            paymentAccountStatus: restaurant.paymentAccountStatus,
            paymentAccountId: restaurant.paymentAccountId,
            paymentTaxRateId: restaurant.paymentTaxRateId,
          };
          console.log(' -- returnedRestaurantDetails -- ');
          console.log(returnedRestaurantDetails);

          // setRestaurantDetails(returnedRestaurantDetails);

          setIsLoading(false);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError && restaurantDetails ? (
        <Paper elevation={4} sx={{ maxWidth: '80rem', width: '100%' }}>
          {/* <Typography variant='h1' component='h1' color='primary' className='header'>
                  Stripe Payment Account
                </Typography> */}
          <Box sx={{ pt: '2rem' }}>
            {restaurantDetails.paymentAccountStatus === 'Pending' ? (
              <Typography align='center' variant='h6'>
                The Client's Stripe Account is Pending....
              </Typography>
            ) : null}

            {restaurantDetails.paymentAccountStatus === 'Active' ? (
              <Typography align='center' variant='h6'>
                The Client's Stripe Account is Active
              </Typography>
            ) : null}

            {!restaurantDetails.paymentAccountStatus ? (
              <Typography align='center' variant='h6'>
                The Client has not created their Stripe Account yet.
              </Typography>
            ) : null}

            {restaurantDetails.paymentAccountStatus === 'Pending' ||
            restaurantDetails.paymentAccountStatus === 'Active' ||
            restaurantDetails.paymentAccountStatus === null ? (
              <form onSubmit={submitHandler}>
                <Box className={classes['form-container']}>
                  <Box className={classes['grid-2']}>
                    <FormControls.InputControl
                      type='text'
                      name='payment_account_id'
                      label='Payment Account Id'
                      defaultValue={restaurantDetails.paymentAccountId}
                      inputRef={paymentAccountIdRef}
                      inputProps={{ maxLength: 50 }}
                      className={classes['mui-control']}
                    />
                    <FormControls.InputControl
                      type='text'
                      name='payment_tax_rate_id'
                      label='Payment Tax Rate Id'
                      defaultValue={restaurantDetails.paymentTaxRateId}
                      inputRef={paymentTaxRateIdRef}
                      inputProps={{ maxLength: 50 }}
                      className={classes['mui-control']}
                    />
                  </Box>

                  <Box component='div' className={classes['actions-container']}>
                    <FormControls.ButtonControl text='Save' type='submit' />
                    {/* <FormControls.ButtonControl text='Cancel' type='button' onClick={reloadData} /> */}
                    {/* {restaurantStatus !== 'New' ? (
                        <FormControls.ButtonControl text='Cancel' type='button' onClick={reloadData} />
                      ) : null} */}
                  </Box>

                  <Box>
                    <Typography>
                      The Payment Account Id links this Restaurant with a Stripe Account.
                    </Typography>
                    <Typography>
                      The Payment Tax Rate Id is set in the Elite Eats Stripe Account.
                    </Typography>
                    <Typography>
                      The Payment Account ID has been partially anonymized for security reasons.
                    </Typography>
                    <Typography>
                      If you enter a new value, it will override the current Payment Account ID.
                    </Typography>
                  </Box>
                </Box>
              </form>
            ) : null}
          </Box>
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default PaymentAccountFormAdmin;

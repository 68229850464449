import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
// import classes from '../CSS/standard-form.module.css';
import classes from '../CSS/standard-mui-form.module.css';

const UserForm = (props) => {
  const {
    onCancel,
    userDetails,
    formHeaderText,
    pageTitle = 'Contact',
    orgType = null,
    orgUuid = null,
  } = props;

  const authCtx = useContext(AuthContext);
  const notificationPreferenceItems = authCtx.notificationPreferencesArray;

  const currentUserUuid = userDetails.userUuid ? userDetails.userUuid : '';
  // const currentFirstName = userDetails.firstName ? userDetails.firstName : '';
  // const currentLastName = userDetails.lastName ? userDetails.lastName : '';
  // const currentPosition = userDetails.position ? userDetails.position : '';
  // const currentPhone = userDetails.phone ? userDetails.phone : '';
  // const currentEmail = userDetails.email ? userDetails.email : '';
  // const currentNotificationPreference = userDetails.notificationPreference
  //   ? userDetails.notificationPreference
  //   : 'Both';
  // const currentStatus = userDetails.status ? userDetails.status : 'Active';

  // console.log('userDetails');
  // console.log(userDetails);

  // console.log('currentFirstName = ' + currentFirstName);
  // console.log('currentLastName = ' + currentLastName);
  // console.log('currentPosition = ' + currentPosition);
  // console.log('currentPhone = ' + currentPhone);
  // console.log('currentEmail = ' + currentEmail);
  console.log('orgType = ' + orgType);
  console.log('orgUuid = ' + orgUuid);

  // const [notificationPreference, setNotificationPreference] = useState(
  //   currentNotificationPreference
  // );
  const [notificationPreference, setNotificationPreference] = useState(
    userDetails.notificationPreference ? userDetails.notificationPreference : 'Email'
  );
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const positionRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  // const userUuid = authCtx.userUuid;
  // const userRole = authCtx.userRole;

  useEffect(() => {
    firstNameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredFirstName = firstNameRef.current.value;
    const enteredLastName = lastNameRef.current.value;
    const enteredPosition = positionRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPassword = passwordRef.current ? passwordRef.current.value : '';
    const enteredPasswordConfirm = passwordConfirmRef.current
      ? passwordConfirmRef.current.value
      : '';
    // const enteredStatus = currentStatus;
    const enteredStatus = userDetails.status ? userDetails.status : 'Active';

    // console.log('enteredFirstName = ' + enteredFirstName);
    // console.log('enteredLastName = ' + enteredLastName);
    // console.log('enteredPosition = ' + enteredPosition);
    // console.log('enteredPhone = ' + enteredPhone);
    // console.log('enteredEmail = ' + enteredEmail);
    // return;

    // Todo:  Add Validation.

    // Make sure that the Passwords Match.
    if (enteredPassword && enteredPasswordConfirm && enteredPassword !== enteredPasswordConfirm) {
      setNotify({
        isOpen: true,
        message: 'The Passwords entered do not match.',
        type: 'warning',
      });
      return;
    }

    let apiURL = '';
    let apiMethod = '';

    if (currentUserUuid !== '' && currentUserUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'users/' + currentUserUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'users';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        first_name: enteredFirstName,
        last_name: enteredLastName,
        position: enteredPosition,
        phone: enteredPhone,
        email: enteredEmail,
        ...(enteredPassword && { password: enteredPassword }),
        notification_preference: notificationPreference,
        status: enteredStatus,
        org_type: orgType,
        org_uuid: orgUuid,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Data successfully updated.',
          type: 'success',
        });

        firstNameRef.current.value = '';
        lastNameRef.current.value = '';
        positionRef.current.value = '';
        phoneRef.current.value = '';
        emailRef.current.value = '';
        if (passwordRef.current) {
          passwordRef.current.value = '';
        }
        if (passwordConfirmRef.current) {
          passwordConfirmRef.current.value = '';
        }
        setNotificationPreference('Both');

        return res.json().then((responseData) => {
          // console.log(' ***** SUCCESS ***** ');
          // console.log(responseData);

          const user = responseData.data.users;

          const returnedData = {
            userUuid: user.userUuid,
            firstName: user.firstName,
            lastName: user.lastName,
            position: user.position,
            phone: user.phone,
            email: user.email,
            notificationPreference: user.notificationPreference,
            status: user.status,
            roleType: user.roleType,
            dateAdded: user.dateAdded,
          };

          onCancel(returnedData);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Data update failed.',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const notificationPreferenceChangeHandler = (event) => {
    // console.log('notificationPreferenceChangeHandler = event.target.value = ' + event.target.value);
    setNotificationPreference(event.target.value);
  };

  return (
    <Box>
      <Box className='title-and-button-holder'>
        <Typography variant='h1' component='h1'>
          {pageTitle}
        </Typography>
        <Box className='button-holder-no-pb'>
          <Button variant='contained' color='accent' onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Box>

      {/* <Paper elevation={4} className='standard-form'> */}
      <Paper elevation={4} sx={{ maxWidth: '120rem', p: '2rem', m: '0 auto' }}>
        {formHeaderText ? (
          <Typography variant='h5' className={classes['mui-form-header']} sx={{ mb: '4rem' }}>
            {formHeaderText}
          </Typography>
        ) : null}
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            {/* <Box className={classes['grid-2']}>
              <Typography>orgType: {orgType}</Typography>
              <Typography>orgUuid: {orgUuid}</Typography>
            </Box> */}

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='first_name'
                label='First Name'
                // defaultValue={currentFirstName}
                defaultValue={userDetails.firstName}
                inputRef={firstNameRef}
                required
                className={classes['mui-control']}
                inputProps={{ maxLength: 50 }}
              />
              <FormControls.InputControl
                type='text'
                name='last_name'
                label='Last Name'
                // defaultValue={currentLastName}
                defaultValue={userDetails.lastName}
                inputRef={lastNameRef}
                required
                className={classes['mui-control']}
                inputProps={{ maxLength: 50 }}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='position'
                label='Position'
                // defaultValue={currentPosition}
                defaultValue={userDetails.position}
                inputRef={positionRef}
                required
                className={classes['mui-control']}
                inputProps={{ maxLength: 50 }}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='phone'
                label='Phone'
                // defaultValue={currentPhone}
                defaultValue={userDetails.phone}
                inputRef={phoneRef}
                required
                className={classes['mui-control']}
                inputProps={{ maxLength: 20 }}
              />
              <FormControls.InputControl
                type='email'
                name='email'
                label='Email'
                // defaultValue={currentEmail}
                defaultValue={userDetails.email}
                inputRef={emailRef}
                required
                autoComplete='username'
                className={classes['mui-control']}
                inputProps={{ maxLength: 50 }}
              />
            </Box>
            {/* {!currentUserUuid ? ( */}
            {!userDetails.userUuid ? (
              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='password'
                  name='password'
                  label='Password'
                  inputRef={passwordRef}
                  required
                  autoComplete='new-password'
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
                <FormControls.InputControl
                  type='password'
                  name='password_confirm'
                  label='Confirm Password'
                  inputRef={passwordConfirmRef}
                  required
                  autoComplete='new-password'
                  className={classes['mui-control']}
                  inputProps={{ maxLength: 50 }}
                />
              </Box>
            ) : null}
            <FormControls.RadioGroupControl
              name='notification_preference'
              label='Notification Preference *'
              value={notificationPreference}
              onChange={notificationPreferenceChangeHandler}
              items={notificationPreferenceItems}
            />
          </Box>
          <Box component='div' className={classes['actions-container']}>
            <FormControls.ButtonControl text='Save' size='small' type='submit' />
            <FormControls.ButtonControl
              text='Cancel'
              size='small'
              onClick={onCancel}
              type='button'
            />
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default UserForm;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import Notification from '../MUI/UI/Notification';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';

const FileAttachmentListCard = ({ fileUploads, allowEdit, onDelete, onEdit }) => {
  const authCtx = useContext(AuthContext);

  const userRole = authCtx.userRole;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  useEffect(() => {
    setIsLoading(false);
    setIsError(false);
  }, []);

  const deleteRecordHandler = (fileUploadUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });

    // console.log(' - deleteRecordHandler - fileUploadUuid = ' + fileUploadUuid);

    deleteData(fileUploadUuid);

    // setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
  };

  const deleteData = async (fileUploadUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'fileupload/' + fileUploadUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log(responseData);
      await response.json();

      // Update the FileUploadList.
      onDelete();

      setNotify({ isOpen: true, message: 'Deleted Successfully', type: 'error' });
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const editRecordHandler = (record) => {
    // console.log(
    //   '### editRecordHandler - record.fileUploadUuid = ' + record.fileUploadUuid + ' ###'
    // );
    // console.log('### record ###');
    // console.log(record);
    onEdit(record);
  };

  const openFileInNewTab = (pathAndFilename) => {
    // console.log('### openFileInNewTab - pathAndFilename = ' + pathAndFilename + ' ###');
    window.open(pathAndFilename, '_blank');
  };

  const displayFileUploadList = () => {
    return fileUploads.map((record, index) => (
      <Paper elevation={4} className='p-10 mb-20' key={index}>
        <Box className='flex-container-side-by-side-edges'>
          <Box>
            <Typography component='div' sx={{ mb: '20px' }}>
              {record.name}
            </Typography>
            <Typography component='pre'>{record.description}</Typography>
          </Box>
          <Box className='flex-container-side-by-side-small-gap flex-no-shrink'>
            <Box>
              {/* {record.pathAndFilename ? ( */}
              {record.pathAndFilename !== '' ? (
                <FormControls.ActionButtonControl
                  color='primary'
                  toolTip='Download / Open File'
                  onClick={() => {
                    openFileInNewTab(record.pathAndFilename);
                  }}
                >
                  <FileDownloadIcon sx={{ fontSize: '1.5rem' }} />
                </FormControls.ActionButtonControl>
              ) : null}

              {userRole === 'User' ? (
                <>
                  {allowEdit ? (
                    <FormControls.ActionButtonControl
                      color='primary'
                      toolTip='Edit Record'
                      onClick={() => {
                        editRecordHandler(record);
                      }}
                    >
                      <EditIcon sx={{ fontSize: '1.5rem' }} />
                    </FormControls.ActionButtonControl>
                  ) : null}

                  <FormControls.ActionButtonControl
                    color='error'
                    toolTip='Delete Record'
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure you want to delete this file attachment?',
                        subTitle: 'It will be permanently deleted.',
                        onConfirm: () => {
                          deleteRecordHandler(record.fileUploadUuid);
                        },
                      });
                    }}
                  >
                    <DeleteIcon sx={{ fontSize: '1.5rem' }} />
                  </FormControls.ActionButtonControl>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Paper>
    ));
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <>
          {fileUploads.length > 0 ? (
            <Box>{fileUploads.length > 0 ? displayFileUploadList() : null}</Box>
          ) : null}
        </>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default FileAttachmentListCard;

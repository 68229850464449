import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import classes from '../CSS/TopMenu.module.css';

const pathStart = '/admin-dashboard/';

const TeamMenu = () => {
  return (
    <Box className='page-min-width'>
      <Box component='div' className={classes['top-menu-container']}>
        <nav className={classes['top-nav']}>
          <ul>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}dashboard`}>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}orders`}>
                Orders
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}users`}>
                Users
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}teams`}>
                Teams
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}restaurants`}>
                Restaurants
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}vendor-referrals`}>
                Referrals
              </NavLink>
            </li>
            {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}leagues`}>
              Leagues
            </NavLink>
          </li> */}
            <li>
              <NavLink activeClassName={classes.active} to={`${pathStart}delivery-locations`}>
                Delivery Locations
              </NavLink>
            </li>
            {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}food-types`}>
              Food Types
            </NavLink>
          </li> */}
            {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}menu-item-categories`}>
              Menu Categories
            </NavLink>
          </li> */}
            {/* <li>
            <NavLink activeClassName={classes.active} to={`${pathStart}account`}>
              Account
            </NavLink>
          </li> */}
          </ul>
        </nav>
      </Box>
    </Box>
  );
};

export default TeamMenu;

import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@mui/material';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import WorkflowMessage from '../UI/WorkflowMessage';
import PageTitleAndButtons from '../UI/PageTitleAndButtons';
import UserDetailsCard from '../User/UserDetailsCard';
import classes from '../CSS/styled-table.module.css';

const UserDetails = (props) => {
  const authCtx = useContext(AuthContext);

  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { onEdit, onShowList, userUuid, pageTitle = 'User Details' } = props;

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  const mainComponent = 'Card';

  useEffect(() => {
    // declare the async data fetching function
    const fetchData = async () => {
      setIsLoading(true);
      // get the data from the api
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);
        // console.log(' -- here -- ');

        let returnedUserDetails = {
          firstName: responseData.data.users[0].firstName,
          lastName: responseData.data.users[0].lastName,
          position: responseData.data.users[0].position,
          phone: responseData.data.users[0].phone,
          email: responseData.data.users[0].email,
          // notificationPreference: responseData.data.users[0].notificationPreference,
          notificationPreference:
            responseData.data.users[0].notificationPreference === 'Both'
              ? 'Email & Text'
              : responseData.data.users[0].notificationPreference,
          status: responseData.data.users[0].status,
          roleType: responseData.data.users[0].roleType,
          dateLastLogin: responseData.data.users[0].dateLastLogin,
          dateAdded: responseData.data.users[0].dateAdded,
          orgName: responseData.data.users[0].orgName,
          orgType: responseData.data.users[0].orgType,
        };
        // console.log(' -- returnedUserDetails ** -- ');
        // console.log(returnedUserDetails);

        // Format values for display.
        if (returnedUserDetails.dateLastLogin !== '') {
          returnedUserDetails.dateLastLogin = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateLastLogin
          );
        }
        if (returnedUserDetails.dateAdded !== '') {
          returnedUserDetails.dateAdded = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateAdded
          );
        }

        setUserDetails(returnedUserDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    // call the function
    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, userUuid, FormatDateAndTimeForDisplay]);

  const deleteUserHandler = (event) => {
    console.log(' -- deleteUserHandler -- ');
    console.log('userUuid = ' + userUuid);

    // showConfirmationHandler();

    // const deleteData = async () => {
    //   // get the data from the api
    //   const response = await fetch(authCtx.baseApiUrl + 'users/' + userId, {
    //     method: 'DELETE',
    //     headers: {
    //       Authorization: authCtx.token,
    //     },
    //   });

    //   if (response.ok) {
    //     const responseData = await response.json();
    //     console.log(responseData);
    //     history.push('/admin-dashboard/users');
    //   } else {
    //     setIsError(true);
    //   }
    //   setIsLoading(false);
    // };

    // deleteData().catch(console.error);
  };

  return (
    <Box>
      {!isLoading && !isError ? (
        <PageTitleAndButtons pageTitle={pageTitle}>
          <Box className='button-holder-no-pb'>
            <Button
              variant='contained'
              size='custom'
              color='accent'
              onClick={() => {
                onEdit(userUuid);
              }}
            >
              Edit
            </Button>
            {userDetails.status !== 'Deleted' && (
              <Button variant='contained' size='custom' color='error' onClick={deleteUserHandler}>
                Delete
              </Button>
            )}
            <Button variant='contained' size='custom' color='accent' onClick={onShowList}>
              Users
            </Button>
          </Box>
        </PageTitleAndButtons>
      ) : null}

      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <>
          {mainComponent === 'Card' ? (
            <Box sx={{ mb: '20px' }}>
              <UserDetailsCard details={userDetails} userRole='Admin' />
            </Box>
          ) : null}

          {mainComponent === 'Table' ? (
            <div className='table-holder'>
              <table className={`${classes['styled-table']} ${classes['full-width']}`}>
                <tbody>
                  <tr>
                    <th>First Name</th>
                    <td>{userDetails.firstName}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{userDetails.lastName}</td>
                  </tr>
                  <tr>
                    <th>Position</th>
                    <td>{userDetails.position}</td>
                  </tr>
                  <tr>
                    <th>{userDetails.orgType} Name</th>
                    <td>{userDetails.orgName}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{userDetails.phone}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{userDetails.email}</td>
                  </tr>
                  <tr>
                    <th>Notification Preference</th>
                    <td>{userDetails.notificationPreference}</td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td>{userDetails.roleType}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{userDetails.status}</td>
                  </tr>
                  <tr>
                    <th>Last Logged In</th>
                    <td>{userDetails.dateLastLogin}</td>
                  </tr>
                  <tr>
                    <th>Created On</th>
                    <td>{userDetails.dateAdded}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default UserDetails;

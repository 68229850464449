import React from 'react';

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const RadioGroupControl = (props) => {
  const { name, label, value, onChange, items, direction = 'row', required = false } = props;

  // console.log('RadioGroupControl - required  = ' + required);

  return (
    <FormControl>
      <FormLabel>
        {label} {required ? '*' : ''}
      </FormLabel>
      {direction === 'row' ? (
        <RadioGroup row name={name} value={value} onChange={onChange}>
          {items.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<Radio />}
              label={item.label}
              extrainfo={item.extrainfo}
            />
          ))}
        </RadioGroup>
      ) : null}
      {direction === 'column' ? (
        <RadioGroup name={name} value={value} onChange={onChange}>
          {items.map((item, index) => (
            <div key={index}>
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
              {item.extrainfo ? (
                <span key={'a' + index} className='small-text'>
                  {item.extrainfo}
                </span>
              ) : null}
            </div>
          ))}
        </RadioGroup>
      ) : null}
    </FormControl>
  );
};

export default RadioGroupControl;

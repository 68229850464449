import React, { useContext, useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import WorkflowMessage from '../UI/WorkflowMessage';
import FileAttachmentListCard from './FileAttachmentListCard';
import ConditionalWrapper from '../../helpers/ConditionalWrapper';

const FileAttachmentList = ({
  orderUuid,
  displayHeaderText = false,
  allowEdit = false,
  onEdit,
  includePaperContainer = false,
}) => {
  const authCtx = useContext(AuthContext);

  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + orderUuid + '/';
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  // const [refreshData, setRefreshData] = useState(false);
  const timestamp = new Date().getTime();
  const [refreshData, setRefreshData] = useState(timestamp);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'fileupload/order/' + orderUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('responseData.data');
        // console.log(responseData.data);

        const transformedRecords = responseData.data.file_uploads.map((record) => {
          return {
            fileUploadUuid: record.fileUploadUuid,
            name: record.name,
            description: record.description,
            filename: record.filename,
            pathAndFilename: record.filename ? baseApiUploadedImagesPath + record.filename : '',
            // pathAndFilename: record.pathAndFilename,
            status: record.status,
          };
        });

        setFileUploads(transformedRecords);

        // console.log('transformedRecords');
        // console.log(transformedRecords);
      } else {
        setIsError(true);
        // console.log('responseData');
        // console.log(responseData);
      }
      setIsLoading(false);
      // setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, orderUuid, baseApiUploadedImagesPath, refreshData]);

  const deleteFileUploadHandler = () => {
    // console.log('##### deleteFileUploadHandler #####');
    // setRefreshData(true);
    refreshDataHandler();
  };

  const editFileUploadHandler = (record) => {
    // console.log('### editFileUploadHandler ###');
    // console.log('### record ###');
    // console.log(record);
    onEdit(record);
  };

  const refreshDataHandler = () => {
    // setRefreshData(true);
    const timestamp = new Date().getTime();
    setRefreshData(timestamp);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError && fileUploads.length > 0 ? (
        <ConditionalWrapper
          condition={includePaperContainer}
          wrapper={(children) => (
            <Paper elevation={4} className='p-10'>
              {children}
            </Paper>
          )}
        >
          <Box>
            {displayHeaderText ? (
              // <Typography variant='h2' component='h2'>
              <Typography variant='h8'>File Attachments</Typography>
            ) : null}

            <FileAttachmentListCard
              fileUploads={fileUploads}
              allowEdit={allowEdit}
              onDelete={deleteFileUploadHandler}
              onEdit={editFileUploadHandler}
            />
          </Box>
        </ConditionalWrapper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default FileAttachmentList;

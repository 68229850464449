import StartingPageContent from '../components/StartingPage/StartingPageContent';

const HomePage = () => {
  return (
    <div className='page-container'>
      <StartingPageContent />
    </div>
  );
};

export default HomePage;

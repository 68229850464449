import { Box, Rating, Typography } from '@mui/material';

import RestaurantReviewImages from './RestaurantReviewImages';

const RestaurantReviewCard = (props) => {
  const { rating, review, reviewImages, baseApiUploadedImagesPath } = props;
  // console.log('RestaurantReviewCard - rating = ' + rating);

  return (
    <>
      {rating !== 0 && review !== '' ? (
        <Box>
          <Typography variant='h2' component='h2'>
            Order Review
          </Typography>
          <Rating name='rating' value={rating} precision={0.5} readOnly size='large' />

          <Typography component='pre'>{review}</Typography>
          <RestaurantReviewImages
            baseApiUploadedImagesPath={baseApiUploadedImagesPath}
            reviewImages={reviewImages}
            imageSize='thumbnail'
            showMoreImagesText={false}
          />
        </Box>
      ) : null}
    </>
  );
};

export default RestaurantReviewCard;

import React from 'react';

import { Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const SelectControl = (props) => {
  const { name, label, value, onChange, options, error, ...other } = props;

  // console.log(' - SelectControl - ');
  // console.log(' value = ' + value);
  // console.log(options);
  // console.log();

  return (
    <Box sx={{ pt: '1.3rem' }}>
      <FormControl fullWidth {...(error ? { error: true } : null)}>
        <InputLabel>{label}</InputLabel>
        <Select
          variant='outlined'
          label={label}
          id={name}
          value={value}
          onChange={onChange}
          size='small'
          {...other}
        >
          <MenuItem value=''>None</MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        {error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    </Box>
  );
};

export default SelectControl;

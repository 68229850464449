import React from 'react';

import { Button } from '@mui/material';

const ButtonControl = (props) => {
  const { text, size, color, variant, onClick, ...other } = props;

  // console.log(' - ButtonControl - variant = ' + variant + ' - size = ' + size);

  return (
    // <Button variant={'text'} size={size || 'large'} color={color || 'primary'} onClick={onClick}>
    //   {text}
    // </Button>
    <Button
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      onClick={onClick}
      {...other}
    >
      {text}
    </Button>
  );
};

export default ButtonControl;

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import DeliveryHoursTable from './DeliveryHoursTable';

const DeliveryHoursCard = (props) => {
  const { mode = 'details', restaurantDetails = {} } = props;

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const rolesAbleToEditDeliveryHours = authCtx.rolesAbleToEditDeliveryHours;

  const history = useHistory();

  const restaurantUuid = restaurantDetails.restaurantUuid;
  // const deliveryFees = restaurantDetails.deliveryFees;

  // const displayEditButton =
  //   restaurantDetails.status !== 'New' && restaurantDetails.status !== 'Deleted' ? true : false;
  const displayEditButton =
    mode === 'details' && restaurantDetails.status !== 'Deleted' ? true : false;

  // console.log('mode = ' + mode);

  const editDeliveryHoursHandler = () => {
    console.log('editDeliveryHoursHandler');
    history.push(dashboardUrl + 'restaurants/delivery-hours/' + restaurantUuid);
  };

  return (
    <>
      {mode === 'details' ? (
        <Box>
          <Box align='center'>
            <DeliveryHoursTable deliveryHours={restaurantDetails.deliveryHours} />
          </Box>

          {displayEditButton && rolesAbleToEditDeliveryHours.includes(userRole) ? (
            <Box align='center' className='pt-10'>
              <Button variant='contained' size='small' onClick={editDeliveryHoursHandler}>
                Edit Delivery Hours
              </Button>
            </Box>
          ) : null}
        </Box>
      ) : null}

      <Box>
        {mode === 'main' ? (
          <Paper elevation={4} className='p-20'>
            <DeliveryHoursTable deliveryHours={restaurantDetails.deliveryHours} />
          </Paper>
        ) : null}
      </Box>
    </>
  );
};

export default DeliveryHoursCard;

import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

import Popup from '../MUI/UI/Popup';
import PdfDeliveryDetails from '../ReactPdf/PdfDeliveryDetails';
import PrintDeliveryDetails from '../ReactPdf/PrintDeliveryDetails';

const DeliveryDetails = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);

  const displayPdfViewer = false;

  const printPdfHandler = () => {
    setOpenPopup(true);
  };

  return (
    <Box>
      <Box sx={{ pb: '2rem' }}>
        <Typography variant='h9'>Delivery Date</Typography>
        {deliveryDateAndTimeForDisplay ? (
          <Typography variant='labelLarge'>{deliveryDateAndTimeForDisplay}</Typography>
        ) : null}
      </Box>

      <Box component='div' className='flex-wrap-container-side-by-side'>
        <div>
          <Typography variant='h8'>Address</Typography>
          <Typography variant='label'>{recordDetails.teamName}</Typography>
          {deliveryLocationForDisplay.name ? (
            <>
              <Typography>{deliveryLocationForDisplay.name}</Typography>
              <Typography>{deliveryLocationForDisplay.addressLine1}</Typography>
              <Typography>{deliveryLocationForDisplay.addressLine2}</Typography>
            </>
          ) : null}
        </div>
        <div>
          {Array.isArray(orderContacts) && orderContacts.length > 0 ? (
            <Box component='div'>
              <Typography variant='h8'>
                {orderContacts.length === 1 ? 'Contact' : 'Contacts'}
              </Typography>

              {orderContacts.map((record, index) => (
                <Box key={index} sx={{ pb: '1rem' }}>
                  <Typography variant='label'>{record.name}</Typography>
                  <Typography>{record.position}</Typography>
                  <Typography>{record.phone}</Typography>
                  <Typography>{record.email}</Typography>
                </Box>
              ))}
            </Box>
          ) : null}
        </div>
      </Box>

      {recordDetails.deliveryInstructions !== '' ? (
        <Box sx={{ pt: '2rem' }}>
          <Typography variant='h8'>Delivery Instructions</Typography>
          <Typography component='pre'>{recordDetails.deliveryInstructions}</Typography>
        </Box>
      ) : null}

      {recordDetails.teamInstructions !== '' ? (
        <Box sx={{ pt: '4rem', pb: '4rem' }}>
          <Typography variant='h8'>Team Instructions</Typography>
          <Typography component='pre'>{recordDetails.teamInstructions}</Typography>
        </Box>
      ) : null}

      <Box align='right' className='button-holder'>
        <Button variant='contained' size='small' onClick={printPdfHandler}>
          Print
        </Button>

        <PDFDownloadLink
          document={
            <PdfDeliveryDetails
              deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
              deliveryLocationForDisplay={deliveryLocationForDisplay}
              recordDetails={recordDetails}
              orderContacts={orderContacts}
            />
          }
          fileName={'EliteEatsOrderDeliveryInstructions-' + recordDetails.orderNumber}
        >
          <Button variant='contained' size='small'>
            Download
          </Button>
        </PDFDownloadLink>
      </Box>

      {displayPdfViewer ? (
        <Box sx={{ pt: '2rem' }}>
          <PDFViewer className='pdf-viewer'>
            <PdfDeliveryDetails
              deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
              deliveryLocationForDisplay={deliveryLocationForDisplay}
              recordDetails={recordDetails}
              orderContacts={orderContacts}
            />
          </PDFViewer>
        </Box>
      ) : null}

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth='md'
        title='Print Delivery Details'
      >
        <PrintDeliveryDetails
          deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
          deliveryLocationForDisplay={deliveryLocationForDisplay}
          recordDetails={recordDetails}
          orderContacts={orderContacts}
        />
      </Popup>
    </Box>
  );
};

export default DeliveryDetails;

import React from 'react';
import { TextField } from '@mui/material';

const InputControl = (props) => {
  const { type, name, label, value, error = null, onChange, ...other } = props;
  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      type={type}
      label={label}
      name={name}
      id={name}
      value={value}
      size='small'
      onChange={onChange}
      fullWidth
      {...(error ? { error: true, helperText: error } : null)}
      // {...(error && { error: true, helperText: error })}
      {...other}
    />
  );
};

// const InputControl = (props) => {
//   const { type, name, label, value, error = null, onChange, ...other } = props;
//   return (
//     <TextField
//       InputLabelProps={{ shrink: true }}
//       variant='outlined'
//       type={type}
//       label={label}
//       name={name}
//       value={value}
//       size='small'
//       onChange={onChange}
//       {...(error ? { error: true, helperText: error } : null)}
//       // {...(error && { error: true, helperText: error })}
//       {...other}
//     />
//   );
// };

export default InputControl;

import { Autocomplete, Box, FormControl, TextField } from '@mui/material';

const AutocompleteMultipleControl = (props) => {
  const { name, label, value, inputRef, onChange, options, error, placeholder, ...other } = props;

  // console.log(' - SelectControl - ');
  // console.log(' value ');
  // console.log(value);
  // console.log(' options ');
  // console.log(options);
  // console.log('label');
  // console.log(label);

  return (
    <Box>
      <FormControl fullWidth {...(error ? { error: true } : null)}>
        <Autocomplete
          disablePortal
          options={options}
          size='small'
          autoHighlight
          autoSelect
          onChange={onChange}
          inputValue={value}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              inputRef={inputRef}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
            />
          )}
          {...other}
        />
      </FormControl>
    </Box>
  );
};

export default AutocompleteMultipleControl;

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import RestaurantForm from '../Restaurant/RestaurantForm';
import RestaurantImagesForm from '../Restaurant/RestaurantImagesForm';
import RestaurantDetailsCard from '../Restaurant/RestaurantDetailsCard';

const VendorSignupComplete = (props) => {
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;

  // const userRole = authCtx.userRole;
  const restaurantUuid = authCtx.orgDetails.orgUuid ? authCtx.orgDetails.orgUuid : '';

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const orgFolder = restaurantUuid;
  baseApiUploadedImagesPath += orgFolder + '/';

  // console.log('VendorSignupComplete');
  // console.log('userRole = ' + userRole);
  // console.log('restaurantUuid = ' + restaurantUuid);
  // console.log('baseApiUploadedImagesPath = ' + baseApiUploadedImagesPath);

  const history = useHistory();

  const [displayedComponent, setDisplayedComponent] = useState('Details');
  const [foodTypes, setFoodTypes] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});

  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { FormatDateAndTimeForDisplay, FormatTimeForDisplay } = useFormatDateAndTime();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      // console.log(' -- getting globalfoodtypes -- ');

      const response = await fetch(authCtx.baseApiUrl + 'globalfoodtypes', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('globalfoodtypes - responseData');
        // console.log(responseData);

        const filteredRecords = responseData.data.global_food_types.filter(
          (record) => record.status === 'Active'
        );

        const transformedRecords = filteredRecords.map((record) => {
          return {
            id: record.name,
            title: record.name,
          };
        });

        // console.log('transformedRecords');
        // console.log(transformedRecords);

        setFoodTypes(transformedRecords);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'globaldata/leagues', {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(' -- response.ok -- ');
        // console.log(responseData.data.active_leagues);

        const transformedLeagues = responseData.data.active_leagues.map((league) => {
          return {
            name: league,
          };
        });

        // console.log('transformedLeagues');
        // console.table(transformedLeagues);

        setLeagues(transformedLeagues);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await fetch(authCtx.baseApiUrl + 'restaurants/' + restaurantUuid, {
        headers: {
          Authorization: authCtx.token,
        },
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log(responseData);

        const returnedRestaurantDetails = {
          restaurantUuid: responseData.data.restaurants[0].restaurantUuid,
          name: responseData.data.restaurants[0].name,
          description: responseData.data.restaurants[0].description,
          address1: responseData.data.restaurants[0].address1,
          address2: responseData.data.restaurants[0].address2,
          city: responseData.data.restaurants[0].city ? responseData.data.restaurants[0].city : '',
          state: responseData.data.restaurants[0].state
            ? responseData.data.restaurants[0].state
            : '',
          zipcode: responseData.data.restaurants[0].zipcode,
          email: responseData.data.restaurants[0].email,
          phone: responseData.data.restaurants[0].phone,
          foodType: responseData.data.restaurants[0].foodType,
          leagueExperience: responseData.data.restaurants[0].leagueExperience,
          // deliveryHours: responseData.data.restaurants[0].deliveryHours,
          deliveryHoursStart: responseData.data.restaurants[0].deliveryHoursStart,
          deliveryHoursEnd: responseData.data.restaurants[0].deliveryHoursEnd,
          deliveryHoursStartFormatted: responseData.data.restaurants[0].deliveryHoursStart
            ? FormatTimeForDisplay(responseData.data.restaurants[0].deliveryHoursStart)
            : '',
          deliveryHoursEndFormatted: responseData.data.restaurants[0].deliveryHoursEnd
            ? FormatTimeForDisplay(responseData.data.restaurants[0].deliveryHoursEnd)
            : '',
          deliveryHours: responseData.data.restaurants[0].deliveryHours,
          // orderLeadTime: responseData.data.restaurants[0].orderLeadTime,
          orderLeadTime: responseData.data.restaurants[0].orderLeadTime
            ? responseData.data.restaurants[0].orderLeadTime
            : 0,
          taxRate: responseData.data.restaurants[0].taxRate,
          status: responseData.data.restaurants[0].status,
          headerImage: responseData.data.restaurants[0].headerImage,
          listImage: responseData.data.restaurants[0].listImage,
          dateAdded: responseData.data.restaurants[0].dateAdded,
          dateUpdated: responseData.data.restaurants[0].dateUpdated,
          dateAddedFormatted: responseData.data.restaurants[0].dateAdded
            ? FormatDateAndTimeForDisplay(responseData.data.restaurants[0].dateAdded)
            : '',
          dateUpdatedFormatted: responseData.data.restaurants[0].dateUpdated
            ? FormatDateAndTimeForDisplay(responseData.data.restaurants[0].dateUpdated)
            : '',
        };
        // console.log(' -- returnedRestaurantDetails -- ');
        // console.log(returnedRestaurantDetails);

        setSelectedRestaurant(returnedRestaurantDetails);

        if (returnedRestaurantDetails.address1 === '') {
          setDisplayedComponent('Form');
        }
      } else {
        // If a 401 is returned then there is an issue with the session (most likely).
        if (responseData.statusCode === 401) {
          history.push(dashboardUrl + 'sessionexpired');
        }

        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [
    authCtx.baseApiUrl,
    authCtx.token,
    restaurantUuid,
    FormatDateAndTimeForDisplay,
    FormatTimeForDisplay,
    refreshData,
    dashboardUrl,
    history,
  ]);

  const backFromFormHandler = () => {
    // console.log('### backFromFormHandler ###');
    setRefreshData(true);
    setDisplayedComponent('Details');
  };

  const editDetailsHandler = () => {
    // console.log('### editDetailsHandler ###');
    setRefreshData(true);
    setDisplayedComponent('Form');
  };

  // const testHandler = () => {
  //   console.log('********** testHandler ************');
  // };

  const editImagesHandler = () => {
    // console.log('### editImagesHandler ###');
    setDisplayedComponent('ImagesForm');
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message='Something did not work...' /> : null}

      {!isLoading && !isError ? (
        <>
          <Box className='pb-20'>
            <Alert severity='success'>
              <Typography>
                A member of our onboarding team will be in touch to help finalize your Elite Eats
                Account.
              </Typography>
              {displayedComponent === 'Details' ? (
                <Typography>
                  Complete your profile by clicking on the PROFILE DETAILS Button and filling out
                  the displayed form.
                </Typography>
              ) : null}
              {displayedComponent === 'Form' ? (
                <Typography>Complete your profile by filling out the Form below.</Typography>
              ) : null}
              {displayedComponent === 'ImagesForm' ? (
                <Typography>Complete your profile by selecting a Header and List image.</Typography>
              ) : null}
            </Alert>
          </Box>

          <Box className='title-and-button-holder'>
            <Box>
              <Typography variant='h1' component='h1'>
                Vendor Profile
              </Typography>
            </Box>
            <Box className='button-holder-no-pb'>
              {displayedComponent !== 'Form' ? (
                <Button
                  variant='contained'
                  size='custom'
                  color='accent'
                  onClick={editDetailsHandler}
                >
                  Edit Profile
                </Button>
              ) : null}

              {displayedComponent === 'Form' ? (
                <Button
                  variant='contained'
                  size='custom'
                  color='accent'
                  onClick={backFromFormHandler}
                >
                  Profile Details
                </Button>
              ) : null}
            </Box>
          </Box>
        </>
      ) : null}

      {!isLoading && !isError && displayedComponent === 'Form' ? (
        <RestaurantForm
          restaurantDetails={selectedRestaurant}
          globalFoodTypesForForm={foodTypes}
          globalLeaguesForForm={leagues}
          reloadData={backFromFormHandler}
          onCancel={backFromFormHandler}
          onEditImages={editImagesHandler}
        />
      ) : null}

      {!isLoading && !isError && displayedComponent === 'ImagesForm' ? (
        <Box>
          <RestaurantImagesForm
            restaurantDetails={selectedRestaurant}
            onEditImages={editImagesHandler}
            onCancel={editDetailsHandler}
          />
        </Box>
      ) : null}

      {!isLoading && !isError && displayedComponent === 'Details' ? (
        <Box>
          <RestaurantDetailsCard
            restaurantDetails={selectedRestaurant}
            onEdit={editDetailsHandler}
            baseApiUploadedImagesPath={baseApiUploadedImagesPath}
            reloadData={backFromFormHandler}
          />
        </Box>
      ) : null}
    </>
  );
};

export default VendorSignupComplete;

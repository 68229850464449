import { Route, Redirect, Switch } from 'react-router-dom';

import TeamMenu from '../components/TeamDashboard/TeamMenu';
import NewOrder from '../components/TeamDashboard/NewOrder';
import Orders from '../components/Orders/Orders';
import DeliveryLocations from '../components/TeamDashboard/DeliveryLocations';
import Receipts from '../components/TeamDashboard/Receipts';
import Reviews from '../components/TeamDashboard/Reviews';
import Dashboard from '../components/TeamDashboard/Dashboard';
import Restaurants from '../components/TeamDashboard/Restaurants';
import ReferVendor from '../components/TeamDashboard/ReferVendor';
import TeamProfile from '../components/TeamDashboard/TeamProfile';
import UserProfile from '../components/TeamDashboard/UserProfile';
import DeliveryLocationAdd from '../components/DeliveryLocations/DeliveryLocationAdd';
import DeliveryLocationEdit from '../components/DeliveryLocations/DeliveryLocationEdit';
import MenuItemList from '../components/Menu/MenuItemList';
import UserMenuItemDetails from '../components/Menu/UserMenuItemDetails';
import PaymentSuccessful from '../components/Orders/PaymentSuccessful';
import CheckoutSuccessful from '../components/Orders/CheckoutSuccessful';

import SessionExpired from './SessionExpired';

const TeamDashboardPage = () => {
  const pathStart = '/team-dashboard/';

  return (
    <>
      <TeamMenu />
      <div className='page-container-after-top-menu'>
        <Switch>
          <Route path={pathStart + 'sessionexpired'} exact>
            <SessionExpired />
          </Route>
          <Route path={pathStart + 'new-order'}>
            <NewOrder />
          </Route>
          <Route path={pathStart + 'orders'} exact>
            <Orders />
          </Route>
          <Route path={pathStart + 'orders/:orderUuid'}>
            <Orders />
          </Route>
          <Route path={pathStart + 'order-paid/:orderUuid'}>
            <PaymentSuccessful />
          </Route>
          <Route path={pathStart + 'checkout-successful/:orderUuid'}>
            <CheckoutSuccessful />
          </Route>
          <Route path={pathStart + 'receipts'}>
            <Receipts />
          </Route>
          <Route path={pathStart + 'reviews'}>
            <Reviews />
          </Route>
          <Route path={pathStart + 'reviews/:restaurantUuid'}>
            <Reviews />
          </Route>
          <Route path={pathStart + 'delivery-locations'} exact>
            <DeliveryLocations />
          </Route>
          <Route path={pathStart + 'delivery-locations/add'} exact>
            <DeliveryLocationAdd dashboardUrl={pathStart} />
          </Route>
          <Route path={pathStart + 'delivery-locations/edit/:deliveryLocationUuid'}>
            <DeliveryLocationEdit />
          </Route>
          <Route path={pathStart + 'delivery-locations/details/:deliveryLocationUuid'}>
            <DeliveryLocations mode='details' urlStart={pathStart} />
          </Route>
          <Route path={pathStart + 'refer-vendor'}>
            <ReferVendor />
          </Route>
          <Route path={pathStart + 'restaurants'} exact>
            <Restaurants />
          </Route>
          <Route path={pathStart + 'restaurants/details/:restaurantUuid'} exact>
            <Restaurants mode='details' urlStart={pathStart} />
          </Route>
          <Route path={pathStart + 'restaurants/menu-items/:restaurantUuid'} exact>
            <MenuItemList />
          </Route>
          <Route path={pathStart + 'menu-items/details/:menuItemUuid'}>
            <UserMenuItemDetails />
          </Route>
          <Route path={pathStart + 'dashboard'}>
            <Dashboard />
          </Route>
          <Route path={pathStart + 'team-profile'}>
            <TeamProfile />
          </Route>
          <Route path={pathStart + 'user-profile'}>
            <UserProfile />
          </Route>
          <Route path='*'>
            <Redirect to={pathStart + 'dashboard'} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default TeamDashboardPage;

import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

const TeamDetailsCard = (props) => {
  const { teamDetails, baseApiUploadedImagesPath } = props;
  // Removed 'onEdit' from the list above because it wasn't used.

  const displayCardVersion = 2;

  return (
    <Paper elevation={4} sx={{ p: '20px' }}>
      {displayCardVersion === 2 ? (
        <Box className='flex-container-side-by-side'>
          {teamDetails.logoImage ? (
            <Box className='uploaded-team-logo-image-100'>
              <img
                src={baseApiUploadedImagesPath + teamDetails.logoImage}
                alt='Current Team Logo'
              />
            </Box>
          ) : null}

          <Box className='flex-no-shrink'>
            <Typography variant='h1' component='h1'>
              {teamDetails.name}
            </Typography>
            <Typography component='div' className='pb-10'>
              {'League: ' + teamDetails.league}
            </Typography>

            <Typography>{'Status: ' + teamDetails.status}</Typography>
          </Box>

          <Box>
            <Typography variant='labelAbove'>Team Catering Instructions</Typography>
            <Typography component='pre'>
              {teamDetails.teamInstructions
                ? teamDetails.teamInstructions
                : '* General Instructions for Delivery People'}
            </Typography>
          </Box>
        </Box>
      ) : null}

      {displayCardVersion === 1 ? (
        <Box>
          <Box className='flex-wrap-container'>
            <Typography variant='h5' component='div'>
              {teamDetails.name}
              <Typography variant='label' sx={{ pl: '4rem' }}>
                {'(' + teamDetails.league + ')'}
              </Typography>
            </Typography>
            {teamDetails.logoImage ? (
              <Box>
                <img
                  src={baseApiUploadedImagesPath + teamDetails.logoImage}
                  alt='Current Team Logo'
                  className='team-logo'
                />
              </Box>
            ) : null}
          </Box>

          <Box sx={{ pt: '2rem' }}>
            <Typography variant='labelAbove'>Team Catering Instructions</Typography>
            <Typography>
              {teamDetails.teamInstructions
                ? teamDetails.teamInstructions
                : '* General Instructions for Delivery People'}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Paper>
  );
};

export default TeamDetailsCard;

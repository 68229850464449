import { useState, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { displayTeamCharge, teamChargeLabel, deliveryFeeLabel } from '../../constants/global';

import AuthContext from '../../store/auth-context';
import SalesTaxMessage from '../Widgets/SalesTaxMessage';
import Notification from '../MUI/UI/Notification';

const PaymentForm = ({ orderDetails }) => {
  const authCtx = useContext(AuthContext);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const userDetails = authCtx.userDetails;
  // console.log('userDetails', userDetails);
  // console.log('orderDetails', orderDetails);

  const makePayment = () => {
    const processPayment = true;
    if (!processPayment) {
      return;
    }

    const apiURL = authCtx.baseApiUrl + 'orderpayment';
    const apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        user_uuid: userDetails.userUuid,
        order_uuid: orderDetails.orderUuid,
        subtotal: orderDetails.subtotal,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          if (responseData.data.checkout_url) {
            window.location.href = responseData.data.checkout_url;
          } else {
            console.log('no checkoutUrl');
          }
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box>
      <Box>
        <Typography variant='h9'>Delivery Date</Typography>
        {orderDetails.deliveryDateAndTimeForDisplay ? (
          <Typography variant='labelLarge'>{orderDetails.deliveryDateAndTimeForDisplay}</Typography>
        ) : null}
      </Box>
      <Box align='right' sx={{ pt: '2rem' }}>
        <table className='table-simple table-simple-with-padding'>
          <tbody>
            <tr>
              <td className='table-td-label-np'>Subtotal (Cart Items)</td>
              <td className='table-td-number table-td-label-np'>
                {orderDetails['subtotalFormatted']}
              </td>
            </tr>

            {displayTeamCharge &&
            orderDetails['teamChargeFormatted'] &&
            orderDetails['teamCharge'] > 0 ? (
              <>
                <tr>
                  <td className='table-td-text-right'>{teamChargeLabel}</td>
                  <td className='table-td-number'>{orderDetails['teamChargeFormatted']}</td>
                </tr>
              </>
            ) : null}

            {orderDetails['deliveryFeeFormatted'] && orderDetails['deliveryFee'] !== 0 ? (
              <>
                <tr>
                  <td className='table-td-text-right'>{deliveryFeeLabel}</td>
                  <td className='table-td-number'>{orderDetails['deliveryFeeFormatted']}</td>
                </tr>
              </>
            ) : null}

            {orderDetails['tipFormatted'] && orderDetails['tip'] > 0 ? (
              <>
                <tr>
                  <td className='table-td-text-right'>Gratuity (for the driver)</td>
                  <td className='table-td-number'>{orderDetails['tipFormatted']}</td>
                </tr>
              </>
            ) : null}

            {orderDetails['calculatedTotalFormatted'] ? (
              <>
                <tr>
                  <td className='table-td-label-np with-padding'>Total</td>
                  <td className='table-td-number table-td-label-np with-padding'>
                    {orderDetails['calculatedTotalFormatted']}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>

        <Box className='pt-20'>
          <SalesTaxMessage textAlign='right' />
        </Box>

        <Box sx={{ pt: '2rem' }}>
          <Button variant='contained' onClick={() => makePayment()}>
            Pay for Order
          </Button>
        </Box>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default PaymentForm;

import { useState, useRef, useEffect, useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Alert, Box, Button, Paper, Tooltip, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import StatusSelectFormField from '../FormControls/StatusSelectFormField';
import classes from '../CSS/standard-mui-form.module.css';

const TeamForm = (props) => {
  // console.log(' ######################################################');
  // console.log('-- Loading TeamForm --');
  // console.log(' ######################################################');

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;

  const displayTeamLogoWithLink = false;

  // let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  // const orgFolder = authCtx.orgDetails.orgUuid;
  // baseApiUploadedImagesPath += orgFolder + '/';
  // console.log(authCtx.orgDetails);
  // console.log('baseApiUploadedImagesPath');
  // console.log(baseApiUploadedImagesPath);

  const { teamDetails, leagues, onCancel, baseApiUploadedImagesPath } = props;

  const teamUuid = teamDetails.teamUuid ? teamDetails.teamUuid : '';

  console.log('### teamDetails ###');
  console.log(teamDetails);
  console.log('teamUuid', teamUuid);

  // console.log('leagues');
  // console.log(leagues);

  // console.log('teamDetails.league = ' + teamDetails.league);

  const [league, setLeague] = useState(teamDetails.league ? teamDetails.league : 'NFL');
  const [teamStatus, setTeamStatus] = useState(teamDetails.status ? teamDetails.status : 'New');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  // const [uploadedFilename, setUploadedFilename] = useState('');

  // console.log('league = ' + league);

  const nameRef = useRef();
  // const descriptionRef = useRef();
  // const leagueRef = useRef();
  // const logoImageRef = useRef();
  const teamInstructionsRef = useRef();

  const onFileChange = (event) => {
    // console.log('-- onFileChange -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    // setSelectedImageName(files[0].name);

    // console.log('files');
    // console.log(files);

    const filename = files[0].name;

    setSelectedImageName(filename);

    // console.log('filename');
    // console.log(filename);

    fileReader.onload = (event) => {
      setSelectedImage(event.target.result);
    };

    // fileReader.onload = () => {
    //   setSelectedImage(fileReader.result);
    // }
  };

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    // setDisplayMessage(null);
    // setIsLoading(true);
    // setIsError(false);

    const enteredName = nameRef.current.value;
    // const enteredDescription = descriptionRef.current.value;
    // const enteredLeague = leagueRef.current.value;
    // const enteredLogoImage = logoImageRef.current.value;
    const enteredTeamInstructions = teamInstructionsRef.current.value;

    let apiURL = '';
    let apiMethod = '';

    if (teamUuid !== '' && teamUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'teams/' + teamUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'teams';
      apiMethod = 'POST';
    }

    console.log('teamUuid', teamUuid);
    console.log('apiURL', apiURL);
    console.log('apiMethod', apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        // description: enteredDescription,
        // league: enteredLeague,
        league: league,
        // logo_image: enteredLogoImage,
        team_instructions: enteredTeamInstructions,
        status: teamStatus,
        logo_image: selectedImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        nameRef.current.value = '';
        // descriptionRef.current.value = '';
        // leagueRef.current.value = '';
        // logoImageRef.current.value = '';
        teamInstructionsRef.current.value = '';

        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          const returnedData = {
            teamUuid: responseData.data.teams[0].teamUuid,
            name: responseData.data.teams[0].name,
            description: responseData.data.teams[0].description,
            league: responseData.data.teams[0].league,
            logoImage: responseData.data.teams[0].logoImage,
            teamInstructions: responseData.data.teams[0].teamInstructions,
            status: responseData.data.teams[0].status,
          };

          if (userRole === 'User') {
            authCtx.updateOrgValues(
              responseData.data.teams[0].teamUuid,
              responseData.data.teams[0].name
            );
          }

          props.onCancel(returnedData);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const handleSelectLeague = (event, value) => {
    console.log('handleSelectLeague - event.target.value = ' + event.target.value);
    // console.log('value');
    // console.log(value);
    if (value) {
      const leagueValue = value.name ? value.name : '';
      console.log('leagueValue = ' + leagueValue);
      setLeague(leagueValue);
    }
  };

  const statusChangeHandler = (event) => {
    // console.log('statusChangeHandler - event.target.value = ' + event.target.value);
    setTeamStatus(event.target.value);
  };

  return (
    <Box>
      <Paper elevation={4}>
        {/* <Typography variant='h5' className={classes['mui-form-header']}>
          Create Team Account
        </Typography> */}
        <form onSubmit={submitHandler} encType='multiport/form-data'>
          <Box className={classes['form-container']}>
            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='name'
                label='Team Name'
                defaultValue={teamDetails.name}
                inputRef={nameRef}
                required
                inputProps={{ maxLength: 50 }}
                className={classes['mui-control']}
              />
              <Box className={classes['grid-2']}>
                <FormControls.AutocompleteControl
                  name='league'
                  label='League *'
                  options={leagues}
                  getOptionLabel={(option) => option.name ?? option}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  required
                  className={classes['mui-control']}
                  defaultValue={{ name: teamDetails.league }}
                  onChange={handleSelectLeague}
                />

                {userRole !== 'Vendor' ? (
                  <StatusSelectFormField
                    currentStatus={teamStatus}
                    onChange={statusChangeHandler}
                  />
                ) : null}
              </Box>
            </Box>

            {/* <FormControls.InputControl
              type='text'
              name='description'
              label='Description'
              inputRef={descriptionRef}
              defaultValue={teamDetails.description}
              className={classes['mui-control']}
              multiline
              minRows={3}
              maxRows={12}
            /> */}

            {/* <FormControls.InputControl
              type='text'
              name='logo_image'
              label='Logo Image'
              defaultValue={teamDetails.logoImage}
              inputRef={logoImageRef}
              inputProps={{ maxLength: 255 }}
              className={classes['mui-control']}
            /> */}

            <FormControls.InputControl
              type='text'
              name='team_instructions'
              label='Team Catering Instructions'
              inputRef={teamInstructionsRef}
              defaultValue={teamDetails.teamInstructions}
              className={classes['mui-control']}
              multiline
              minRows={8}
              maxRows={16}
            />

            {/* <Box sx={{ border: '1px solid red' }}>
              <Typography variant='caption' sx={{ pr: '1rem' }}>
                Logo Image
              </Typography>
              <input type='file' name='image' onChange={onFileChange} />
            </Box> */}
          </Box>

          <Box component='div' className={classes['actions-container']}>
            <FormControls.ButtonControl text='Save' type='submit' />
            <FormControls.ButtonControl text='Cancel' type='button' onClick={onCancel} />
          </Box>

          <Box align='center' sx={{ py: '20px' }}>
            <Box>
              {teamDetails.logoImage && !displayTeamLogoWithLink ? (
                <img
                  src={baseApiUploadedImagesPath + teamDetails.logoImage}
                  alt='Current Team Logo'
                  className='uploaded-team-logo-image'
                />
              ) : null}

              {teamDetails.logoImage && displayTeamLogoWithLink ? (
                <Tooltip title='Click to open the full size Logo Image in a New Tab'>
                  <a
                    href={baseApiUploadedImagesPath + teamDetails.logoImage}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img
                      src={baseApiUploadedImagesPath + teamDetails.logoImage}
                      alt='Current Team Logo'
                      className='uploaded-team-logo-image'
                    />
                  </a>
                </Tooltip>
              ) : null}

              {!teamDetails.logoImage ? (
                <Box className='uploaded-team-logo-image-filler'>NO IMAGE</Box>
              ) : null}
            </Box>

            <Box sx={{ pt: '20px' }}>
              <Box className='pb-20'>
                <Typography variant='caption'>(Dimensions: 200px wide, 200px high)</Typography>
              </Box>

              <Button variant='contained' component='label' size='small'>
                Select Image File
                <input
                  hidden
                  accept='image/*'
                  multiple
                  type='file'
                  name='logo_image'
                  id='logo_image'
                  onChange={onFileChange}
                />
              </Button>

              {selectedImageName ? (
                <Box align='center' sx={{ pt: '20px' }}>
                  <Alert severity='info' align='center' sx={{ width: '500px' }}>
                    Image file selected. Click the SAVE button to upload the image.
                  </Alert>
                </Box>
              ) : null}
            </Box>
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default TeamForm;

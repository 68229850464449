import React, { useContext } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import RestaurantBasicInformationCard from './RestaurantBasicInformationCard';
import RestaurantDetailsTableSimple from './RestaurantDetailsTableSimple';
import RestaurantRatingWidget from '../UI/RestaurantRatingWidget';
import DeliveryHoursCard from '../DeliveryHours/DeliveryHoursCard';
import DeliveryFeesListCard from '../DeliveryFees/DeliveryFeesListCard';

const RestaurantDetailsCard = (props) => {
  // console.log('######## RestaurantDetailsCard ########');
  const {
    mode = 'details',
    restaurantDetails,
    baseApiUploadedImagesPath,
    onEdit = null,
    reloadData,
  } = props;

  // console.log('RestaurantDetailsCard');
  // console.log('props', props);
  // console.log('restaurantDetails');
  // console.log(restaurantDetails);

  const authCtx = useContext(AuthContext);
  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  // const userRole = authCtx.userRole;
  const restaurantUuid = restaurantDetails.restaurantUuid;
  const restaurantStatus = restaurantDetails.status ? restaurantDetails.status : null;

  return (
    <Paper elevation={4} sx={{ mb: '20px' }}>
      {restaurantDetails.headerImage ? (
        <Box sx={{ pb: '1rem' }}>
          <img
            src={baseApiUploadedImagesPath + restaurantDetails.headerImage}
            alt={restaurantDetails.name + ' Logo'}
            className='header-image-cover'
          />
        </Box>
      ) : null}

      <Box sx={{ p: '20px' }}>
        <Box className='restaurant-details-layout'>
          <Box>
            <RestaurantBasicInformationCard restaurantDetails={restaurantDetails} />

            {restaurantDetails.rating && restaurantDetails.numberOfRatings ? (
              <Box sx={{ pt: '20px' }}>
                <RestaurantRatingWidget
                  rating={restaurantDetails.rating}
                  numberOfRatings={restaurantDetails.numberOfRatings}
                />
              </Box>
            ) : null}

            <RestaurantDetailsTableSimple
              restaurantDetails={restaurantDetails}
              userRole={userRole}
              dashboardUrl={dashboardUrl}
            />

            {userRole !== 'User' ? (
              <Box className='pt-20'>
                <Typography variant='label' className='pb-10'>
                  Notification Email Addresses
                </Typography>
                <Typography>{restaurantDetails.notificationEmailAddresses}</Typography>
                <Box>
                  <Typography className='small-text pt-10'>
                    All Order Nofication Emails will be sent to the email addresses listed above.
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Box>

          <Box className='delivery-info-layout'>
            <DeliveryHoursCard
              mode={mode}
              displayTitle={true}
              restaurantDetails={restaurantDetails}
              reloadData={reloadData}
            />

            <DeliveryFeesListCard
              mode={mode}
              restaurantDetails={restaurantDetails}
              restaurantUuid={restaurantUuid}
              deliveryFees={restaurantDetails.deliveryFees}
            />
          </Box>
        </Box>

        {onEdit && restaurantStatus !== 'New' ? (
          <Box align='center'>
            <button onClick={() => onEdit(restaurantDetails.restaurantUuid)} className='small'>
              Edit
            </button>
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
};

export default RestaurantDetailsCard;

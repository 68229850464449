import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import WorkflowMessage from '../UI/WorkflowMessage';
import classes from './OrderMessages.module.css';

const OrderMessagesList = (props) => {
  const authCtx = useContext(AuthContext);
  // const orderCtx = useContext(OrderContext);

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  const { orderUuid, refreshData, mode } = props;

  // console.log('OrderMessagesList - refreshData = ' + refreshData);

  const [orderMessages, setOrderMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const scrollToBottomOfMessages = () => {
    // console.log('scrollToBottomOfMessages');
    let scroll_to_bottom = document.getElementById('scroll-to-bottom');
    if (scroll_to_bottom) {
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    }

    // console.log('scroll_to_bottom.scrollHeight = ' + scroll_to_bottom.scrollHeight);
  };

  useEffect(() => {
    const getAllOrderMessages = async () => {
      // console.log('***** getAllOrderMessages *****');

      setIsLoading(true);
      setIsError(false);

      const response = await fetch(authCtx.baseApiUrl + 'ordermessages/order/' + orderUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();

        const transformedRecords = responseData.data.order_messages.map((record) => {
          return {
            ordermessageUuid: record.additionalChargeUuid,
            userRole: record.userRole,
            firstName: record.firstName,
            lastName: record.lastName,
            message: record.message,
            dateAdded: record.dateAdded,
            dateAddedFormatted: record.dateAdded
              ? FormatDateAndTimeForDisplay(record.dateAdded)
              : '',
          };
        });

        setOrderMessages(transformedRecords);
      } else {
        console.log(' ***** ERROR ** getAllOrderMessages ** ERROR ***** ');
      }

      setIsLoading(false);
    };

    getAllOrderMessages();
  }, [authCtx.baseApiUrl, authCtx.token, orderUuid, refreshData, FormatDateAndTimeForDisplay]);

  useEffect(() => {
    setTimeout(scrollToBottomOfMessages, 500);
  }, [refreshData]);

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message='Something did not work...' /> : null}

      {/* {!isLoading && !isError && mode === 'review-order' && orderMessages.length > 0 ? ( */}
      {/* {!isLoading && !isError && mode !== 'special-requests' && orderMessages.length > 0 ? ( */}
      {!isLoading && !isError && mode !== 'special-requests' ? (
        // <Typography variant='h2' component='h2'>
        <Typography variant='h8'>Messages</Typography>
      ) : null}

      {orderMessages.length === 0 ? (
        <Typography variant='body1' align='center'>
          No Messages for this Order
        </Typography>
      ) : null}

      {orderMessages.length > 0 ? (
        <Box
          id='scroll-to-bottom'
          className={
            authCtx.userRole === 'Admin'
              ? classes['order-messages-list-scrolling-admin']
              : classes['order-messages-list-scrolling']
          }
        >
          {orderMessages.map((record, index) => (
            <Box
              key={index}
              className={
                classes[`${record.userRole === 'User' ? 'user-message' : 'vendor-message'}`]
              }
            >
              <Box sx={{ color: 'var(--clr-primary)' }}>
                {record.firstName}
                <Typography variant='caption' component='span' sx={{ pl: '2rem' }}>
                  ({record.dateAddedFormatted})
                </Typography>
              </Box>
              <pre>{record.message}</pre>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default OrderMessagesList;

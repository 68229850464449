import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { apiEnvironment, apiVersion, apiLocation } from '../constants/global';

// import { logUserOut, refreshTokens } from '../components/ApiFunctions';

// There are in set in 2 places.
// I need to clean that up.
const displayTaxInfo = false;
const defaultMenuItemImage = 'default_menu_item.jpg';
const displayAddNewLocationButton = false;
const defaultOrderLeadTime = 12; // 24; // - WAS - 72;

const rolesAbleToEditDeliveryHours = ['Admin'];
const rolesAbleToEditDeliveryFees = ['Admin'];

const defaultSubtotalCartItemsLabel = 'Subtotal (Cart Items)';
// const defaultServingSizeLabel = 'Option'; // 'Serving Size

let logoutTimer;
// const logoutTimerValue = 1800000; // 30 minutes
// const logoutTimerValue = 3600000; // 60 minutes or 1 hour if you prefer
// const logoutTimerValue = 14400000; // 4 hours
// const logoutTimerValue = 28800000; // 8 hours
const logoutTimerValue = 300000; // 5 minutes
// const logoutTimerValue = 10000;

let logoutUrl = '';

let baseApiUrl =
  'http://localhost:8888/client-sites/elite-eats/elite-eats-php-rest-api/' + apiVersion + '/';

if (apiEnvironment === 'production') {
  if (apiLocation === 'SD') {
    //
    // Using the API on Sound Decisions.
    baseApiUrl = 'https://sound-decisions.ca/elite-eats-live-api/' + apiVersion + '/';
  } else {
    //
    // Using the API on Hostinger
    baseApiUrl = 'https://www.eliteeatsinc.com/elite-eats-php-rest-api/' + apiVersion + '/';
  }
} else if (apiEnvironment === 'staging') {
  if (apiLocation === 'SD') {
    //
    // Using the API on Sound Decisions.
    baseApiUrl = 'https://sound-decisions.ca/elite-eats-staging-api/' + apiVersion + '/';
  } else {
    //
    // Using the API on Hostinger
    baseApiUrl = 'https://www.eliteeatsinc.com/staging/elite-eats-php-rest-api/' + apiVersion + '/';
  }
} else if (apiEnvironment === 'development') {
  baseApiUrl = 'https://sound-decisions.ca/elite-eats-php-rest-api/' + apiVersion + '/';
  //
  // Always uses the api at Sound Decisions.
} else if (apiEnvironment === 'sd-staging') {
  baseApiUrl = 'https://sound-decisions.ca/elite-eats-staging-api/' + apiVersion + '/';
  //
  // The LIVE API and UI is located at Sound Decisions.
} else if (apiEnvironment === 'sd-live') {
  baseApiUrl =
    'https://sound-decisions.ca/elite-eats-live/elite-eats-php-rest-api/' + apiVersion + '/';
  //
  // The LIVE API is located at Sound Decisions.
} else if (apiEnvironment === 'live-api-sd') {
  baseApiUrl = 'https://sound-decisions.ca/elite-eats-live-api/' + apiVersion + '/';
}

const baseApiUploadedImagesPath = baseApiUrl + 'react-uploads/';

const signupToken = 'EliteEatsSignup';

const globalDietaryOptions = [
  'Dairy Free',
  'Gluten Free',
  'Low Carb',
  'Spicy',
  'Vegan',
  'Vegetarian',
];

const globalDietaryOptionsArray = [
  { id: 'dairyFree', title: 'Dairy Free' },
  { id: 'glutenFree', title: 'Gluten Free' },
  { id: 'lowCarb', title: 'Low Carb' },
  { id: 'spicy', title: 'Spicy' },
  { id: 'vegan', title: 'Vegan' },
  { id: 'vegetarian', title: 'Vegetarian' },
];

const notificationPreferencesArray = [
  { value: 'Email', label: 'Email' },
  // { value: 'Text', label: 'Text' },
  // { value: 'Both', label: 'Both' },
  { value: 'None', label: 'None' },
];

const AuthContext = React.createContext({
  baseApiUrl: baseApiUrl,
  baseApiUploadedImagesPath: baseApiUploadedImagesPath,
  token: '',
  isLoggedIn: false,
  userDetails: {},
  orgDetails: {},
  restaurantDetails: {},
  login: (token) => {},
  logout: () => {},
  logoutUnAuth: () => {},
  setUserDetails: (userDetails) => {},
  setOrgDetails: (orgDetails) => {},
  setRestaurantDetails: (restaurantDetails) => {},
  refreshTokenValues: (tokenValues) => {},
  userUuid: '',
  userName: '',
  userRole: '',
  userStatus: '',
  orgUuid: '',
  orgName: '',
  orgType: '',
  orgStatus: '',
  updateUserValues: () => {},
  updateOrgValues: () => {},
  updateSelectedDeliveryLocationHandler: () => {},
  updateSelectedRestaurantHandler: () => {},
  selectedDeliveryLocationUuid: '',
  selectedDeliveryLocationName: '',
  selectedDeliveryLocationCity: '',
  selectedDeliveryLocationState: '',
  selectedDeliveryLocationStatus: '',
  selectedRestaurantUuid: '',
  selectedRestaurantName: '',
  selectedRestaurantCity: '',
  selectedRestaurantState: '',
  selectedRestaurantStatus: '',
  dashboardUrl: '',
  checkIfShouldLogoutHandler: () => {},
  globalMenuItemCategories: [],
  setGlobalMenuItemCategories: () => {},
  signupToken: signupToken,
  globalDietaryOptions: globalDietaryOptions,
  globalDietaryOptionsArray: globalDietaryOptionsArray,
  notificationPreferencesArray: notificationPreferencesArray,
  defaultSubtotalCartItemsLabel: defaultSubtotalCartItemsLabel,
  // defaultServingSizeLabel: defaultServingSizeLabel,
  displayTaxInfo: displayTaxInfo,
  defaultMenuItemImage: defaultMenuItemImage,
  displayAddNewLocationButton: displayAddNewLocationButton,
  defaultOrderLeadTime: defaultOrderLeadTime,
  rolesAbleToEditDeliveryHours: rolesAbleToEditDeliveryHours,
  rolesAbleToEditDeliveryFees: rolesAbleToEditDeliveryFees,
  multipleRestaurants: false,
  versionUpdatedRefreshNeeded: false,
  setVersionUpdatedRefreshNeeded: () => {},
  setOrdersFilter: () => {},
  ordersStatusFilter: '',
});

const calculateRemainingExpiryTime = (expiryTime) => {
  const currentTime = new Date().getTime();
  const remainingTime = expiryTime - currentTime;

  return remainingTime;
};

const retrieveStoredData = () => {
  // console.log(' ******* retrieveStoredData ********** ');
  // console.log(' **************************************** ');

  const storedUserUuid = localStorage.getItem('eeuuid');
  const storedUserName = localStorage.getItem('eeun');
  const storedUserRole = localStorage.getItem('eeur');
  const storedUserStatus = localStorage.getItem('eeus');
  const storedToken = localStorage.getItem('eeat');
  const storedExpiryTime = localStorage.getItem('eeatet');
  const storedSessionId = localStorage.getItem('eesid');

  // This is for the logged in user.  Could be Team, Restaurant or Admin (Organization).
  const storedOrgUuid = localStorage.getItem('eeouuid');
  const storedOrgName = localStorage.getItem('eeon');
  const storedOrgType = localStorage.getItem('eeot');
  const storedOrgStatus = localStorage.getItem('eeoss');

  // Selected Delivery Location.
  const storedSelectedDeliveryLocationUuid = localStorage.getItem('eesdluuid');
  const storedSelectedDeliveryLocationName = localStorage.getItem('eesdln');
  const storedSelectedDeliveryLocationCity = localStorage.getItem('eesdlc');
  const storedSelectedDeliveryLocationState = localStorage.getItem('eesdls');
  const storedSelectedDeliveryLocationStatus = localStorage.getItem('eesdlss');

  // Selected Restaurant.
  const storedSelectedRestaurantUuid = localStorage.getItem('eesruuid');
  const storedSelectedRestaurantName = localStorage.getItem('eesrn');
  const storedSelectedRestaurantCity = localStorage.getItem('eesrc');
  const storedSelectedRestaurantState = localStorage.getItem('eesrs');
  const storedSelectedRestaurantStatus = localStorage.getItem('eesrss');

  const storedMultipleRestaurants = localStorage.getItem('eemr');

  // Determine the storedDashboardUrl based on the storedUserRole.
  let storedDashboardUrl = '/team-dashboard/';
  if (storedUserRole === 'Vendor') {
    // storedDashboardUrl = '/restaurant-dashboard/';
    storedDashboardUrl = '/vendor-dashboard/';
  } else if (storedUserRole === 'Admin') {
    storedDashboardUrl = '/admin-dashboard/';
  }
  // const storedDashboardUrl = localStorage.getItem('eedurl');

  // User Details.
  const storedUserDetails = localStorage.getItem('ee-user-details');
  const storedOrgDetails = localStorage.getItem('ee-org-details');
  // const storedRestaurantDetails = localStorage.getItem('ee-restaurant-details');

  // Org Details

  // console.log(' -- retrieveStoredData -- ');
  // console.log(' -- storedUserName = ' + storedUserName);
  // console.log(' -- storedUserRole = ' + storedUserRole);
  // console.log(' -- storedToken = ' + storedToken);
  // console.log(' -- storedExpiryTime = ' + storedExpiryTime);
  // console.log(' -- storedSessionId = ' + storedSessionId);

  // Convert string to date before sending to calculateRemainingExpiryTime.
  // This functionality doesn't work correcly without this.
  const dateExpiryTime = new Date(storedExpiryTime);

  const remainingTime = calculateRemainingExpiryTime(dateExpiryTime);

  // If the remaining time is less than a logical amount of time then there is no sense logging in.
  // if (remainingTime <= 60000) {
  // Testing
  if (remainingTime <= 60000) {
    // console.log(' -- clearing localStorage -- ');
    localStorage.clear();
    // localStorage.removeItem('eeat');
    // localStorage.removeItem('eeatet');
    return null;
  }

  return {
    userUuid: storedUserUuid,
    userName: storedUserName,
    userRole: storedUserRole,
    userStatus: storedUserStatus,
    token: storedToken,
    remainingTime: remainingTime,
    sessionId: storedSessionId,
    orgUuid: storedOrgUuid,
    orgName: storedOrgName,
    orgType: storedOrgType,
    orgStatus: storedOrgStatus,
    selectedDeliveryLocationUuid: storedSelectedDeliveryLocationUuid,
    selectedDeliveryLocationName: storedSelectedDeliveryLocationName,
    selectedDeliveryLocationCity: storedSelectedDeliveryLocationCity,
    selectedDeliveryLocationState: storedSelectedDeliveryLocationState,
    selectedDeliveryLocationStatus: storedSelectedDeliveryLocationStatus,
    selectedRestaurantUuid: storedSelectedRestaurantUuid,
    selectedRestaurantName: storedSelectedRestaurantName,
    selectedRestaurantCity: storedSelectedRestaurantCity,
    selectedRestaurantState: storedSelectedRestaurantState,
    selectedRestaurantStatus: storedSelectedRestaurantStatus,
    dashboardUrl: storedDashboardUrl,
    userDetails: JSON.parse(storedUserDetails),
    orgDetails: JSON.parse(storedOrgDetails),
    multipleRestaurants: storedMultipleRestaurants,
    // restaurantDetails: storedRestaurantDetails ? JSON.parse(storedRestaurantDetails) : '',
  };
};

// Testing
// const testingAddingFunctionToLogoutHandler = () => {
//   console.log(' -- testingAddingFunctionToLogoutHandler -- ');

//   logUserOut(baseApiUrl, localStorage.getItem('eeat'), localStorage.getItem('eesid'));

//   localStorage.clear();

// if (logoutTimer) {
//   clearTimeout(logoutTimer);
// }
// };

// const testingRefreshTokens = () => {
//   console.log(' -- testingRefreshTokens -- ');

//   refreshTokens(
//     baseApiUrl,
//     localStorage.getItem('eeat'),
//     localStorage.getItem('eesid'),
//     localStorage.getItem('eert')
//   );
// };

export const AuthContextProvider = (props) => {
  const history = useHistory();

  const storedData = retrieveStoredData();

  // console.log(' ------ AuthContextProvider ------- ');

  let initialToken;
  let initialUserUid;
  let initialUserName;
  let initialUserRole;
  let initialUserStatus;
  let initialOrgUuid;
  let initialOrgName;
  let initialOrgType;
  let initialOrgStatus;
  let initialSelectedDeliveryLocationUuid;
  let initialSelectedDeliveryLocationName;
  let initialSelectedDeliveryLocationCity;
  let initialSelectedDeliveryLocationState;
  let initialSelectedDeliveryLocationStatus;
  let initialSelectedRestaurantUuid;
  let initialSelectedRestaurantName;
  let initialSelectedRestaurantCity;
  let initialSelectedRestaurantState;
  let initialSelectedRestaurantStatus;
  let initialDashboardUrl;
  let initialUserDetails;
  let initialOrgDetails;
  let initialRestaurantDetails;
  let initialMultipleRestaurants;

  if (storedData) {
    initialToken = storedData.token;
    initialUserUid = storedData.userUuid;
    initialUserName = storedData.userName;
    initialUserRole = storedData.userRole;
    initialUserStatus = storedData.userStatus;
    initialOrgUuid = storedData.orgUuid;
    initialOrgName = storedData.orgName;
    initialOrgType = storedData.orgType;
    initialOrgStatus = storedData.orgStatus;
    initialSelectedDeliveryLocationUuid = storedData.selectedDeliveryLocationUuid;
    initialSelectedDeliveryLocationName = storedData.selectedDeliveryLocationName;
    initialSelectedDeliveryLocationCity = storedData.selectedDeliveryLocationCity;
    initialSelectedDeliveryLocationState = storedData.selectedDeliveryLocationState;
    initialSelectedDeliveryLocationStatus = storedData.selectedDeliveryLocationStatus;
    initialSelectedRestaurantUuid = storedData.selectedRestaurantUuid;
    initialSelectedRestaurantName = storedData.selectedRestaurantName;
    initialSelectedRestaurantCity = storedData.selectedRestaurantCity;
    initialSelectedRestaurantState = storedData.selectedRestaurantState;
    initialSelectedRestaurantStatus = storedData.selectedRestaurantStatus;
    initialDashboardUrl = storedData.dashboardUrl;
    initialUserDetails = storedData.userDetails;
    initialOrgDetails = storedData.orgDetails;
    initialRestaurantDetails = storedData.restaurantDetails;
    initialMultipleRestaurants = storedData.multipleRestaurants;
  }

  const [token, setToken] = useState(initialToken);
  const [userUuid, setUserUuid] = useState(initialUserUid);
  const [userName, setUserName] = useState(initialUserName);
  const [userRole, setUserRole] = useState(initialUserRole);
  const [userStatus, setUserStatus] = useState(initialUserStatus);
  const [orgUuid, setOrgUuid] = useState(initialOrgUuid);
  const [orgName, setOrgName] = useState(initialOrgName);
  const [orgType, setOrgType] = useState(initialOrgType);
  const [orgStatus, setOrgStatus] = useState(initialOrgStatus);
  const [selectedDeliveryLocationUuid, setSelectedDeliveryLocationUuid] = useState(
    initialSelectedDeliveryLocationUuid
  );
  const [selectedDeliveryLocationName, setSelectedDeliveryLocationName] = useState(
    initialSelectedDeliveryLocationName
  );
  const [selectedDeliveryLocationCity, setSelectedDeliveryLocationCity] = useState(
    initialSelectedDeliveryLocationCity
  );
  const [selectedDeliveryLocationState, setSelectedDeliveryLocationState] = useState(
    initialSelectedDeliveryLocationState
  );
  const [selectedDeliveryLocationStatus, setSelectedDeliveryLocationStatus] = useState(
    initialSelectedDeliveryLocationStatus
  );
  const [selectedRestaurantUuid, setSelectedRestaurantUuid] = useState(
    initialSelectedRestaurantUuid
  );
  const [selectedRestaurantName, setSelectedRestaurantName] = useState(
    initialSelectedRestaurantName
  );
  const [selectedRestaurantCity, setSelectedRestaurantCity] = useState(
    initialSelectedRestaurantCity
  );
  const [selectedRestaurantState, setSelectedRestaurantState] = useState(
    initialSelectedRestaurantState
  );
  const [selectedRestaurantStatus, setSelectedRestaurantStatus] = useState(
    initialSelectedRestaurantStatus
  );
  const [dashboardUrl, setDashboardUrl] = useState(initialDashboardUrl);

  const [globalMenuItemCategories, setGlobalMenuItemCategories] = useState([]);

  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [orgDetails, setOrgDetails] = useState(initialOrgDetails);
  const [restaurantDetails, setRestaurantDetails] = useState(initialRestaurantDetails);
  const [multipleRestaurants, setMultipleRestaurants] = useState(initialMultipleRestaurants);
  const [versionUpdatedRefreshNeeded, setVersionUpdatedRefreshNeeded] = useState(false);

  const [ordersStatusFilter, setOrdersStatusFilter] = useState('');

  const userIsLoggedIn = !!token;

  const setUserDetailsHandler = (userDetails) => {
    // console.log(' ** setUserDetailsHandler ** ');
    // console.log('userDetails');
    // console.table(userDetails);

    setUserDetails(userDetails);

    localStorage.setItem('ee-user-details', JSON.stringify(userDetails));
  };

  const setOrgDetailsHandler = (orgDetails) => {
    // console.log(' ** setOrgDetailsHandler ** ');
    // console.log('orgDetails');
    // console.table(orgDetails);

    setOrgDetails(orgDetails);

    localStorage.setItem('ee-org-details', JSON.stringify(orgDetails));
  };

  const setMultipleRestaurantsHandler = (value) => {
    setMultipleRestaurants(value);

    localStorage.setItem('eemr', value);
  };

  const setVersionUpdatedRefreshNeededHandler = (value) => {
    setVersionUpdatedRefreshNeeded(value);
  };

  const setOrdersFilterHandler = (status) => {
    setOrdersStatusFilter(status);
  };

  const setRestaurantDetailsHandler = (restaurnatDetails) => {
    // console.log(' ** setRestaurantDetailsHandler ** ');
    // console.log('restaurnatDetails');
    // console.table(restaurnatDetails);

    setRestaurantDetails(restaurnatDetails);

    localStorage.setItem('ee-restaurant-details', JSON.stringify(restaurnatDetails));
  };

  const updateUserHandler = (userName) => {
    setUserName(userName);

    localStorage.setItem('eeun', userName);
  };

  const updateOrgHandler = (orgUuid, orgName, orgType, orgStatus) => {
    setOrgUuid(orgUuid);
    setOrgName(orgName);
    setOrgType(orgType);
    setOrgType(orgStatus);

    localStorage.setItem('eeouuid', orgUuid);
    localStorage.setItem('eeon', orgName);
    localStorage.setItem('eeot', orgType);
    localStorage.setItem('eeoss', orgStatus);
  };

  const updateSelectedDeliveryLocationHandler = (uuid, name, city, state, status) => {
    setSelectedDeliveryLocationUuid(uuid);
    setSelectedDeliveryLocationName(name);
    setSelectedDeliveryLocationCity(city);
    setSelectedDeliveryLocationState(state);
    setSelectedDeliveryLocationStatus(status);

    localStorage.setItem('eesdluuid', uuid);
    localStorage.setItem('eesdln', name);
    localStorage.setItem('eesdlc', city);
    localStorage.setItem('eesdls', state);
    localStorage.setItem('eesdlss', status);
  };

  const updateSelectedRestaurantHandler = (uuid, name, city, state, status) => {
    setSelectedRestaurantUuid(uuid);
    setSelectedRestaurantName(name);
    setSelectedRestaurantCity(city);
    setSelectedRestaurantState(state);
    setSelectedRestaurantStatus(status);

    localStorage.setItem('eesruuid', uuid);
    localStorage.setItem('eesrn', name);
    localStorage.setItem('eesrc', city);
    localStorage.setItem('eesrs', state);
    localStorage.setItem('eesrss', status);
  };

  // Must be above the loginHandler so we can call the logoutHandler in loginHandler.
  const logoutHandler = useCallback(() => {
    // Test Code.
    // console.log(' ** logoutHandler - logoutTimerValue = ' + logoutTimerValue);

    setToken(null);
    setUserUuid('');
    setUserName('');
    setUserRole('');
    setUserStatus('');

    setOrgUuid('');
    setOrgName('');
    setOrgType('');
    setOrgStatus('');
    setSelectedDeliveryLocationUuid('');
    setSelectedDeliveryLocationName('');
    setSelectedDeliveryLocationCity('');
    setSelectedDeliveryLocationState('');
    setSelectedDeliveryLocationStatus('');
    setSelectedRestaurantUuid('');
    setSelectedRestaurantName('');
    setSelectedRestaurantCity('');
    setSelectedRestaurantState('');
    setSelectedRestaurantStatus('');
    setDashboardUrl('');
    setMultipleRestaurants(false);

    setGlobalMenuItemCategories([]);

    setUserDetails({});
    setOrgDetails({});
    setRestaurantDetails({});

    // console.log(' -- logoutHandler -- ');

    // Seems to work.
    // testingAddingFunctionToLogoutHandler();
    // testingRefreshTokens();

    // Clears all localStorage for this site.
    localStorage.clear();
    // localStorage.removeItem('eeat');
    // localStorage.removeItem('eeatet');
    // localStorage.removeItem('eesid');

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    if (logoutUrl) {
      window.location.href = logoutUrl;
    } else {
      history.push('/login');
    }
  }, [history]);

  const loginHandler = (
    userUuid,
    userName,
    userStatus,
    userRole,
    sessionId,
    token,
    expiryTime,
    refreshToken,
    refreshTokenExpiryTime,
    orgUuid,
    orgName,
    orgType,
    orgStatus,
    locationUuid,
    locationName,
    locationCity,
    locationState,
    locationStatus,
    userDetails,
    orgDetails,
    multipleRestaurants
  ) => {
    setToken(token);
    setUserUuid(userUuid);
    setUserName(userName);
    setUserStatus(userStatus);
    setUserRole(userRole);
    setOrgUuid(orgUuid);
    setOrgName(orgName);
    setOrgType(orgType);
    setOrgStatus(orgStatus);

    // setMultipleRestaurants(multipleRestaurants);
    // localStorage.setItem('eemr', multipleRestaurants);
    setMultipleRestaurantsHandler(multipleRestaurants);

    if (userRole === 'User') {
      setSelectedDeliveryLocationUuid(locationUuid);
      setSelectedDeliveryLocationName(locationName);
      setSelectedDeliveryLocationCity(locationCity);
      setSelectedDeliveryLocationState(locationState);
      setSelectedDeliveryLocationStatus(locationStatus);

      localStorage.setItem('eesdluuid', locationUuid);
      localStorage.setItem('eesdln', locationName);
      localStorage.setItem('eesdlc', locationCity);
      localStorage.setItem('eesdls', locationState);
      localStorage.setItem('eesdlss', locationStatus);
    }

    if (userRole === 'Vendor') {
      setSelectedRestaurantUuid(locationUuid);
      setSelectedRestaurantName(locationName);
      setSelectedRestaurantCity(locationCity);
      setSelectedRestaurantState(locationState);
      setSelectedRestaurantStatus(locationStatus);

      localStorage.setItem('eesruuid', locationUuid);
      localStorage.setItem('eesrn', locationName);
      localStorage.setItem('eesrc', locationCity);
      localStorage.setItem('eesrs', locationState);
      localStorage.setItem('eesrss', locationStatus);
    }

    localStorage.setItem('eeuuid', userUuid);
    localStorage.setItem('eeun', userName);
    localStorage.setItem('eeur', userRole);
    localStorage.setItem('eeus', userStatus);
    localStorage.setItem('eesid', sessionId);
    localStorage.setItem('eeat', token);
    localStorage.setItem('eeatet', expiryTime);
    localStorage.setItem('eert', refreshToken);
    localStorage.setItem('eertet', refreshTokenExpiryTime);
    localStorage.setItem('eeouuid', orgUuid);
    localStorage.setItem('eeon', orgName);
    localStorage.setItem('eeot', orgType);
    localStorage.setItem('eeoss', orgStatus);

    const remainingTime = calculateRemainingExpiryTime(expiryTime);

    // Save the remaining time we get at login so we can use it later.
    localStorage.setItem('eereti', remainingTime);

    // setUserDetails(userDetails);
    // setOrgDetails(orgDetails);
    setUserDetailsHandler(userDetails);
    setOrgDetailsHandler(orgDetails);
    setRestaurantDetailsHandler(restaurantDetails);

    setOrdersFilterHandler('');

    // console.log('orgDetails');
    // console.log(orgDetails);

    // console.log(
    //   ' -- loginHandler  - expiryTime = ' +
    //     expiryTime +
    //     ' - remainingTime = ' +
    //     remainingTime +
    //     ' - userRole = ' +
    //     userRole +
    //     ' -- '
    // );

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    // Todo:  Look into why this doesn't seem to work very well.
    // This code shouldn't be needed anymore - I don't think so anyway.
    // console.log('Setting logoutTime - remainingTime = ' + remainingTime + ' ----- TESTING *******');
    // logoutTimer = setTimeout(logoutHandler, +remainingTime);
    // Testing
    // console.log(' *** ABOUT TO CALL setTimeout - logoutHandler, 20000 ***');
    // logoutTimer = setTimeout(logoutHandler, 20000);

    let dashboardPath;
    // let loadPage = '/';

    switch (userRole) {
      case 'Admin':
        dashboardPath = '/admin-dashboard/';
        // loadPage = dashboardPath + 'dashboard';
        break;
      case 'User':
        dashboardPath = '/team-dashboard/';
        // if (orgName === '' || orgName === undefined) {
        //   loadPage = dashboardPath + 'team';
        // } else {
        //   // loadPage = dashboardPath + 'dashboard';
        //   loadPage = dashboardPath + 'new-order';
        // }
        break;
      case 'Vendor':
        // dashboardPath = '/restaurant-dashboard/';
        dashboardPath = '/vendor-dashboard/';
        // if (orgName === '' || orgName === undefined) {
        //   loadPage = dashboardPath + 'restaurant';
        //   // } else if (orgDetails.city === '' || orgDetails.city === undefined) {
        //   //   loadPage = dashboardPath + 'restaurant';
        // } else {
        //   // loadPage = dashboardPath + 'dashboard';
        //   loadPage = dashboardPath + 'orders';
        // }
        break;
      default:
        dashboardPath = '';
      // loadPage = '/';
    }

    // localStorage.setItem('eedurl', dashboardPath);
    setDashboardUrl(dashboardPath);

    // console.log(' -- loginHandler -- ');
    // console.log('userName = ' + userName);
    // console.log('userRole = ' + userRole);
    // console.log('loadPage = ' + loadPage);

    // history.push(loadPage);
  };

  const refreshTokensHandler = (tokenValues) => {
    console.log('** auth-context - refreshTokensHandler **');
    // console.log('tokenValues');
    // console.table(tokenValues);
    // localStorage.setItem('eesid', sessionId);
    // localStorage.setItem('eeat', token);
    // localStorage.setItem('eeatet', expiryTime);
    // localStorage.setItem('eert', refreshToken);
    // localStorage.setItem('eertet', refreshTokenExpiryTime);
  };

  const checkIfShouldLogoutHandler = () => {
    console.log(' -- checkIfShouldLogoutHandler -- ');
    // if (localStorage.getItem('eeon') === null) {
    //   logoutHandler();
    // }
  };

  const resetLogoutTimerHandler = () => {
    console.log('*** resetLogoutTimer *** setTimeout - ' + logoutTimerValue + ' ***');
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }

    // Commenting this out so there is no logoutTimer.
    // logoutTimer = setTimeout(logoutHandler, logoutTimerValue);
  };

  // TODO:  clean this up.
  // If we automatically login a user we need to set a new Timer if there is time left.
  // useEffect(() => {
  //   if (storedData) {
  //     // console.log(' -- useEffect - storedData.remainingTime = ' + storedData.remainingTime);
  //     // logoutTimer = setTimeout(logoutHandler, storedData.remainingTime);
  //     // Testing
  //     console.log(' *** storedData - setTimeout ' + logoutTimerValue + ' ***');

  //     // Commenting this out so there is no logoutTimer.
  //     // logoutTimer = setTimeout(logoutHandler, logoutTimerValue);
  //   }
  // }, [storedData, logoutHandler]);

  const contextValue = {
    baseApiUrl: baseApiUrl,
    baseApiUploadedImagesPath: baseApiUploadedImagesPath,
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    userUuid: userUuid,
    userName: userName,
    userRole: userRole,
    userStatus: userStatus,
    orgUuid: orgUuid,
    orgName: orgName,
    orgType: orgType,
    orgStatus: orgStatus,
    userDetails: userDetails,
    orgDetails: orgDetails,
    setUserDetails: setUserDetailsHandler,
    setOrgDetails: setOrgDetailsHandler,
    setRestaurantDetails: setRestaurantDetailsHandler,
    refreshTokenValues: refreshTokensHandler,
    updateUserValues: updateUserHandler,
    updateOrgValues: updateOrgHandler,
    updateSelectedDeliveryLocationValues: updateSelectedDeliveryLocationHandler,
    updateSelectedRestaurantValues: updateSelectedRestaurantHandler,
    selectedDeliveryLocationUuid: selectedDeliveryLocationUuid,
    selectedDeliveryLocationName: selectedDeliveryLocationName,
    selectedDeliveryLocationCity: selectedDeliveryLocationCity,
    selectedDeliveryLocationState: selectedDeliveryLocationState,
    selectedDeliveryLocationStatus: selectedDeliveryLocationStatus,
    selectedRestaurantUuid: selectedRestaurantUuid,
    selectedRestaurantName: selectedRestaurantName,
    selectedRestaurantCity: selectedRestaurantCity,
    selectedRestaurantState: selectedRestaurantState,
    selectedRestaurantStatus: selectedRestaurantStatus,
    dashboardUrl: dashboardUrl,
    checkIfShouldLogout: checkIfShouldLogoutHandler,
    resetLogoutTimer: resetLogoutTimerHandler,
    globalMenuItemCategories: globalMenuItemCategories,
    signupToken: signupToken,
    globalDietaryOptions: globalDietaryOptions,
    globalDietaryOptionsArray: globalDietaryOptionsArray,
    notificationPreferencesArray: notificationPreferencesArray,
    defaultSubtotalCartItemsLabel: defaultSubtotalCartItemsLabel,
    // defaultServingSizeLabel: defaultServingSizeLabel,
    displayTaxInfo: displayTaxInfo,
    defaultMenuItemImage: defaultMenuItemImage,
    displayAddNewLocationButton: displayAddNewLocationButton,
    defaultOrderLeadTime: defaultOrderLeadTime,
    rolesAbleToEditDeliveryHours: rolesAbleToEditDeliveryHours,
    rolesAbleToEditDeliveryFees: rolesAbleToEditDeliveryFees,
    multipleRestaurants: multipleRestaurants,
    setMultipleRestaurants: setMultipleRestaurantsHandler,
    versionUpdatedRefreshNeeded: versionUpdatedRefreshNeeded,
    setVersionUpdatedRefreshNeeded: setVersionUpdatedRefreshNeededHandler,
    setOrdersFilter: setOrdersFilterHandler,
    ordersStatusFilter: ordersStatusFilter,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Collapse, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import { useFormatDateAndTime } from '../Hooks/useFormatDateAndTime';
import UserDetails from '../User/UserDetails';
import UserForm from '../User/UserForm';
import ChangePasswordForm from '../MUI/User/ChangePasswordForm';

const TeamContact = (props) => {
  const { teamUuid, userUuid, onShowList, showFormOrDetails, pageTitle = 'Contact' } = props;

  // console.log('######## TeamContact ########');
  // console.log('teamUuid = ' + teamUuid);
  // console.table(props);

  const authCtx = useContext(AuthContext);

  let showChangePasswordLink = false;
  if (userUuid === authCtx.userDetails.userUuid) {
    showChangePasswordLink = true;
  }

  // console.log('showChangePasswordLink = ' + showChangePasswordLink);

  const [showForm, setShowForm] = useState(showFormOrDetails === 'Form' ? true : false);
  const [showDetails, setShowDetails] = useState(showFormOrDetails === 'Details' ? true : false);
  const [userDetails, setUserDetails] = useState('');
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { FormatDateAndTimeForDisplay } = useFormatDateAndTime();

  const showChangeYourPassword = showDetails;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(authCtx.baseApiUrl + 'users/' + userUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log(responseData);

        const user = responseData.data.users[0];

        const returnedUserDetails = {
          userUuid: user.userUuid,
          firstName: user.firstName,
          lastName: user.lastName,
          position: user.position,
          phone: user.phone,
          email: user.email,
          notificationPreference: user.notificationPreference,
          status: user.status,
          roleType: user.roleType,
          dateLastLogin: user.dateLastLogin,
          dateAdded: user.dateAdded,
          orgName: user.orgName ? user.orgName : '',
        };
        // console.log(' -- returnedUserDetails - HERE -- ');
        // console.log(returnedUserDetails);

        if (returnedUserDetails.dateLastLogin !== '') {
          returnedUserDetails.dateLastLogin = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateLastLogin
          );
        }
        if (returnedUserDetails.dateAdded !== '') {
          returnedUserDetails.dateAdded = FormatDateAndTimeForDisplay(
            returnedUserDetails.dateAdded
          );
        }

        setUserDetails(returnedUserDetails);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };

    if (userUuid !== '' && userUuid !== undefined) {
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }
  }, [authCtx.baseApiUrl, authCtx.token, userUuid, FormatDateAndTimeForDisplay]);

  const toggleShowPasswordForm = () => {
    setShowChangePasswordForm((current) => !current);
  };

  const showFormHandler = () => {
    setShowForm(true);
    setShowDetails(false);
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && showForm ? (
        <UserForm
          onCancel={onShowList}
          userDetails={userDetails}
          orgType='Team'
          orgUuid={teamUuid}
        />
      ) : null}

      {!isLoading && showDetails ? (
        <>
          <Box>
            <Box className='title-and-button-holder'>
              <Typography variant='h1' component='h1'>
                {pageTitle}
              </Typography>

              <Box className='button-holder-no-pb'>
                <Button variant='contained' size='small' color='accent' onClick={showFormHandler}>
                  Edit
                </Button>
                <Button variant='contained' size='small' color='accent' onClick={onShowList}>
                  Contacts
                </Button>
              </Box>
            </Box>

            <UserDetails userDetails={userDetails} />
          </Box>

          {showChangeYourPassword && showChangePasswordLink ? (
            <>
              <Box align='center' className='p-20'>
                <Button variant='text' onClick={toggleShowPasswordForm} className='text-button'>
                  Change Your Password
                </Button>
              </Box>
              <Collapse in={showChangePasswordForm}>
                <ChangePasswordForm />
              </Collapse>
            </>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default TeamContact;

import React, { useState } from 'react';

import UserList from '../User/UserList';
import TeamContact from './TeamContact';

const scrollToTopOfPage = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

const TeamContacts = ({ contacts, teamUuid }) => {
  // console.log('######## TeamContacts ########');
  // console.log('teamUuid', teamUuid);
  // console.log('contacts');
  // console.log(contacts);
  const [showFormOrDetails, setShowFormOrDetails] = useState('Details');
  const [showContact, setShowContact] = useState(false);
  const [showList, setShowList] = useState(true);
  const [userUuid, setUserUuid] = useState('');
  const [pageTitle, setPageTitle] = useState('Contacts');

  const showContactHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowContact(true);
    setShowFormOrDetails('Details');
    setShowList(false);
    setPageTitle('Contact Details');
  };

  const addUserHandler = () => {
    // console.log('##### addUserHandler #####');
    // console.log('teamUuid = ' + teamUuid);
    setUserUuid('');
    setShowContact(true);
    setShowFormOrDetails('Form');
    setShowList(false);
    setPageTitle('Create New Contact');
  };

  const editUserHandler = (userUuid) => {
    setUserUuid(userUuid);
    setShowContact(true);
    setShowFormOrDetails('Form');
    setShowList(false);
    setPageTitle('Edit Contact Details');
  };

  const showListHandler = () => {
    setShowContact(false);
    setShowFormOrDetails('Details');
    setShowList(true);
    setPageTitle('Contacts');
    scrollToTopOfPage();

    // console.log(' ** showListHandler ** ');
  };

  return (
    <div className='pt-40'>
      {showList ? (
        <UserList
          onAddNew={addUserHandler}
          onEdit={editUserHandler}
          onShowContact={showContactHandler}
          pageTitle={pageTitle}
          orgType='Team'
          orgUuid={teamUuid}
          // userRecords={contacts}
        />
      ) : null}

      {showContact ? (
        <TeamContact
          teamUuid={teamUuid}
          userUuid={userUuid}
          onShowList={showListHandler}
          showFormOrDetails={showFormOrDetails}
          pageTitle={pageTitle}
        />
      ) : null}
    </div>
  );
};

export default TeamContacts;

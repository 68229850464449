import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import TeamDetails from './TeamDetails';
import TeamForm from './TeamForm';
import TeamContacts from '../TeamContacts/TeamContacts';
import WorkflowMessage from '../UI/WorkflowMessage';
import Popup from '../MUI/UI/Popup';

const Team = ({ teamUuid, mode = 'details' }) => {
  // console.log('######## Team ########');
  // console.log('teamUuid', teamUuid);
  const authCtx = useContext(AuthContext);
  const dashboardUrl = authCtx.dashboardUrl;
  const userRole = authCtx.userRole;

  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath + teamUuid + '/';

  const history = useHistory();

  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showForm, setShowForm] = useState(mode === 'edit' ? true : false);
  const [showDetails, setShowDetails] = useState(mode === 'details' ? true : false);
  const [leagues, setLeagues] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);

  const pageTitle = mode === 'details' ? 'Team Profile' : 'Edit Team';
  const editButtonText = userRole === 'User' ? 'Edit Team Profile' : 'Edit Team';
  const errorMessage = 'Failed to retrieve Data from the API.';

  useEffect(() => {
    const fetchTeamData = async () => {
      // console.log(' *** useEffect - fetchTeamData *** ');
      try {
        setIsLoading(true);

        const response = await fetch(authCtx.baseApiUrl + 'teams/' + teamUuid, {
          headers: {
            Authorization: authCtx.token,
          },
        });

        const responseData = await response.json();

        if (response.ok) {
          // console.log('### responseData ###');
          // console.log(responseData);

          const returnedData = {
            teamUuid: responseData.data.team.teamUuid,
            name: responseData.data.team.name,
            description: responseData.data.team.description,
            logoImage: responseData.data.team.logoImage,
            teamInstructions: responseData.data.team.teamInstructions,
            league: responseData.data.team.league,
            status: responseData.data.team.status,
            contacts: responseData.data.team.contacts ? responseData.data.team.contacts : [],
          };

          // console.log('returnedData');
          // console.log(returnedData);

          setTeamDetails(returnedData);

          const transformedLeagues = responseData.data.leagues.map((league) => {
            return {
              name: league,
            };
          });

          // console.log('transformedLeagues');
          // console.log(transformedLeagues);

          setLeagues(transformedLeagues);
        } else {
          // If a 401 is returned then there is an issue with the session (most likely).
          if (responseData.statusCode === 401) {
            history.push(dashboardUrl + 'sessionexpired');
          }

          setIsError(true);
        }
      } catch (err) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchTeamData();
  }, [authCtx.baseApiUrl, authCtx.token, teamUuid, dashboardUrl, history]);

  const showDetailsHandler = (formTeamDetails) => {
    // console.log(' -- formTeamDetails = ' + formTeamDetails);
    if (formTeamDetails.name) {
      setTeamDetails(formTeamDetails);
    }
    setShowDetails(true);
    setShowForm(false);

    setOpenPopup(false);
  };

  const editHandler = () => {
    setShowDetails(false);
    setShowForm(true);
  };

  const editInPopupHandler = () => {
    setOpenPopup(true);
  };

  const showListHandler = () => {
    history.push(dashboardUrl + 'teams');
  };

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' message={errorMessage} /> : null}

      {!isLoading && !isError ? (
        <>
          <Box className='title-and-button-holder'>
            <Typography variant='h1' component='h1'>
              {pageTitle}
            </Typography>
            <Box className='button-holder-no-pb'>
              {mode === 'details' ? (
                <Button variant='contained' color='accent' onClick={editInPopupHandler}>
                  {editButtonText}
                </Button>
              ) : null}
              {userRole === 'Admin' && mode !== 'list' ? (
                <Button variant='contained' color='accent' onClick={showListHandler}>
                  Teams
                </Button>
              ) : null}
            </Box>
          </Box>

          {showForm ? (
            <TeamForm
              onCancel={showDetailsHandler}
              leagues={leagues}
              teamDetails={teamDetails}
              baseApiUploadedImagesPath={baseApiUploadedImagesPath}
            />
          ) : null}

          {teamUuid && showDetails ? (
            <>
              <TeamDetails
                onEdit={editHandler}
                onEditInPopup={editInPopupHandler}
                teamDetails={teamDetails}
                baseApiUploadedImagesPath={baseApiUploadedImagesPath}
              />

              {userRole === 'Admin' || teamDetails.status === 'Active' ? (
                <Box sx={{ pt: '20px' }}>
                  <TeamContacts contacts={teamDetails.contacts} teamUuid={teamUuid} />
                </Box>
              ) : null}
            </>
          ) : null}

          {teamDetails ? (
            <Popup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              title={teamDetails.name}
              maxWidth='lg'
            >
              <TeamForm
                onCancel={showDetailsHandler}
                leagues={leagues}
                teamDetails={teamDetails}
                baseApiUploadedImagesPath={baseApiUploadedImagesPath}
              />
            </Popup>
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default Team;

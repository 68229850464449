import { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import LeaguesAutocompleteFormField from '../FormControls/LeaguesAutocompleteFormField';
import FormControls from '../MUI/FormControls/FormControls';
// import classes from '../CSS/standard-form.module.css';
import classes from '../CSS/standard-mui-form.module.css';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Deleted', title: 'Deleted' },
];

const DeliveryLocationForm = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();

  const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;
  const displayDescription = false;

  let { deliveryLocationUuid, deliveryLocationDetails, onCancel } = props;

  if (!deliveryLocationDetails) {
    deliveryLocationDetails = {
      deliveryLocationuid: '',
      name: '',
      description: '',
      leagues: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      email: '',
      phone: '',
      deliveryInstructions: '',
      status: '',
    };
  }

  const [selectedLeagues, setSelectedLeagues] = useState(null);
  const [userStatus, setUserStatus] = useState(
    deliveryLocationDetails.status ? deliveryLocationDetails.status : 'Active'
  );
  const [leagues, setLeagues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // console.log('deliveryLocationDetails');
  // console.table(deliveryLocationDetails);

  // const currentDeliveryLocationLeagues = deliveryLocationDetails.leagues;

  // const currentDeliveryLocationLeagues = [
  //   { id: 'MLB', title: 'MLB' },
  //   { id: 'MLS', title: 'MLS' },
  // ];

  // const currentDeliveryLocationLeagues = ['MLB', 'MLS'];
  let currentDeliveryLocationLeagues = [];
  if (deliveryLocationDetails.leagues) {
    // console.log('deliveryLocationDetails.leagues = ' + deliveryLocationDetails.leagues);

    const returnedLeagues = deliveryLocationDetails.leagues;
    const returnedLeaguesArray = returnedLeagues.split(', ');

    // console.log('returnedLeaguesArray');
    // console.log(returnedLeaguesArray);

    returnedLeaguesArray.sort();

    currentDeliveryLocationLeagues = returnedLeaguesArray.map((league) => {
      return {
        name: league,
      };
    });
  }

  // const currentDeliveryLocationLeagues = [{ name: 'MLB' }, { name: 'MLS' }];

  // console.log('currentDeliveryLocationLeagues');
  // console.log(currentDeliveryLocationLeagues);

  // const leagueOptions = [
  //   { id: 'MLB', title: 'MLB' },
  //   { id: 'MLS', title: 'MLS' },
  //   { id: 'NFL', title: 'NFL' },
  // ];

  // const leagueOptions = ['MLB', 'MLS', 'NFL'];

  const nameRef = useRef();
  const descriptionRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipcodeRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const deliveryInstructionsRef = useRef();

  let submitButtonText = 'Save';

  useEffect(() => {
    const fetchDataGlobalLeagues = async () => {
      // console.log(' *** useEffect - fetchDataGlobalLeagues *** ');
      try {
        setIsLoading(true);
        const response = await fetch(authCtx.baseApiUrl + 'globaldata/leagues', {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const responseData = await response.json();
          // console.log(responseData);

          const transformedLeagues = responseData.data.active_leagues.map((league) => {
            return {
              name: league,
            };
          });

          // console.log('transformedLeagues');
          // console.log(transformedLeagues);

          setLeagues(transformedLeagues);
        } else {
          setIsError(true);
        }
      } catch (err) {
        // console.log(err);
        setIsError(true);
        return false;
      }

      setIsLoading(false);
    };

    fetchDataGlobalLeagues();
  }, [authCtx.baseApiUrl, authCtx.token]);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [isLoading]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    let apiURL = '';
    let apiMethod = '';

    if (deliveryLocationUuid !== '' && deliveryLocationUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'deliverylocations/' + deliveryLocationUuid;
      apiMethod = 'PATCH';
      submitButtonText = 'Update Delivery Location';
    } else {
      apiURL = authCtx.baseApiUrl + 'deliverylocations';
      apiMethod = 'POST';
      submitButtonText = 'Create Delivery Location';
    }

    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    const enteredName = nameRef.current.value;
    // const enteredDescription = descriptionRef.current.value;
    const enteredDescription = descriptionRef.current ? descriptionRef.current.value : null;
    const enteredAddress1 = address1Ref.current.value;
    const enteredAddress2 = address2Ref.current.value;
    const enteredCity = cityRef.current.value;
    const enteredState = stateRef.current.value;
    const enteredZipcode = zipcodeRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredDeliveryInstructions = deliveryInstructionsRef.current.value;

    // Todo:  Add Validation.

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        leagues: selectedLeagues,
        address1: enteredAddress1,
        address2: enteredAddress2,
        city: enteredCity,
        state: enteredState,
        zipcode: enteredZipcode,
        email: enteredEmail,
        phone: enteredPhone,
        delivery_instructions: enteredDeliveryInstructions,
        status: userStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setNotify({
          isOpen: true,
          message: 'Delivery Location Successfully Saved.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          nameRef.current.value = '';
          // descriptionRef.current.value = '';
          if (descriptionRef.current) {
            descriptionRef.current.value = '';
          }
          address1Ref.current.value = '';
          address2Ref.current.value = '';
          cityRef.current.value = '';
          stateRef.current.value = '';
          zipcodeRef.current.value = '';
          emailRef.current.value = '';
          phoneRef.current.value = '';
          deliveryInstructionsRef.current.value = '';

          const deliveryLocationUuid = responseData.data.delivery_locations[0].deliveryLocationUuid;

          history.replace(dashboardUrl + 'delivery-locations/details/' + deliveryLocationUuid);
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Delivery Location Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const setLeaguesHandler = (event, selectedLeagues) => {
    // console.log('setLeaguesHandler');
    // console.log('selectedLeagues');
    // console.log(selectedLeagues);

    let leaguesString = '';
    if (Array.isArray(selectedLeagues) && selectedLeagues.length > 0) {
      const leaguesArray = selectedLeagues.map((league) => {
        return league.name;
      });

      // console.log('leaguesArray');
      // console.log(leaguesArray);

      leaguesArray.sort();

      leaguesString = leaguesArray.join(', ');
    }

    // console.log('leaguesString');
    // console.log(leaguesString);

    setSelectedLeagues(leaguesString);
  };

  const statusChangeHandler = (event) => {
    setUserStatus(event.target.value);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading ? (
        <Paper elevation={4} className={classes['form-container']}>
          <form onSubmit={submitHandler}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
              }}
            >
              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='name'
                  label='Name'
                  inputRef={nameRef}
                  defaultValue={deliveryLocationDetails.name}
                  required
                  inputProps={{ maxLength: 50 }}
                  className={classes['mui-control']}
                />

                <Box className={classes['grid-2']}>
                  {userRole === 'Admin' ? (
                    <Box component='div'>
                      <LeaguesAutocompleteFormField
                        leagues={leagues}
                        currentLeagues={currentDeliveryLocationLeagues}
                        onChange={setLeaguesHandler}
                      />
                    </Box>
                  ) : null}

                  <FormControls.SelectControl
                    name='status'
                    label='Status'
                    value={userStatus}
                    onChange={statusChangeHandler}
                    options={statusOptions}
                  />
                </Box>
              </Box>

              {displayDescription ? (
                <FormControls.InputControl
                  type='text'
                  name='description'
                  label='Description'
                  inputRef={descriptionRef}
                  defaultValue={deliveryLocationDetails.description}
                  className={classes['mui-control']}
                  multiline
                  minRows={3}
                  maxRows={12}
                />
              ) : null}

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='address1'
                  label='Address 1'
                  inputRef={address1Ref}
                  defaultValue={deliveryLocationDetails.address1}
                  required
                  inputProps={{ maxLength: 250 }}
                  className={classes['mui-control']}
                />
                <FormControls.InputControl
                  type='text'
                  name='address2'
                  label='Address 2'
                  inputRef={address2Ref}
                  defaultValue={deliveryLocationDetails.address2}
                  inputProps={{ maxLength: 250 }}
                  className={classes['mui-control']}
                />
              </Box>

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='city'
                  label='City'
                  inputRef={cityRef}
                  defaultValue={deliveryLocationDetails.city}
                  inputProps={{ maxLength: 50 }}
                  className={classes['mui-control']}
                />

                <Box className={classes['grid-2']}>
                  <FormControls.InputControl
                    type='text'
                    name='state'
                    label='State'
                    inputRef={stateRef}
                    defaultValue={deliveryLocationDetails.state}
                    inputProps={{ maxLength: 3 }}
                    className={classes['mui-control']}
                  />
                  <FormControls.InputControl
                    type='text'
                    name='zipcode'
                    label='Zipcode'
                    inputRef={zipcodeRef}
                    defaultValue={deliveryLocationDetails.zipcode}
                    inputProps={{ maxLength: 7 }}
                    className={classes['mui-control']}
                  />
                </Box>
              </Box>

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='phone'
                  label='Phone'
                  inputRef={phoneRef}
                  defaultValue={deliveryLocationDetails.phone}
                  required
                  inputProps={{ maxLength: 15 }}
                  className={classes['mui-control']}
                />
                <FormControls.InputControl
                  type='email'
                  name='email'
                  label='Email'
                  inputRef={emailRef}
                  defaultValue={deliveryLocationDetails.email}
                  inputProps={{ maxLength: 50 }}
                  className={classes['mui-control']}
                />
              </Box>

              <FormControls.InputControl
                type='text'
                name='delivery_instructions'
                label='Delivery Instructions'
                inputRef={deliveryInstructionsRef}
                defaultValue={deliveryLocationDetails.deliveryInstructions}
                className={classes['mui-control']}
                multiline
                minRows={6}
                maxRows={12}
              />
            </Box>

            <Box component='div' className={classes['actions-container']} sx={{ pt: '20px' }}>
              <FormControls.ButtonControl text={submitButtonText} type='submit' color='primary' />
              <FormControls.ButtonControl
                text='Cancel'
                onClick={onCancel}
                type='button'
                color='neutral200'
              />
            </Box>
          </form>
        </Paper>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryLocationForm;

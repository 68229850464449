import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    // flexDirection: 'row',
    // backgroundColor: '#e4e4e4',
    fontSize: '12px',
  },
  sideBySideSection: {
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'space-between',
    // border: '1px solid red',
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
  },
  subSection: {
    marginBottom: 10,
  },
  headerText: {
    fontWeight: 900,
    paddingBottom: 10,
  },
});

const PdfDeliveryDetails = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
  } = props;

  // console.log('deliveryDateAndTimeForDisplay', deliveryDateAndTimeForDisplay);
  // console.log('deliveryLocationForDisplay', deliveryLocationForDisplay);
  // console.log('recordDetails', recordDetails);
  // console.log('orderContacts', orderContacts);
  // console.log('teamNamePassedIn', teamNamePassedIn);

  const orderNumber = recordDetails ? recordDetails.orderNumber : '';

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.sideBySideSection}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Delivery Date</Text>
            {deliveryDateAndTimeForDisplay ? <Text>{deliveryDateAndTimeForDisplay}</Text> : null}
          </View>

          <View style={styles.section}>
            <Text style={styles.headerText}>Order Number</Text>
            {orderNumber ? <Text>{orderNumber}</Text> : null}
          </View>
        </View>

        <View style={styles.sideBySideSection}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Address</Text>
            {recordDetails ? <Text>{recordDetails.teamName}</Text> : null}

            {deliveryLocationForDisplay ? (
              <>
                <Text>{deliveryLocationForDisplay.name}</Text>
                <Text>{deliveryLocationForDisplay.addressLine1}</Text>
                <Text>{deliveryLocationForDisplay.addressLine2}</Text>
              </>
            ) : null}
          </View>

          {Array.isArray(orderContacts) && orderContacts.length > 0 ? (
            <View style={styles.section}>
              <Text style={styles.headerText}>
                {orderContacts.length === 1 ? 'Contact' : 'Contacts'}
              </Text>

              {orderContacts.map((record, index) => (
                <View style={styles.subSection} key={index}>
                  <Text>{record.name}</Text>
                  <Text>{record.position}</Text>
                  <Text>{record.phone}</Text>
                  <Text>{record.email}</Text>
                </View>
              ))}
            </View>
          ) : null}
        </View>

        <View style={styles.section}>
          <Text style={styles.headerText}>Delivery Instructions</Text>
          {recordDetails ? <Text>{recordDetails.deliveryInstructions}</Text> : null}
        </View>

        <View style={styles.section}>
          <Text style={styles.headerText}>Team Instructions</Text>
          {recordDetails ? <Text>{recordDetails.teamInstructions}</Text> : null}
        </View>
      </Page>
    </Document>
  );
};

export default PdfDeliveryDetails;

import { useState, useRef, useEffect, useContext } from 'react';
import { Box } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
// import classes from './../CSS/AdditionalCharges.module.css';
import classes from '../CSS/standard-mui-form.module.css';

// const statusOptions = [
//   { id: 'Active', title: 'Active' },
//   { id: 'Inactive', title: 'Inactive' },
//   { id: 'Deleted', title: 'Deleted' },
// ];

const AdditionalChargesForm = (props) => {
  const authCtx = useContext(AuthContext);

  let { additionalChargeDetails, orderUuid, onClose, onCancel } = props;

  if (!additionalChargeDetails) {
    additionalChargeDetails = {
      additionalChargeUuid: '',
      orderUuid: '',
      name: 'Additional Charge',
      description: '',
      price: '',
      status: '',
    };
  }

  // console.log('** AdditionalChargesForm ** additionalChargeDetails');
  // console.table(additionalChargeDetails);

  // const [additionalChargeStatus, setAdditionalChargeStatus] = useState(
  //   additionalChargeDetails.status ? additionalChargeDetails.status : 'Active'
  // );
  const [isLoading, setIsLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const nameRef = useRef();
  const descriptionRef = useRef();
  const quantityRef = useRef();
  const priceRef = useRef();

  const defaultStatus = 'Active';
  let submitButtonText = 'Save';

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [isLoading]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredName = nameRef.current.value;
    const enteredDescription = descriptionRef.current.value;
    const enteredQuantity = quantityRef.current.value;
    const enteredPrice = priceRef.current.value;

    let apiURL = '';
    let apiMethod = '';

    if (
      additionalChargeDetails.additionalChargeUuid !== '' &&
      additionalChargeDetails.additionalChargeUuid !== undefined
    ) {
      apiURL =
        authCtx.baseApiUrl + 'additionalcharges/' + additionalChargeDetails.additionalChargeUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'additionalcharges';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        order_uuid: orderUuid,
        name: enteredName,
        description: enteredDescription,
        price: enteredPrice,
        quantity: enteredQuantity,
        status: defaultStatus,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          nameRef.current.value = '';
          descriptionRef.current.value = '';
          quantityRef.current.value = '';
          priceRef.current.value = '';

          onClose();
        });
      } else {
        return res.json().then((responseData) => {
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Additional Charge Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  // const statusChangeHandler = (event) => {
  //   setAdditionalChargeStatus(event.target.value);
  // };

  return (
    <>
      {isLoading ? (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      ) : null}

      {/* {isError ? (
        <div className='message-holder'>
          <p className='error'>Error...</p>
        </div>
      ) : null} */}

      {!isLoading ? (
        // <Paper elevation={4} className='standard-form'>
        <Box className='standard-form'>
          {/* <Typography variant='h7' component='h6' align='center'>
            Additional Charge
          </Typography> */}
          <form onSubmit={submitHandler}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
              <FormControls.InputControl
                type='text'
                name='name'
                label='Name'
                inputRef={nameRef}
                defaultValue={additionalChargeDetails.name}
                required
                inputProps={{ maxLength: 50 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='description'
                label='Description'
                inputRef={descriptionRef}
                defaultValue={additionalChargeDetails.description}
                className={classes['mui-control']}
                multiline
                minRows={8}
                maxRows={16}
              />

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='number'
                  name='quantity'
                  label='Quantity'
                  inputRef={quantityRef}
                  defaultValue={
                    additionalChargeDetails.quantity ? additionalChargeDetails.quantity : 1
                  }
                  required
                  inputProps={{ type: 'number', min: '0', max: '100000', step: '1' }}
                  className={classes['mui-control']}
                />

                <FormControls.InputControl
                  type='text'
                  name='price'
                  label='Price'
                  inputRef={priceRef}
                  defaultValue={additionalChargeDetails.price}
                  required
                  inputProps={{ type: 'number', min: '0', max: '19999.99', step: '0.01' }}
                  className={classes['mui-control']}
                />
              </Box>
            </Box>
            {/* <Box sx={{ width: '15rem', pt: '0.5rem' }}>
              <FormControls.SelectControl
                name='status'
                label='Status'
                value={additionalChargeStatus}
                onChange={statusChangeHandler}
                options={statusOptions}
              />
            </Box> */}
            <Box component='div' className='button-holder' sx={{ pt: '3rem' }}>
              <FormControls.ButtonControl text={submitButtonText} type='submit' color='primary' />
              <FormControls.ButtonControl
                text='Cancel'
                onClick={onCancel}
                type='button'
                color='neutral200'
              />
            </Box>
          </form>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default AdditionalChargesForm;

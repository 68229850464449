import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import LeaguesAutocompleteFormField from '../FormControls/LeaguesAutocompleteFormField';
import StatusSelectFormField from '../FormControls/StatusSelectFormField';
import SimpleStatusSelectFormField from '../FormControls/SimpleStatusSelectFormField';
import RestaurantHeaderImage from '../FormControls/RestaurantHeaderImage';
import RestaurantListImage from '../FormControls/RestaurantListImage';
import CreatePaymentAccount from '../Stripe/CreatePaymentAccount';
import classes from '../CSS/standard-mui-form.module.css';

const RestaurantForm = (props) => {
  const {
    restaurantDetails = {},
    parentRestaurantUuid = '',
    parentRestaurantName = '',
    globalFoodTypesForForm,
    globalLeaguesForForm,
    reloadData,
    onDataUpdated,
    onCancel,
    onEditImages,
  } = props;

  // console.log('######## RestaurantForm ########');
  // console.log('restaurantDetails');
  // console.log(restaurantDetails);

  const authCtx = useContext(AuthContext);

  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;
  const restaurantFolder = restaurantDetails.restaurantUuid;
  baseApiUploadedImagesPath += restaurantFolder + '/';

  const userRole = authCtx.userRole;
  // const dashboardUrl = authCtx.dashboardUrl;
  const defaultDeliveryLeadTime = authCtx.defaultOrderLeadTime;

  const displayPaymentAccountForm = false;
  const displayPaymentAccountFields = true;
  const displayDescriptionAsAdditionalInfo = true;
  const displayListImage = true;

  if (!restaurantDetails.name) {
    restaurantDetails.orderLeadTime = authCtx.defaultOrderLeadTime;
    restaurantDetails.taxRate = 0.0;
  }

  const [foodType, setFoodType] = useState(
    restaurantDetails.foodType ? restaurantDetails.foodType : ''
  );
  const [selectedLeagues, setSelectedLeagues] = useState(null);
  const [restaurantStatus, setRestaurantStatus] = useState(
    restaurantDetails.status ? restaurantDetails.status : 'New'
  );

  const [paymentAccountStatus, setPaymentAccountStatus] = useState(
    restaurantDetails.paymentAccountStatus ? restaurantDetails.paymentAccountStatus : 'Not Setup'
  );

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const [selectedHeaderImage, setSelectedHeaderImage] = useState(null);
  const [selectedListImage, setSelectedListImage] = useState('');

  let currentRestaurantLeagues = [];
  if (restaurantDetails.leagueExperience) {
    const returnedLeagues = restaurantDetails.leagueExperience;
    const returnedLeaguesArray = returnedLeagues.split(', ');

    returnedLeaguesArray.sort();

    currentRestaurantLeagues = returnedLeaguesArray.map((league) => {
      return {
        name: league,
      };
    });
  }

  const nameRef = useRef();
  const descriptionRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipcodeRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const orderLeadTimeRef = useRef();
  const emailNotificationAddressesRef = useRef();
  const paymentAccountIdRef = useRef();
  const paymentTaxRateIdRef = useRef();

  const headerImageChangeHandler = (event) => {
    // console.log('-- headerImageChangeHandler -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    // const filename = files[0].name;
    // setSelectedHeaderImageName(filename);
    // console.log('filename');
    // console.log(filename);

    fileReader.onload = (event) => {
      setSelectedHeaderImage(event.target.result);
    };
  };

  const listImageChangeHandler = (event) => {
    // console.log('-- listImageChangeHandler -- ');

    let files = event.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    // const filename = files[0].name;
    // setSelectedListImageName(filename);
    // console.log('filename');
    // console.log(filename);

    fileReader.onload = (event) => {
      setSelectedListImage(event.target.result);
    };
  };

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredName = nameRef.current.value;

    const enteredDescription = descriptionRef.current ? descriptionRef.current.value : null;
    const enteredAddress1 = address1Ref.current.value;
    const enteredAddress2 = address2Ref.current.value;
    const enteredCity = cityRef.current.value;
    const enteredState = stateRef.current.value;
    const enteredZipcode = zipcodeRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredPhone = phoneRef.current.value;
    const enteredOrderLeadTime = orderLeadTimeRef.current.value;
    // const enteredNotificationEmailAddresses = emailNotificationAddressesRef.current.value;
    let enteredNotificationEmailAddresses = emailNotificationAddressesRef.current.value;
    const enteredPaymentAccountId = paymentAccountIdRef.current
      ? paymentAccountIdRef.current.value
      : null;
    const enteredPaymentTaxRateId = paymentTaxRateIdRef.current
      ? paymentTaxRateIdRef.current.value
      : null;
    const enteredFoodType = foodType;
    const enteredStatus = restaurantStatus;
    const enteredPaymentAccountStatus = paymentAccountStatus;

    // Todo:  Add Validation.
    // console.log('enteredOrderLeadTime = ' + enteredOrderLeadTime);
    if (enteredOrderLeadTime < defaultDeliveryLeadTime) {
      setNotify({
        isOpen: true,
        message: 'The Order Lead Time needs to be at least ' + defaultDeliveryLeadTime + ' Hours',
        type: 'error',
      });
      return;
    }

    // Validate the notification_email_addresses which is a comma separated list of email addresses.
    // If the string ends with a comma remove it.
    enteredNotificationEmailAddresses = enteredNotificationEmailAddresses.trim();
    if (enteredNotificationEmailAddresses.endsWith(',')) {
      enteredNotificationEmailAddresses = enteredNotificationEmailAddresses.slice(0, -1);
    }

    let apiURL = '';
    let apiMethod = '';

    if (restaurantDetails.restaurantUuid !== '' && restaurantDetails.restaurantUuid !== undefined) {
      apiURL = authCtx.baseApiUrl + 'restaurants/' + restaurantDetails.restaurantUuid;
      apiMethod = 'PATCH';
    } else {
      if (parentRestaurantUuid) {
        apiURL = authCtx.baseApiUrl + 'restaurants/child/' + parentRestaurantUuid;
      } else {
        apiURL = authCtx.baseApiUrl + 'restaurants';
      }
      apiMethod = 'POST';
    }

    // console.log('apiURL = ' + apiURL);
    // console.log('apiMethod = ' + apiMethod);

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        name: enteredName,
        description: enteredDescription,
        address1: enteredAddress1,
        address2: enteredAddress2,
        city: enteredCity,
        state: enteredState,
        zipcode: enteredZipcode,
        email: enteredEmail,
        phone: enteredPhone,
        food_type: enteredFoodType,
        league_experience: selectedLeagues,
        order_lead_time: enteredOrderLeadTime,
        payment_account_status: enteredPaymentAccountStatus,
        payment_account_id: enteredPaymentAccountId,
        payment_tax_rate_id: enteredPaymentTaxRateId,
        notification_email_addresses: enteredNotificationEmailAddresses,
        status: enteredStatus,
        header_image: selectedHeaderImage,
        list_image: selectedListImage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        if (nameRef.current) {
          nameRef.current.value = '';
        }
        if (descriptionRef.current) {
          descriptionRef.current.value = '';
        }
        address1Ref.current.value = '';
        address2Ref.current.value = '';
        cityRef.current.value = '';
        stateRef.current.value = '';
        zipcodeRef.current.value = '';
        emailRef.current.value = '';
        phoneRef.current.value = '';
        orderLeadTimeRef.current.value = '';
        if (paymentAccountIdRef.current) {
          paymentAccountIdRef.current.value = '';
        }
        if (paymentTaxRateIdRef.current) {
          paymentTaxRateIdRef.current.value = '';
        }
        if (emailNotificationAddressesRef.current) {
          emailNotificationAddressesRef.current.value = '';
        }

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          if (apiMethod === 'POST') {
            const returnedRestaurantUuid =
              responseData.data.restaurants[0] && responseData.data.restaurants[0].restaurantUuid
                ? responseData.data.restaurants[0].restaurantUuid
                : '';

            onDataUpdated(returnedRestaurantUuid);
          } else {
            reloadData();
          }
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const foodTypeChangeHandler = (event) => {
    // console.log('foodtypeChangeHandler - event.target.value = ' + event.target.value);
    setFoodType(event.target.value);
  };

  const setLeaguesHandler = (event, selectedLeagues) => {
    // console.log('setLeaguesHandler');
    // console.log('selectedLeagues');
    // console.log(selectedLeagues);

    let leaguesString = '';
    if (Array.isArray(selectedLeagues) && selectedLeagues.length > 0) {
      const leaguesArray = selectedLeagues.map((league) => {
        return league.name;
      });

      // console.log('leaguesArray');
      // console.log(leaguesArray);

      leaguesArray.sort();

      leaguesString = leaguesArray.join(', ');
    }

    // console.log('leaguesString');
    // console.log(leaguesString);

    setSelectedLeagues(leaguesString);
  };

  const statusChangeHandler = (event) => {
    // console.log('statusChangeHandler - event.target.value = ' + event.target.value);
    setRestaurantStatus(event.target.value);
  };

  const paymentAccountStatusChangeHandler = (event) => {
    // console.log('paymentAccountStatusChangeHandler - event.target.value = ' + event.target.value);
    setPaymentAccountStatus(event.target.value);
  };

  return (
    <Box>
      {restaurantDetails.restaurantUuid &&
      restaurantDetails.status === 'Active' &&
      restaurantDetails.paymentAccountStatus !== 'Active' &&
      displayPaymentAccountForm ? (
        <Box sx={{ pt: '4rem' }}>
          <CreatePaymentAccount userRole={userRole} restaurantDetails={restaurantDetails} />
        </Box>
      ) : null}

      <Paper elevation={4} sx={{ maxWidth: '120rem' }}>
        <form onSubmit={submitHandler}>
          <Box className={classes['form-container']}>
            {parentRestaurantUuid ? (
              <Box>
                <Typography variant='labelInline'>
                  Parent Restaurant:
                  <Typography variant='h2' component='span' className='pl-20'>
                    {parentRestaurantName}
                  </Typography>
                </Typography>
              </Box>
            ) : null}

            <FormControls.InputControl
              type='text'
              name='name'
              label='Restaurant Name'
              defaultValue={restaurantDetails.name}
              inputRef={nameRef}
              required
              inputProps={{ maxLength: 50 }}
              className={classes['mui-control']}
            />

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='address1'
                label='Address 1'
                defaultValue={restaurantDetails.address1}
                inputRef={address1Ref}
                required
                inputProps={{ maxLength: 255 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='address2'
                label='Address 2'
                defaultValue={restaurantDetails.address2}
                inputRef={address2Ref}
                inputProps={{ maxLength: 255 }}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='text'
                name='city'
                label='City'
                defaultValue={restaurantDetails.city}
                inputRef={cityRef}
                required
                inputProps={{ maxLength: 50 }}
                className={`${classes['mui-control']}`}
              />

              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='text'
                  name='state'
                  label='State'
                  defaultValue={restaurantDetails.state}
                  inputRef={stateRef}
                  required
                  inputProps={{ maxLength: 2 }}
                  className={`${classes['mui-control']}`}
                />
                <FormControls.InputControl
                  type='text'
                  name='zipcode'
                  label='Zipcode'
                  defaultValue={restaurantDetails.zipcode}
                  inputRef={zipcodeRef}
                  // required
                  inputProps={{ maxLength: 10 }}
                  className={`${classes['mui-control']}`}
                />
              </Box>
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.InputControl
                type='email'
                name='email'
                label='Email'
                defaultValue={restaurantDetails.email}
                inputRef={emailRef}
                // required
                inputProps={{ maxLength: 50 }}
                className={classes['mui-control']}
              />
              <FormControls.InputControl
                type='text'
                name='phone'
                label='Phone'
                defaultValue={restaurantDetails.phone}
                inputRef={phoneRef}
                required
                inputProps={{ maxLength: 15 }}
                className={classes['mui-control']}
              />
            </Box>

            <Box className={classes['grid-2']}>
              <FormControls.SelectControl
                name='food_type'
                label='Food Type *'
                value={foodType}
                required
                onChange={foodTypeChangeHandler}
                options={globalFoodTypesForForm}
              />
              <LeaguesAutocompleteFormField
                leagues={globalLeaguesForForm}
                currentLeagues={currentRestaurantLeagues}
                onChange={setLeaguesHandler}
                label='League Experience *'
              />
            </Box>

            <Box className={classes['grid-2']}>
              <Box className={classes['grid-2']}>
                <FormControls.InputControl
                  type='number'
                  min='24'
                  max='120'
                  step='1'
                  name='order_lead_time'
                  label='Order Lead Time (Hours)'
                  defaultValue={
                    restaurantDetails.orderLeadTime
                      ? restaurantDetails.orderLeadTime
                      : authCtx.defaultOrderLeadTime
                  }
                  inputRef={orderLeadTimeRef}
                  inputProps={{ type: 'number', min: '1', max: '120', step: '1', maxLength: 3 }}
                  className={classes['mui-control']}
                />

                {restaurantStatus !== 'New' || userRole === 'Admin' ? (
                  <StatusSelectFormField
                    currentStatus={restaurantStatus}
                    onChange={statusChangeHandler}
                  />
                ) : null}
              </Box>
            </Box>

            {displayDescriptionAsAdditionalInfo ? (
              <Box sx={{ pt: '20px' }}>
                <FormControls.InputControl
                  type='text'
                  name='description'
                  label='Additional Information'
                  inputRef={descriptionRef}
                  defaultValue={restaurantDetails.description}
                  className={classes['mui-control']}
                  multiline
                  minRows={3}
                  maxRows={12}
                />
              </Box>
            ) : null}

            {/* <Box>
              {displayDescriptionAsAdditionalInfo ? (
                <FormControls.InputControl
                  type='text'
                  name='description'
                  label='Additional Information'
                  defaultValue={restaurantDetails.description}
                  inputRef={descriptionRef}
                  inputProps={{ maxLength: 255 }}
                  className={classes['mui-control']}
                />
              ) : null}
            </Box> */}

            <Box className='pt-40'>
              <Typography variant='h6'>Who should get Order Notification Emails?</Typography>
              <FormControls.InputControl
                type='text'
                name='notification_email_addresses'
                label='Notification Email Addresses'
                defaultValue={restaurantDetails.notificationEmailAddresses}
                inputRef={emailNotificationAddressesRef}
                inputProps={{ maxLength: 255 }}
                className={classes['mui-control']}
              />

              <Typography component='div' className='small-text pt-10'>
                A comma separated list of email addresses that will receive all order notification
                emails for this Restaurant.
              </Typography>
              <Typography className='small-text pt-10'>
                Example: email_1@test.com, email_2@test.com
              </Typography>
            </Box>

            {displayPaymentAccountFields && userRole === 'Admin' ? (
              <Box className='pt-20'>
                <Typography variant='h6'>Stripe Payment Account</Typography>

                <Box>
                  {restaurantDetails.paymentAccountStatus === 'Pending' ? (
                    <Typography variant='h6'>The Client's Stripe Account is Pending....</Typography>
                  ) : null}

                  {restaurantDetails.paymentAccountStatus === 'Active' ? (
                    <Typography variant='h6'>The Client's Stripe Account is Active</Typography>
                  ) : null}

                  {!restaurantDetails.paymentAccountStatus ? (
                    <Typography align='center' variant='h6'>
                      The Client has not created their Stripe Account yet.
                    </Typography>
                  ) : null}
                </Box>

                <Box className={classes['grid-3']}>
                  <FormControls.InputControl
                    type='text'
                    name='payment_account_id'
                    label='Payment Account Id'
                    defaultValue={restaurantDetails.paymentAccountId}
                    inputRef={paymentAccountIdRef}
                    inputProps={{ maxLength: 50 }}
                    // disabled={true}
                    className={classes['mui-control']}
                  />
                  <FormControls.InputControl
                    type='text'
                    name='payment_tax_rate_id'
                    label='Payment Tax Rate Id'
                    defaultValue={restaurantDetails.paymentTaxRateId}
                    inputRef={paymentTaxRateIdRef}
                    inputProps={{ maxLength: 50 }}
                    className={classes['mui-control']}
                  />
                  <SimpleStatusSelectFormField
                    label='Payment Account Status'
                    currentStatus={paymentAccountStatus}
                    onChange={paymentAccountStatusChangeHandler}
                  />
                </Box>
              </Box>
            ) : null}

            <Box component='div' className={classes['actions-container']}>
              <FormControls.ButtonControl text='Save' type='submit' />
              <FormControls.ButtonControl text='Cancel' type='button' onClick={onCancel} />
            </Box>

            {restaurantDetails.restaurantUuid ? (
              <Box sx={{ pt: '20px' }}>
                <RestaurantHeaderImage
                  headerImage={restaurantDetails.headerImage}
                  baseApiUploadedImagesPath={baseApiUploadedImagesPath}
                  onHeaderImageChange={headerImageChangeHandler}
                  displayButtons={false}
                />

                {displayListImage ? (
                  <RestaurantListImage
                    listImage={restaurantDetails.listImage}
                    restaurantDetails={restaurantDetails}
                    baseApiUploadedImagesPath={baseApiUploadedImagesPath}
                    onListImageChange={listImageChangeHandler}
                    displayButtons={false}
                  />
                ) : null}

                <Box align='center' className='pt-20'>
                  <Button variant='contained' component='label' size='small' onClick={onEditImages}>
                    Edit Restaurant Images
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </form>
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default RestaurantForm;

import { useState, useRef, useEffect, useContext } from 'react';
import { Box } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from './OrderMessages.module.css';

const OrderMessageForm = (props) => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const { orderUuid, onMessageAdded, mode } = props;

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const messageRef = useRef();

  const buttonText = mode === 'special-requests' ? 'Add Message' : 'Send Message';
  const messageText = mode === 'special-requests' ? 'Saving message...' : 'Sending message...';

  useEffect(() => {
    messageRef.current.focus();
  }, []);

  // Send out order notifications after data has been updated and returned.
  // This is being done to speed things up.
  const sendNotifications = async () => {
    // console.log('sendNotifications - orderUuid = ' + orderUuid);

    // setNotify({ isOpen: true, message: 'Updating order information.', type: 'info' });

    const apiURL = authCtx.baseApiUrl + 'ordermessages/notifications/' + orderUuid;
    const apiMethod = 'GET';

    const response = await fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      // await response.json();
      console.log(responseData);
    } else {
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while sending Notifications.',
        type: 'error',
      });
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({
      isOpen: true,
      // message: 'Sending message...',
      message: messageText,
      type: 'info',
    });

    const enteredMessage = messageRef.current.value;

    // Todo:  Add Validation.

    fetch(authCtx.baseApiUrl + 'ordermessages', {
      method: 'POST',
      body: JSON.stringify({
        order_uuid: orderUuid,
        message: enteredMessage,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        // setNotify({
        //   isOpen: true,
        //   message: 'Message successfully saved.',
        //   type: 'success',
        // });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          messageRef.current.value = '';

          if (mode !== 'special-requests') {
            sendNotifications();
          }

          // const transformedRecords = responseData.data.order_messages.map((orderMessage) => {
          //   return {
          //     orderMessageUuid: orderMessage.orderMessageUuid,
          //     message: orderMessage.message,
          //   };
          // });

          orderCtx.setOrderMessages(responseData.data.order_messages);

          onMessageAdded();
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Order Message Failed',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <Box>
      <Box sx={{ pt: '30px' }}>
        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <FormControls.InputControl
              type='text'
              name='message'
              label='Message'
              inputRef={messageRef}
              className={classes['mui-control']}
              multiline
              minRows={3}
              maxRows={12}
            />
          </Box>
          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl text={buttonText} size='small' type='submit' />
          </Box>
        </form>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default OrderMessageForm;

import { useState, useRef, useContext, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const PaymentAccountForm = ({ restaurantDetails }) => {
  const authCtx = useContext(AuthContext);
  const userDetails = authCtx.userDetails;

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [showForm, setShowForm] = useState(false);

  // console.log('userDetails', userDetails);
  // console.log('restaurantDetails', restaurantDetails);

  const paymentAccountStatus = restaurantDetails.paymentAccountStatus;
  let buttonText = 'Create Account';
  if (paymentAccountStatus === 'Pending') {
    buttonText = 'Complete Account';
  }

  const displayFormFields = false;
  const displayAddress = false;

  const restaurantNameRef = useRef();
  const address1Ref = useRef();
  const address2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipcodeRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();

  if (restaurantDetails.email === '') {
    restaurantDetails.email = userDetails.email;
  }

  useEffect(() => {
    setShowForm(true);
  }, []);

  // console.log('PaymentAccountForm - restaurantDetails', restaurantDetails);
  // console.log('restaurantDetails.name', restaurantDetails.name);

  const submitHandler = (event) => {
    event.preventDefault();
    // console.log('submitHandler');

    const userUuid = userDetails.userUuid;
    const restaurantUuid = restaurantDetails.restaurantUuid;

    const enteredName =
      displayFormFields && restaurantNameRef.current.value ? restaurantNameRef.current.value : '';
    // const enteredAddress1 = address1Ref.current ? address1Ref.current.value : '';
    // const enteredAddress2 = address2Ref.current ? address2Ref.current.value : '';
    // const enteredCity = cityRef.current ? cityRef.current.value : '';
    // const enteredState = stateRef.current ? stateRef.current.value : '';
    // const enteredZipcode = zipcodeRef.current ? zipcodeRef.current.value : '';
    const enteredEmail = displayFormFields && emailRef.current.value ? emailRef.current.value : '';
    const enteredPhone = displayFormFields && phoneRef.current.value ? phoneRef.current.value : '';

    const createAccount = true;

    if (!createAccount) {
      return;
    }

    // console.log('userUuid = ' + userUuid);
    // console.log('restaurantUuid = ' + restaurantUuid);
    // console.log('enteredName = ' + enteredName);
    // console.log('enteredEmail = ' + enteredEmail);
    // console.log('enteredPhone = ' + enteredPhone);

    const apiURL = authCtx.baseApiUrl + 'paymentaccount';
    const apiMethod = 'POST';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        user_uuid: userUuid,
        restaurant_uuid: restaurantUuid,
        restaurant_name: enteredName,
        account_email: enteredEmail,
        account_phone: enteredPhone,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          console.log(' ** SUCCESS ** ');
          console.log(responseData);

          if (responseData.data.account_link_url) {
            window.location.href = responseData.data.account_link_url;
          } else {
            console.log('no account_link_url');
          }
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          // console.log(responseData);
          // setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
            console.table(responseData.messages);
            setNotify({
              isOpen: true,
              message: responseData.messages,
              type: 'error',
            });
          } else {
            // setDisplayMessage([[textFailedApiCallMessage]]);
            console.log('*** SOMETHING HAPPENED ***');
            setNotify({
              isOpen: true,
              message: 'Something Happened',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const checkPaymentAccountStatusHandler = () => {
    // console.log('##### checkPaymentAccountStatusHandler #####');
    window.location.reload();
    // history.replace(
    //   dashboardUrl + 'vendor-profile/payment-account/' + restaurantDetails.restaurantUuid
    // );
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ maxWidth: '120rem', width: '100%' }}>
        {restaurantDetails.paymentAccountStatus === 'Pending' ||
        !restaurantDetails.paymentAccountStatus ? (
          <Box sx={{ pt: '2rem' }}>
            <Typography align='center' variant='h6'>
              {/* Your Stripe Account is Pending.... */}
              Your Payment Account has not been completed as of yet.
            </Typography>

            {restaurantDetails.paymentAccountStatus === 'Pending' ? (
              <>
                <Typography align='center'>
                  It can take several minutes for your information to be validated before your
                  Payment Account becomes active.
                </Typography>
                <Typography align='center'>
                  Please check again after a few minutes by
                  <Button size='small' onClick={checkPaymentAccountStatusHandler}>
                    Clicking Here
                  </Button>
                </Typography>
              </>
            ) : null}

            {displayFormFields ? (
              <Typography sx={{ p: '2rem' }}>
                Please confirm the name, email and phone number you would like to use for your
                Payment Account and click the CREATE ACCOUNT button.
              </Typography>
            ) : null}
          </Box>
        ) : null}

        {restaurantDetails.paymentAccountStatus === 'Active' ? (
          <Typography align='center' variant='h6'>
            Your Stripe Account is Active
          </Typography>
        ) : null}

        {restaurantDetails.paymentAccountStatus === 'Inactive' ? (
          <Typography align='center' variant='h6'>
            Your Stripe Account is Inactive
          </Typography>
        ) : null}

        {restaurantDetails.paymentAccountStatus === 'Not Setup' ? (
          <Typography align='center' variant='h6'>
            Your Stripe Account has not been setup yet
          </Typography>
        ) : null}

        {showForm &&
        restaurantDetails.paymentAccountStatus !== 'Active' &&
        restaurantDetails.paymentAccountStatus !== 'Inactive' ? (
          <form onSubmit={submitHandler}>
            <Box className={classes['form-container']}>
              {displayFormFields ? (
                <>
                  <FormControls.InputControl
                    type='text'
                    name='name'
                    label='Restaurant Name'
                    defaultValue={restaurantDetails.name}
                    inputRef={restaurantNameRef}
                    required
                    inputProps={{ maxLength: 50 }}
                    className={classes['mui-control']}
                  />

                  {displayAddress ? (
                    <>
                      <Box className={classes['grid-2']}>
                        <FormControls.InputControl
                          type='text'
                          name='address1'
                          label='Address 1'
                          defaultValue={restaurantDetails.address1}
                          inputRef={address1Ref}
                          // required
                          inputProps={{ maxLength: 255 }}
                          className={classes['mui-control']}
                        />
                        <FormControls.InputControl
                          type='text'
                          name='address2'
                          label='Address 2'
                          defaultValue={restaurantDetails.address2}
                          inputRef={address2Ref}
                          inputProps={{ maxLength: 255 }}
                          className={classes['mui-control']}
                        />
                      </Box>

                      <Box className={classes['grid-2']}>
                        <FormControls.InputControl
                          type='text'
                          name='city'
                          label='City'
                          defaultValue={restaurantDetails.city}
                          inputRef={cityRef}
                          required
                          inputProps={{ maxLength: 50 }}
                          className={`${classes['mui-control']}`}
                        />

                        <Box className={classes['grid-2']}>
                          <FormControls.InputControl
                            type='text'
                            name='state'
                            label='State'
                            defaultValue={restaurantDetails.state}
                            inputRef={stateRef}
                            required
                            inputProps={{ maxLength: 2 }}
                            className={`${classes['mui-control']}`}
                          />
                          <FormControls.InputControl
                            type='text'
                            name='zipcode'
                            label='Zipcode'
                            defaultValue={restaurantDetails.zipcode}
                            inputRef={zipcodeRef}
                            // required
                            inputProps={{ maxLength: 10 }}
                            className={`${classes['mui-control']}`}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : null}

                  <Box className={classes['grid-2']}>
                    <FormControls.InputControl
                      type='email'
                      name='email'
                      label='Email'
                      defaultValue={restaurantDetails.email}
                      inputRef={emailRef}
                      inputProps={{ maxLength: 50 }}
                      className={classes['mui-control']}
                    />
                    <FormControls.InputControl
                      type='text'
                      name='phone'
                      label='Phone'
                      defaultValue={restaurantDetails.phone}
                      inputRef={phoneRef}
                      required
                      inputProps={{ maxLength: 15 }}
                      className={classes['mui-control']}
                    />
                  </Box>
                </>
              ) : null}

              <Box component='div' className={classes['actions-container']}>
                <FormControls.ButtonControl text={buttonText} type='submit' />
                {/* <FormControls.ButtonControl text='Cancel' type='button' onClick={reloadData} /> */}
                {/* {restaurantStatus !== 'New' ? (
                        <FormControls.ButtonControl text='Cancel' type='button' onClick={reloadData} />
                      ) : null} */}
              </Box>
            </Box>

            <Box>
              <Typography align='center'>
                You will be redirected to the Stripe Site where you will work through the Onboarding
                process to setup your Account to ensure you get PAID.
              </Typography>
            </Box>
          </form>
        ) : null}
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
};

export default PaymentAccountForm;

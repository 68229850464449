import { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@mui/material';
import Popup from '../MUI/UI/Popup';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';

import RestaurantReviewForm from './RestaurantReviewForm';
import RestaurantReviewCard from './RestaurantReviewCard';

const RestaurantReview = (props) => {
  const { userRole, orderUuid } = props;
  // console.log('props');
  // console.log(props);
  const authCtx = useContext(AuthContext);

  const baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;

  const [restaurantReviewUuid, setRestaurantReviewUuid] = useState(null);
  const [rating, setRating] = useState(null);
  const [review, setReview] = useState(null);
  const [reviewImages, setReviewImages] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [openPopupReview, setOpenPopupReview] = useState(false);

  const showReviewForm = () => {
    setOpenPopupReview((current) => !current);
  };

  const refreshReview = () => {
    console.log('** refreshReview **');
    setRefreshData(true);
    setOpenPopupReview((current) => !current);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // console.log('orderUuid = ' + orderUuid);

      const response = await fetch(authCtx.baseApiUrl + 'restaurantreviews/order/' + orderUuid, {
        headers: {
          Authorization: authCtx.token,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        // console.log('responseData.data');
        // console.log(responseData.data);

        setRating(
          responseData.data.restaurant_review && responseData.data.restaurant_review.rating
            ? +responseData.data.restaurant_review.rating
            : 0
        );
        setReview(
          responseData.data.restaurant_review && responseData.data.restaurant_review.review
            ? responseData.data.restaurant_review.review
            : ''
        );
        setRestaurantReviewUuid(
          responseData.data.restaurant_review &&
            responseData.data.restaurant_review.restaurantReviewUuid
            ? responseData.data.restaurant_review.restaurantReviewUuid
            : null
        );

        setReviewImages(responseData.data.review_images ? responseData.data.review_images : null);
      } else {
        console.log('$$$$$ ERROR in Restaurant Review $$$$$');
        setIsError(true);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    if (orderUuid) {
      fetchData().catch(console.error);
    } else {
      setIsLoading(false);
    }
  }, [authCtx.baseApiUrl, authCtx.token, orderUuid, refreshData]);

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {/* {userRole === 'User' ? (
        <RestaurantReviewForm {...props} rating={rating} review={review} />
      ) : null} */}

      {/* userRole !== 'User'  */}

      {rating ? (
        <RestaurantReviewCard
          {...props}
          rating={rating}
          review={review}
          reviewImages={reviewImages}
          baseApiUploadedImagesPath={baseApiUploadedImagesPath}
        />
      ) : null}

      {userRole === 'User' ? (
        <Box align='center' sx={{ pt: '2rem', pb: '1rem' }}>
          <Button size='small' variant='contained' onClick={showReviewForm}>
            {rating ? 'Edit Your Review' : 'Review Your Order'}
          </Button>
        </Box>
      ) : null}

      <Popup
        openPopup={openPopupReview}
        setOpenPopup={setOpenPopupReview}
        maxWidth='md'
        title='Review Your Order'
      >
        <RestaurantReviewForm
          {...props}
          restaurantReviewUuid={restaurantReviewUuid}
          rating={rating}
          review={review}
          popup={true}
          onCancel={refreshReview}
          reviewImages={reviewImages}
          baseApiUploadedImagesPath={baseApiUploadedImagesPath}
        />
      </Popup>
    </Box>
  );
};

export default RestaurantReview;

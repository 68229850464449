import React from 'react';
import { Box } from '@mui/material';

import FormControls from '../MUI/FormControls/FormControls';

const statusOptions = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
  { id: 'Pending', title: 'Pending' },
  { id: 'Not Setup', title: 'Not Setup' },
];

const SimpleStatusSelectFormField = (props) => {
  const { currentStatus, onChange, label = 'Status' } = props;

  return (
    // <Box sx={{ width: '15rem' }}>
    <Box>
      <FormControls.SelectControl
        name='status'
        label={label}
        value={currentStatus}
        onChange={onChange}
        options={statusOptions}
      />
    </Box>
  );
};

export default SimpleStatusSelectFormField;

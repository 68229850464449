import React, { useContext, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import Popup from '../MUI/UI/Popup';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';

const RestaurantReviewImages = (props) => {
  const {
    baseApiUploadedImagesPath,
    reviewImages,
    displayDeleteImageLink = false,
    onCancel,
    imageSize = 'thumbnail',
    displaySingleImage = false,
    showMoreImagesText = false,
  } = props;
  const authCtx = useContext(AuthContext);

  // console.log('reviewImages');
  // console.log(reviewImages);

  // const displaySingleImage = true;

  // const singleReviewImage = [...reviewImages];
  const singleReviewImage = Array.from(reviewImages);

  if (displaySingleImage) {
    singleReviewImage.length = 1;
  }

  // console.log('singleReviewImage');
  // console.log(singleReviewImage);

  // console.log('showMoreImagesText = ' + showMoreImagesText);

  // console.log('reviewImages');
  // console.log(reviewImages);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [openPopupReviewImages, setOpenPopupReviewImages] = useState(false);

  const reviewImageClass = imageSize === 'thumbnail' ? 'review-image-order' : 'review-image-full';
  const reviewImageGridClass = imageSize === 'thumbnail' ? 'image-grid' : 'image-grid-full';

  // console.log('reviewImageClass = ' + reviewImageClass);
  // console.log('reviewImageGridClass = ' + reviewImageGridClass);

  const showReviewImages = () => {
    setOpenPopupReviewImages((current) => !current);
  };

  // const displayReviewImages = () => {
  //   return reviewImages.map((image, index) => (
  //     <Box sx={{ pt: '1rem' }} key={index} className='image-grid-holder'>
  //       <Box className='image-grid'>
  //         <img src={baseApiUploadedImagesPath + image.filenameWithPath} alt='Restaurant Review' />
  //       </Box>
  //     </Box>
  //   ));
  // };

  const displayReviewImages = () => {
    return singleReviewImage.map((image, index) => (
      <Box key={index}>
        {imageSize === 'thumbnail' ? (
          <Tooltip title='Click to show larger images.'>
            <Box align='center' className='review-image-holder'>
              <img
                src={baseApiUploadedImagesPath + image.filenameWithPath}
                alt='Restaurant Review'
                className={reviewImageClass}
                onClick={showReviewImages}
              />
              {reviewImages.length > 1 && showMoreImagesText ? (
                <Box align='center'>
                  <Typography variant='caption'>More Images...</Typography>
                </Box>
              ) : null}
            </Box>
          </Tooltip>
        ) : (
          <img
            src={baseApiUploadedImagesPath + image.filenameWithPath}
            alt='Restaurant Review'
            className={reviewImageClass}
            onClick={showReviewImages}
          />
        )}

        {displayDeleteImageLink ? (
          <Tooltip title='Delete Image'>
            <Button
              variant='text'
              size='small'
              onClick={() => {
                setConfirmDialog({
                  isOpen: true,
                  title: 'Are you sure you want to delete this Review Image?',
                  subTitle: 'It will be permanently deleted.',
                  onConfirm: () => {
                    handleDelete(image);
                  },
                });
              }}
            >
              Delete Image
            </Button>
          </Tooltip>
        ) : null}

        <Popup
          openPopup={openPopupReviewImages}
          setOpenPopup={setOpenPopupReviewImages}
          maxWidth='md'
          title='Review Images'
        >
          <RestaurantReviewImages
            baseApiUploadedImagesPath={baseApiUploadedImagesPath}
            reviewImages={reviewImages}
            imageSize='full'
            showMoreImagesText={false}
          />
        </Popup>
      </Box>
    ));
  };

  // const handleDelete = (image) => {
  //   console.log('Delete Image');
  //   console.log(image);
  // };

  const handleDelete = (image) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteData(image.imageUploadUuid);
    // setNotify({ isOpen: true, message: 'Order Successfully Deleted', type: 'error' });
  };

  const deleteData = async (imageUploadUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'imageupload/' + imageUploadUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);

      //  getAllOrderRecords();
      onCancel();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box>
        <Box className={reviewImageGridClass}>{displayReviewImages()}</Box>

        <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      </Box>
    </>
  );
};

export default RestaurantReviewImages;

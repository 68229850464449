import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import classes from './../CSS/Orders.module.css';

const AdditionalChargeRow = ({
  recordDetails,
  userRole,
  record,
  index,
  editAdditionalChargeHandler,
  setConfirmDialog,
  handleDeleteAdditionalCharge,
}) => {
  return (
    <tr key={index}>
      <td>{record.quantity}</td>
      <td> x </td>
      {userRole === 'Vendor' &&
      (recordDetails.restaurantStatus === 'New' ||
        recordDetails.restaurantStatus === 'Accepted' ||
        recordDetails.restaurantStatus === 'Modified') ? (
        <>
          {/* <td className={`${classes['full-width']} ${classes['additional-charge-with-actions']}`}> */}
          <td className={`${classes['full-width']}`}>
            <Typography>{record.name}</Typography>
            {record.description ? (
              <Box className='details-holder'>
                <ul>
                  <li>{record.description}</li>
                </ul>
              </Box>
            ) : null}
          </td>

          {userRole === 'Vendor' &&
          (recordDetails.restaurantStatus === 'New' ||
            recordDetails.restaurantStatus === 'Accepted' ||
            recordDetails.restaurantStatus === 'Modified') ? (
            <td>
              <Box sx={{ width: '50px' }}>
                <IconButton
                  sx={{
                    padding: '0px',
                    margin: '0px',
                  }}
                  onClick={() => editAdditionalChargeHandler(record.additionalChargeUuid)}
                >
                  <EditIcon color='primary' />
                </IconButton>
                <IconButton
                  sx={{
                    padding: '0px',
                    margin: '0px',
                  }}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: 'Are you sure you want to delete this additional charge.',
                      subTitle: 'It will be permanently deleted.',
                      type: 'General',
                      onConfirm: () => {
                        handleDeleteAdditionalCharge(record.additionalChargeUuid);
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon color='error' />
                </IconButton>
              </Box>
            </td>
          ) : null}
        </>
      ) : (
        <td colSpan='2'>
          <Typography>{record.name}</Typography>
          {record.description ? (
            <Box className='details-holder'>
              <ul>
                <li>{record.description}</li>
              </ul>
            </Box>
          ) : null}
        </td>
      )}

      <td className={`${classes['number']} ${classes['additional-charge-with-actions']}`}>
        {record.subtotalFormatted}
      </td>
    </tr>
  );
};

export default AdditionalChargeRow;

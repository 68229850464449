import { Box, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import * as Constants from '../../helpers/Constants';

import classes from './StartingPageContent.module.css';

const imageFolder = Constants.IMAGE_FOLDER_PATH + 'food-images/';

const foodImages = [
  { src: imageFolder + 'donair_500.png', title: 'Donair' },
  { src: imageFolder + 'shrimp-and-sausage_500.png', title: 'Shrimp and Sausage' },
  { src: imageFolder + 'grilled-chicken-wrap_500.png', title: 'Grilled Chicken Wrap' },
  { src: imageFolder + 'rice-under-chicken_500.png', title: 'Rice under Chicken' },
  { src: imageFolder + 'submarine-sandwich-ham_500.png', title: 'Ham Submarine Sandwich' },
  { src: imageFolder + 'rice-beef-and-string-beans_500.png', title: 'Beef, Rice and String Beans' },
  { src: imageFolder + 'burrito-bowl_500.png', title: 'Burrito Bowl' },
];

const sellingPoints = [
  {
    con: 'Menu is too long and complicated to follow',
    pro: 'Streamlined menu template for every caterer.',
  },
  {
    con: 'Portion sizes are not listed and not adequate for athletes ',
    pro: 'Scaled to fit athletes needs. 1.5 the normal portion size',
  },
  {
    con: 'We need specialty orders for food allergies and preferences',
    pro: 'All vendors have GF, Vegan, Low Carb and Dairy options',
  },
  {
    con: 'We need special instructions on how we want meals prepared',
    pro: 'box for “special instructions”',
  },
  { con: 'Do they do individual portions?', pro: 'Select for boxed meal options' },
  {
    con: 'Can they do custom orders? Or modified orders. Like berry cups., mashed potato? Like add on a few things in addition to what we need?',
    pro: 'Not sure if this would work without a call.',
  },
  {
    con: 'Do they provide, plates, cutlery kits & bags? Service staff ',
    pro: 'Each vendor has a section for this.',
  },
  { con: 'Late delivery', pro: 'Delivery message feature for communication on game day.' },
];

const StartingPageContent = () => {
  return (
    <>
      <Box className='content-container'>
        <Stack direction={{ sm: 'column', md: 'row' }} spacing={{ sx: 2, sm: 4, md: 6 }}>
          <Box
            component='img'
            src={imageFolder + 'boxed-chicken-wings-and-salad_500.png'}
            alt='Boxed Chicken Wings and Salad'
            sx={{ maxHeight: '300px' }}
          />
          <Box>
            <Typography variant='h1' className={classes['main-header-text']}>
              Elite Eats is a
            </Typography>
            <Typography variant='h1' className={classes['main-header-text-accent']}>
              Streamlined Platform
            </Typography>
            <Typography variant='h1' className={classes['main-header-text-line-2']}>
              for Athlete Catering
            </Typography>
            <Typography variant='h2' className={classes['main-sub-header-text']}>
              Our vendors are reviewed and recommended by you – the sport nutrition professional.
            </Typography>
            <Typography variant='h2' className={classes['main-sub-header-text']}>
              We are founded and operated by elite sport dietitians with direct experience in the
              field.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box className={classes['image-row']}>
        {foodImages.map((image, index) => (
          <Box
            component='img'
            key={index}
            src={image.src}
            alt={image.title}
            sx={{ height: '10rem' }}
          />
        ))}
      </Box>
      <Box className='content-container'>
        <Box sx={{ flexGrow: 1, py: '8rem', display: 'flex', justifyContent: 'center' }}>
          <table className={classes['info-table']}>
            <thead>
              <tr>
                <th>Issues when ordering athlete catering</th>
                <th>Solutions that we can offer</th>
              </tr>
            </thead>
            <tbody>
              {sellingPoints.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Stack direction='row'>
                      <CancelIcon className={classes['con-icon']} />
                      {row.con}
                    </Stack>
                  </td>
                  <td>
                    <Stack direction='row'>
                      <CheckCircleIcon className={classes['pro-icon']} />
                      {row.pro}
                    </Stack>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
};

export default StartingPageContent;

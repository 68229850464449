import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const RestaurantListImage = (props) => {
  const {
    listImage = '',
    baseApiUploadedImagesPath,
    onListImageChange,
    displayButtons = false,
  } = props;

  return (
    <Box
      sx={{
        // border: '5px solid cyan',
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        pt: '2rem',
      }}
    >
      {/* <div>Placeholder</div> */}

      <Box>
        <Typography variant='h2' component='h2'>
          List Image
          <Typography variant='caption' component='span' sx={{ ml: '10px' }}>
            (Dimensions: 200px width, 160px height)
          </Typography>
        </Typography>

        <Box align='center' className='pb-20'>
          <Box className='uploaded-list-image-holder'>
            {listImage ? (
              <img
                src={baseApiUploadedImagesPath + listImage}
                alt='Current List'
                className='uploaded-list-image-cover'
              />
            ) : (
              <Box className='uploaded-list-image-filler'>NO IMAGE</Box>
            )}
          </Box>
        </Box>

        {displayButtons ? (
          <Box align='center'>
            <Button variant='contained' component='label' size='small'>
              Select List Image File
              <input
                hidden
                accept='image/*'
                multiple
                type='file'
                name='header_image'
                onChange={(event) => onListImageChange(event)}
              />
            </Button>
          </Box>
        ) : null}
      </Box>

      {/* <div>BOTTOM</div> */}
    </Box>
  );
};

export default RestaurantListImage;

import { useRef, useEffect, useContext } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import AuthContext from '../../../store/auth-context';
import FormControls from '../FormControls/FormControls';
import classes from '../../CSS/standard-form.module.css';

const ResetPasswordForm = (props) => {
  const { toggleShowForm, setNotify } = props;

  const authCtx = useContext(AuthContext);

  const resetCodeRef = useRef();
  const newPasswordRef = useRef();
  const newPasswordConfirmRef = useRef();

  useEffect(() => {
    if (props.setFocus) {
      resetCodeRef.current.focus();
    }
  }, [props.setFocus]);

  const submitHandler = (event) => {
    event.preventDefault();

    setNotify({ isOpen: true, message: 'Sending Request...', type: 'info' });

    const enteredResetCode = resetCodeRef.current.value;
    const enteredNewPassword = newPasswordRef.current.value;
    const enteredNewPasswordConfirm = newPasswordConfirmRef.current.value;

    // console.log(' - enteredResetCode = ' + enteredResetCode);
    // console.log(' - enteredNewPassword = ' + enteredNewPassword);
    // console.log(' - enteredNewPasswordConfirm = ' + enteredNewPasswordConfirm);

    // Todo:  Add Validation.
    // Make sure that the Passwords Match.
    if (enteredNewPassword !== enteredNewPasswordConfirm) {
      setNotify({ isOpen: true, message: 'The Passwords entered do not match.', type: 'error' });
      return;
    }

    fetch(authCtx.baseApiUrl + 'resetpassword', {
      method: 'POST',
      body: JSON.stringify({
        reset_code: enteredResetCode,
        new_password: enteredNewPassword,
      }),
      headers: {
        Authorization: authCtx.signupToken,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        resetCodeRef.current.value = '';
        newPasswordRef.current.value = '';
        newPasswordConfirmRef.current.value = '';

        return res.json().then((responseData) => {
          setNotify({ isOpen: true, message: 'Your Password has been reset.', type: 'info' });

          toggleShowForm('Login');
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(responseData);

          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({ isOpen: true, message: 'Failed to Reset Password', type: 'error' });
          }
        });
      }
    });
  };

  return (
    <>
      <Paper elevation={4} sx={{ maxWidth: '60rem', p: '2rem', m: '0 auto' }}>
        <Typography variant='h5' className={classes['mui-form-header']}>
          Reset Password
        </Typography>

        <Box sx={{ pt: '2rem' }}>
          <Typography>
            Check your Email for a Password Reset Code and enter it here along with a New Password
            and click the Reset Password Button.
          </Typography>
        </Box>

        <form onSubmit={submitHandler}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', pt: '4rem' }}>
            <FormControls.InputControl
              type='text'
              name='reset_code'
              label='Password Reset Code'
              inputRef={resetCodeRef}
              autoComplete='false'
              required
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='password'
              name='new_password'
              label='New Password'
              inputRef={newPasswordRef}
              autoComplete='new-password'
              required
              className={classes['mui-control']}
            />
            <FormControls.InputControl
              type='password'
              name='new_password_confirm'
              label='Confirm New Password'
              inputRef={newPasswordConfirmRef}
              autoComplete='new-password'
              required
              className={classes['mui-control']}
            />
          </Box>
          <Box component='div' className={classes.actions}>
            <FormControls.ButtonControl text='Reset Password' type='submit' />
          </Box>

          <Box align='center'>
            <Button variant='text' onClick={() => toggleShowForm('Login')}>
              Back to Login
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default ResetPasswordForm;

import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';

import OrderMessageForm from './OrderMessageForm';
import OrderMessagesList from './OrderMessagesList';

const OrderMessages = (props) => {
  const { orderUuid, userRole, status, mode } = props;

  // console.log('userRole = ' + userRole);
  // console.log('status = ' + status);
  // console.log('mode = ' + mode);

  const [refreshMessageList, setRefreshMessageList] = useState(null);

  const messageAddedHandler = () => {
    const timeStamp = new Date().getTime();
    setRefreshMessageList(timeStamp);
  };

  // console.log('** refreshMessageList = ' + refreshMessageList);

  return (
    <Box component='div' sx={{ width: '100%' }}>
      <Box>
        {status === 'Draft' && mode !== 'review-order' && mode !== 'special-requests' ? (
          <Box className='pt-20'>
            {/* <Typography variant='h2' component='h2'> */}
            <Typography variant='h8'>Messages</Typography>
            {userRole === 'Admin' ? (
              <Typography>No messages have been added to this Order.</Typography>
            ) : (
              <>
                <Typography>
                  You cannot send a Restaurant a Message until you Place an Order.
                </Typography>
                <Typography sx={{ pt: '1rem' }}>
                  You can always call or email the Restaurant Contact if you have any questions.
                </Typography>
              </>
            )}
          </Box>
        ) : null}

        {status !== 'Draft' || mode === 'review-order' || mode === 'special-requests' ? (
          <OrderMessagesList orderUuid={orderUuid} refreshData={refreshMessageList} mode={mode} />
        ) : null}

        {/* {userRole !== 'Admin' && (status !== 'Draft' || mode === 'special-requests') ? ( */}
        {userRole !== 'Admin' &&
        ((status !== 'Draft' && status !== 'Completed') || mode === 'special-requests') ? (
          <OrderMessageForm
            orderUuid={orderUuid}
            onMessageAdded={messageAddedHandler}
            mode={mode}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default OrderMessages;

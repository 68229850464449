import { useCallback } from 'react';

export const useFormatDateAndTime = () => {
  const FormatDateForDisplay = useCallback((date) => {
    let returnValue = '';
    if (date) {
      // const dateValue = new Date(date);
      // returnFormattedDate = dateValue.toDateString(); // Returns Fri Dec 09 2022
      // returnFormattedDate = dateValue.toLocaleDateString();  // Returns 12/9/2022

      const options = { year: 'numeric', month: 'long', day: 'numeric' }; // September 17, 2022
      let dateValue = new Date(date);
      dateValue = new Date(dateValue.toISOString().slice(0, -1));
      returnValue = dateValue.toLocaleDateString('en-US', options);

      // const dateValue = new Date(date);
      // returnValue = dateValue.toLocaleDateString('en-US', options);
    }
    return returnValue;
  }, []);

  const FormatTimeForDisplay = useCallback((time) => {
    let returnFormattedTime = '';
    if (time) {
      const defaultDate = '1972-03-03';
      const dateValue = new Date(defaultDate + ' ' + time);
      // returnFormattedTime = dateValue.toLocaleTimeString(); // Returns: 7:15:00 PM
      returnFormattedTime = dateValue.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      }); // Returns: 7:15 PM
    }
    return returnFormattedTime;
  }, []);

  const FormatDateAndTimeForDisplay = useCallback((dateTime) => {
    let returnFormattedDateAndTime = '';
    if (dateTime) {
      const dateValue = new Date(dateTime);
      returnFormattedDateAndTime =
        dateValue.toDateString() + ' at ' + dateValue.toLocaleTimeString(); // Returns: Wed Jan 25 2023 at 7:15:00 PM
      // dateValue.toLocaleDateString() + ' at ' + dateValue.toLocaleTimeString();
    }
    return returnFormattedDateAndTime;
  }, []);

  const FormatLongDateForDisplay = useCallback((dateTime) => {
    let returnValue = '';
    if (dateTime) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }; // Saturday, September 17, 2022

      let dateValue = new Date(dateTime);
      dateValue = new Date(dateValue.toISOString().slice(0, -1));
      returnValue = dateValue.toLocaleDateString('en-US', options);

      // Original Code:  This value was using the browswer timezone to format the date so I was getting the wrong value.
      // const dateValue = new Date(dateTime);
      // returnValue = dateValue.toLocaleDateString('en-US', options); // Saturday, September 17, 2022
    }

    return returnValue;
  }, []);

  const FormatLongDateAndTimeForDisplay = useCallback((dateTime) => {
    let returnValue = '';
    if (dateTime) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

      let dateValue = new Date(dateTime);
      dateValue = new Date(dateValue.toISOString().slice(0, -1));

      const formattedTime = dateValue.toLocaleTimeString('en-US');

      returnValue = dateValue.toLocaleDateString('en-US', options) + ' at ' + formattedTime; // Saturday, September 17, 2022 at 1:25:43 PM
    }

    return returnValue;
  }, []);

  return {
    FormatDateForDisplay,
    FormatTimeForDisplay,
    FormatDateAndTimeForDisplay,
    FormatLongDateForDisplay,
    FormatLongDateAndTimeForDisplay,
  };
};

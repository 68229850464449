import UserDetailsTable from './UserDetailsTable';
import UserDetailsCard from './UserDetailsCard';

const UserDetails = ({ userDetails }) => {
  const displayComponent = 'Card';

  return (
    <>
      {displayComponent === 'Card' ? <UserDetailsCard details={userDetails} /> : null}
      {displayComponent === 'Table' ? <UserDetailsTable details={userDetails} /> : null}
    </>
  );
};

export default UserDetails;

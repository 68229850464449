import React from 'react';
import { Box } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import PdfDeliveryDetails from '../ReactPdf/PdfDeliveryDetails';

const PrintDeliveryDetails = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
  } = props;

  return (
    <Box sx={{ pt: '2rem' }}>
      <PDFViewer className='pdf-viewer'>
        <PdfDeliveryDetails
          deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
          deliveryLocationForDisplay={deliveryLocationForDisplay}
          recordDetails={recordDetails}
          orderContacts={orderContacts}
        />
      </PDFViewer>
    </Box>
  );
};

export default PrintDeliveryDetails;

import { Box } from '@mui/material';

import FormControls from '../MUI/FormControls/FormControls';

const LeaguesAutocompleteFormField = (props) => {
  const { leagues, currentLeagues, onChange, label = 'Leagues' } = props;

  return leagues ? (
    <Box component='div'>
      <FormControls.AutocompleteMultipleControl
        name='leagues'
        label={label}
        options={leagues}
        getOptionLabel={(option) => option.name ?? option}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        fullWidth
        defaultValue={currentLeagues}
        onChange={onChange}
      />
    </Box>
  ) : null;
};

export default LeaguesAutocompleteFormField;

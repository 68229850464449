import { Box, Typography } from '@mui/material';

import LoadingIndicator from './LoadingIndicator';

const WorkflowMessage = (props) => {
  let { type, message, alignText = 'center' } = props;

  if (!message) {
    if (type === 'loading') {
      message = 'Loading.....';
    }
    // if (type === 'error') {
    //   message = 'Something went wrong.....';
    // }
  }

  return (
    <>
      {/* <Box className={type === 'loading' ? 'loading-message-holder' : 'message-holder'}>
        {type === 'loading' ? <LoadingIndicator /> : null}

        {type === 'error' && !message ? (
          <Box>
            <Typography className={type}>Oops,</Typography>
            <Typography className={type}>Something went wrong...</Typography>{' '}
          </Box>
        ) : null}
      </Box> */}

      {type === 'loading' ? (
        <Box className='loading-message-holder'>
          <LoadingIndicator />
        </Box>
      ) : null}

      {type === 'error' ? (
        <Box className='message-holder'>
          {message ? (
            <Box>
              <Typography align={alignText} className={type}>
                {message}
              </Typography>
            </Box>
          ) : null}
          {!message ? (
            <Box>
              <Typography className={type}>Oops,</Typography>
              <Typography className={type}>Something went wrong...</Typography>
            </Box>
          ) : null}
        </Box>
      ) : null}

      {type === 'nodata' ? (
        <Box className='message-holder-inline'>
          {/* <Typography className={type}>No Data Found...</Typography> */}
          <Typography align={alignText} className={type}>
            {message ? message : 'No Data Found...'}
          </Typography>
        </Box>
      ) : null}

      {/* {type === 'error' ? <Typography className={type}>{message}</Typography> : null} */}
    </>
  );
};

export default WorkflowMessage;

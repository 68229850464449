import React from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import FormControls from '../FormControls/FormControls';
import CloseIcon from '@mui/icons-material/Close';

export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup, maxWidth } = props;

  return (
    <Dialog
      PaperProps={{ sx: { position: 'fixed', top: 30 } }}
      open={openPopup}
      fullWidth={true}
      maxWidth={maxWidth ? maxWidth : 'md'}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex' }}>
          <Typography variant='h5' component='div' sx={{ flexGrow: '1' }}>
            {title}
          </Typography>
          {/* <Tooltip title='Close'> */}
          <FormControls.ActionButtonControl
            color='error'
            size='sm'
            onClick={() => setOpenPopup(false)}
          >
            <CloseIcon />
          </FormControls.ActionButtonControl>
          {/* </Tooltip> */}
        </Box>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}

import React, { useContext } from 'react';
import { Box } from '@mui/material';

import AuthContext from '../store/auth-context';
import StandardPageWrapper from '../components/Layout/StandardPageWrapper';

import VendorSignupComplete from '../components/Signup/VendorSignupComplete';
import TeamSignupComplete from '../components/Signup/TeamSignupComplete';

const NewAccountPage = () => {
  const authCtx = useContext(AuthContext);

  if (!authCtx.orgDetails) {
    authCtx.logout();
  }

  // const userRole = authCtx.userRole;
  const { orgUuid, orgName, orgStatus, orgType } = authCtx.orgDetails;

  console.log('authCtx.orgDetails');
  console.log(authCtx.orgDetails);
  console.log('orgUuid = ' + orgUuid);
  console.log('orgName = ' + orgName);
  // console.log('orgStatus = ' + orgStatus);
  // console.log('orgType = ' + orgType);

  return (
    <div className='page-container'>
      <Box className='content-container' sx={{ maxWidth: '1200px' }}>
        {orgStatus === 'New' ? (
          <StandardPageWrapper>
            {orgType === 'Restaurant' ? (
              <VendorSignupComplete restaurantUuid={orgUuid} />
            ) : (
              <TeamSignupComplete teamUuid={orgUuid} />
            )}
          </StandardPageWrapper>
        ) : null}
      </Box>
    </div>
  );
};

export default NewAccountPage;

import React from 'react';
import { Box } from '@mui/material';
import { useState, useRef, useContext } from 'react';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import FormControls from '../MUI/FormControls/FormControls';
import Notification from '../MUI/UI/Notification';
import classes from '../CSS/standard-form.module.css';

const OrderStatusNotesForm = (props) => {
  const authCtx = useContext(AuthContext);

  let { statusForUpdate = '', statusNotes = '', orderUuid, onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  const statusNotesRef = useRef();

  // Test Function to send out order notifications after data has been updated and returned.
  // This is being done to speed things up.
  const sendOrderNotifications = async () => {
    console.log('sendOrderNotifications - orderUuid = ' + orderUuid);

    // setNotify({ isOpen: true, message: 'Updating order information.', type: 'info' });

    const apiURL = authCtx.baseApiUrl + 'orders/ordernotifications/' + orderUuid;
    const apiMethod = 'GET';

    const response = await fetch(apiURL, {
      method: apiMethod,
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const responseData = await response.json();
      // await response.json();
      console.log(responseData);
    } else {
      setIsError(true);
      console.log(' ** ERROR ** ');
      setNotify({
        isOpen: true,
        message: 'An Error occurred while sending Order Notifications.',
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    setIsError(false);
    setIsLoading(true);

    const enteredStatusNotes = statusNotesRef.current.value;

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        status: statusForUpdate,
        restaurant_status: statusForUpdate,
        status_notes: enteredStatusNotes,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        statusNotesRef.current.value = '';

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          sendOrderNotifications();

          onClose(true);
        });
      } else {
        return res.json().then((responseData) => {
          setIsError(true);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Status Notes not saved.',
              type: 'error',
            });
          }
        });
      }
    });
  };

  return (
    <div>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <form onSubmit={submitHandler}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          <FormControls.InputControl
            type='text'
            name='status_notes'
            label='Reason Why'
            inputRef={statusNotesRef}
            defaultValue={statusNotes}
            className={classes['mui-control']}
            multiline
            minRows={3}
            maxRows={12}
          />
        </Box>

        <Box component='div' className={classes.actions}>
          <FormControls.ButtonControl text='Reject Order' type='submit' color='primary' />
          <FormControls.ButtonControl
            text='Cancel'
            type='button'
            color='error'
            onClick={() => onClose(false)}
          />
        </Box>
      </form>

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default OrderStatusNotesForm;

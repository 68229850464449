import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const RestaurantDetailsTableSimple = ({ restaurantDetails, userRole, dashboardUrl }) => {
  // console.log('### restaurantDetails ###');
  // console.log(restaurantDetails);

  return (
    <Box sx={{ pt: '20px' }}>
      <table className='table-simple table-simple-pb-10'>
        <tbody>
          {restaurantDetails.foodType ? (
            <tr>
              <td className='table-td-label'>Food Type</td>
              <td className='table-td-text'>{restaurantDetails.foodType}</td>
            </tr>
          ) : null}
          {restaurantDetails.leagueExperience ? (
            <tr>
              <td className='table-td-label'>League Experience</td>
              <td className='table-td-text'>{restaurantDetails.leagueExperience}</td>
            </tr>
          ) : null}
          {restaurantDetails.orderLeadTime ? (
            <tr>
              <td className='table-td-label'>Order Lead Time</td>
              <td className='table-td-text'>{restaurantDetails.orderLeadTime + ' Hours'}</td>
            </tr>
          ) : null}

          {userRole === 'Vendor' && restaurantDetails.status !== 'New' ? (
            <tr>
              <td className='table-td-label'>Payment Account Status</td>
              <td className='table-td-text'>
                {restaurantDetails.paymentAccountStatus}
                {restaurantDetails.paymentAccountStatus ? (
                  <>
                    <span className='pl-20'>
                      <Link
                        to={`${dashboardUrl}vendor-profile/payment-account/${restaurantDetails.restaurantUuid}`}
                      >
                        Details
                      </Link>
                    </span>
                  </>
                ) : (
                  <span>
                    <Link to={`payment-account/${restaurantDetails.restaurantUuid}`}>
                      Create Account
                    </Link>
                  </span>
                )}
              </td>
            </tr>
          ) : null}

          {userRole === 'Admin' ? (
            <tr>
              <td className='table-td-label'>Payment Account Status</td>
              <td className='table-td-text'>
                {restaurantDetails.paymentAccountStatus
                  ? restaurantDetails.paymentAccountStatus
                  : 'Not Setup'}
              </td>
            </tr>
          ) : null}

          {userRole !== 'User' ? (
            <tr>
              <td className='table-td-label'>Status</td>
              <td className='table-td-text'>{restaurantDetails.status}</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    </Box>
  );
};

export default RestaurantDetailsTableSimple;

import React from 'react';
import { Autocomplete, Box, FormControl, TextField } from '@mui/material';

const AutocompleteControl = (props) => {
  const { name, label, value, inputRef, onChange, options, error, placeholder, ...other } = props;

  // console.log(' - SelectControl - ');
  // console.log(' value ');
  // console.log(value);
  // console.log(' options ');
  // console.log(options);
  // console.log('label');
  // console.log(label);

  // const otherValue = value + ' -- ';

  return (
    <Box className='autocomplete-holder'>
      <FormControl fullWidth={true} {...(error ? { error: true } : null)}>
        <Autocomplete
          disablePortal
          options={options}
          size='small'
          autoHighlight
          autoSelect
          onChange={onChange}
          inputValue={value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              InputLabelProps={{ shrink: true }}
              inputRef={inputRef}
              placeholder={placeholder}
            />
          )}
          // {error ? <FormHelperText>{error}</FormHelperText> : null} }
          {...other}
        />
      </FormControl>
    </Box>
  );
};

export default AutocompleteControl;

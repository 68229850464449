import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import StandardPageWrapper from '../Layout/StandardPageWrapper';
import WorkflowMessage from '../UI/WorkflowMessage';
import DeliveryHoursForm from './DeliveryHoursForm';

const DeliveryHours = ({ mode = 'details' }) => {
  console.log(' ***** DeliveryHours ****** ');

  const authCtx = useContext(AuthContext);
  // const userRole = authCtx.userRole;
  const dashboardUrl = authCtx.dashboardUrl;

  const history = useHistory();
  const params = useParams();
  const restaurantUuid = params.restaurantUuid ? params.restaurantUuid : null;

  const pageTitle = 'Delivery Hours';

  console.log('mode = ' + mode);
  console.log('restaurantUuid = ' + restaurantUuid);

  const [deliveryHours, setDeliveryHours] = useState({});
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await fetch(
        authCtx.baseApiUrl + 'deliveryhours/restaurant/' + restaurantUuid,
        {
          headers: {
            Authorization: authCtx.token,
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        console.log('responseData.data');
        console.log(responseData.data);

        // const transformedRecords = responseData.data.delivery_hours.map((record) => {
        //   return {
        //     deliveryFeeUuid: record.deliveryFeeUuid,
        //     restaurantUuid: record.restaurantUuid,
        //     rangeStart: record.rangeStart,
        //     rangeEnd: record.rangeEnd,
        //     fee: record.fee,
        //   };
        // });

        setDeliveryHours(responseData.data.delivery_hours);

        // console.log('transformedRecords');
        // console.log(transformedRecords);
      } else {
        setIsError(true);
        // console.log('responseData');
        // console.log(responseData);
      }
      setIsLoading(false);
      setRefreshData(false);
    };

    fetchData().catch(console.error);
  }, [authCtx.baseApiUrl, authCtx.token, restaurantUuid, refreshData]);

  const displayRestaurantDetailsHandler = () => {
    console.log('displayRestaurantDetailsHandler');
    history.push(dashboardUrl + 'restaurants/details/' + restaurantUuid);
  };

  return (
    <StandardPageWrapper>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      <Box className='title-and-button-holder'>
        <Typography variant='h1' component='h1'>
          {pageTitle}
        </Typography>
        <Box className='button-holder-no-pb'>
          <Button
            variant='contained'
            status='custom'
            color='accent'
            // onClick={() => editDetailsHandler(restaurantUuid)}
            onClick={displayRestaurantDetailsHandler}
          >
            Restaurant Details
          </Button>
        </Box>
      </Box>

      {!isLoading && !isError ? (
        <Box>
          {mode === 'form' ? (
            <DeliveryHoursForm
              restaurantUuid={restaurantUuid}
              deliveryHours={deliveryHours}
              //   // onCancel={hideDeliveryHoursFormHandler}
              //   // onUpdated={deliveryHoursUpdatedHandler}
              //   // onDataUpdated={dataUpdatedHander}
            />
          ) : null}
        </Box>
      ) : null}
    </StandardPageWrapper>
  );
};

export default DeliveryHours;

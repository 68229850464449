import React, { useState } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import SalesTaxMessage from '../Widgets/SalesTaxMessage';
import DeliveryFeeEditForm from '../DeliveryFees/DeliveryFeeEditForm';
import Popup from '../MUI/UI/Popup';
import classes from './../CSS/Orders.module.css';

const OrderDetailsTotalArea = (props) => {
  const {
    orderUuid,
    teamChargeLabel,
    teamCharge,
    restaurantsCut,
    eliteEatsCut,
    deliveryFeeLabel,
    deliveryFee,
    deliveryFeeUnformatted,
    tip,
    orderSubtotal,
    orderTax,
    orderTotal,
    datePaymentForDisplay,
    userRole,
    restaurantStatus,
    refreshData,
  } = props;

  // console.log('######## OrderDetailsTotalArea ########');
  // console.log('props');
  // console.log(props);

  const [openPopup, setOpenPopup] = useState(false);

  const subtotal = orderSubtotal;
  const tax = orderTax;
  const total = orderTotal;

  const editDeliveryFeeHandler = () => {
    // console.log('### editDeliveryFeeHandler ###');
    setOpenPopup(true);
  };

  const closeDeliveryFeeEditFormHandler = () => {
    // console.log('### closeDeliveryFeeEditFormHandler ###');
    setOpenPopup(false);
    refreshData();
  };

  const cancelDeliveryFeeEditFormHandler = () => {
    // console.log('### cancelDeliveryFeeEditFormHandler ###');
    setOpenPopup(false);
  };

  return (
    <Box sx={{ py: 2 }}>
      <table className={classes['table-details']}>
        <tbody>
          <tr>
            <td className={classes['full-width']}>Subtotal</td>
            <td colSpan='2' className={classes['number']}>
              {subtotal}
            </td>
          </tr>

          {teamCharge ? (
            <tr>
              <td>{teamChargeLabel}</td>
              <td colSpan='2' className={classes['number']}>
                {teamCharge}
              </td>
            </tr>
          ) : null}

          {/* {deliveryFee && deliveryFee !== '$0.00' ? ( */}
          {deliveryFee ? (
            <tr>
              <td>{deliveryFeeLabel}</td>
              {userRole === 'Vendor' &&
              (restaurantStatus === 'New' ||
                restaurantStatus === 'Accepted' ||
                restaurantStatus === 'Modified') ? (
                <>
                  <td>
                    <Box>
                      <IconButton
                        sx={{
                          padding: '0px',
                          margin: '0px',
                        }}
                        onClick={() => editDeliveryFeeHandler()}
                      >
                        <EditIcon color='primary' />
                      </IconButton>
                    </Box>
                  </td>
                  <td className={classes['number']}>{deliveryFee}</td>
                </>
              ) : (
                <td colSpan='2' className={classes['number']}>
                  {deliveryFee}
                </td>
              )}
            </tr>
          ) : null}

          {tip ? (
            <tr>
              <td>Gratuity (for the driver)</td>
              <td colSpan='2' className={classes['number']}>
                {tip}
              </td>
            </tr>
          ) : null}

          {tax ? (
            <tr>
              <td>Sales Tax</td>
              <td colSpan='2' className={classes['number']}>
                {tax}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>

      {total ? (
        <>
          <Divider sx={{ pt: '1rem', mb: '1rem' }} />
          <table className={classes['table-details']}>
            <tbody>
              {userRole === 'Vendor' ? (
                <>
                  <tr>
                    <td>Order Total</td>
                    <td className={classes['number']}>{total}</td>
                  </tr>
                  {eliteEatsCut ? (
                    <tr>
                      <td>Elite Eats Payout</td>
                      <td className={classes['number']}>- {eliteEatsCut}</td>
                    </tr>
                  ) : null}
                </>
              ) : (
                <tr className={classes['totals-row']}>
                  <td>{userRole === 'User' ? 'Total' : 'Order Total'}</td>
                  <td className={classes['number']}>{total}</td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : null}

      {eliteEatsCut && userRole !== 'User' ? (
        <>
          <Divider sx={{ pt: '1rem', mb: '1rem' }} />
          <table className={classes['table-details']}>
            <tbody>
              {userRole === 'Admin' ? (
                <>
                  {restaurantsCut ? (
                    <tr className={classes['totals-row']}>
                      <td>Restaurants Payout</td>
                      <td className={classes['number']}>{restaurantsCut}</td>
                    </tr>
                  ) : null}

                  <tr className={classes['totals-row']}>
                    <td>Elite Eats Payout</td>
                    <td className={classes['number']}>{eliteEatsCut}</td>
                  </tr>
                </>
              ) : (
                <>
                  {restaurantsCut ? (
                    <tr className={classes['totals-row']}>
                      <td>Payout Amount</td>
                      <td className={classes['number']}>{restaurantsCut}</td>
                    </tr>
                  ) : null}
                </>
              )}
            </tbody>
          </table>
        </>
      ) : null}

      {!tax ? (
        <Box className='pt-10'>
          <SalesTaxMessage />
        </Box>
      ) : null}

      {datePaymentForDisplay ? (
        // <Box sx={{ pt: '2rem', pb: '2rem' }}>
        //   <Typography variant='h9'>Payment Made</Typography>
        //   <Typography variant='label'>{datePaymentForDisplay}</Typography>
        // </Box>

        <Box sx={{ pt: '2rem', pb: '2rem' }}>
          <Typography variant='labelSmall' align='center'>
            Payment Made: {datePaymentForDisplay}
          </Typography>
        </Box>
      ) : null}

      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        maxWidth='sm'
        title={'Edit ' + deliveryFeeLabel}
      >
        <DeliveryFeeEditForm
          orderUuid={orderUuid}
          deliveryFee={deliveryFeeUnformatted}
          onClose={closeDeliveryFeeEditFormHandler}
          onCancel={cancelDeliveryFeeEditFormHandler}
        />
      </Popup>
    </Box>
  );
};

export default OrderDetailsTotalArea;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

import AuthContext from '../../store/auth-context';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

import { deliveryFeeLabel } from '../../constants/global';

const DeliveryFeeEditForm = ({ orderUuid, deliveryFee, onClose, onCancel }) => {
  const authCtx = useContext(AuthContext);
  // const dashboardUrl = authCtx.dashboardUrl;

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });

  // const [enteredDeliveryFee, setEnteredDeliveryFee] = useState(deliveryFee);

  const deliveryFeeRef = useRef();

  useEffect(() => {
    deliveryFeeRef.current.focus();
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    const apiURL = authCtx.baseApiUrl + 'orders/' + orderUuid;
    const apiMethod = 'PATCH';
    // console.log(' - apiURL = ' + apiURL);
    // console.log(' - apiMethod = ' + apiMethod);

    const enteredDeliveryFee = deliveryFeeRef.current.value;

    // console.log(' - enteredDeliveryFee = ' + enteredDeliveryFee);

    if (!validateDecimalValueEntered(enteredDeliveryFee)) {
      return false;
    }

    setIsLoading(true);

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        delivery_fee: enteredDeliveryFee,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.ok) {
        setIsError(false);

        setNotify({
          isOpen: true,
          message: deliveryFeeLabel + ' Successfully Updated.',
          type: 'success',
        });

        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          deliveryFeeRef.current.value = '';

          onClose();
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: deliveryFeeLabel + ' Not Updated',
              type: 'error',
            });
          }
        });
      }
    });

    setIsLoading(false);
  };

  const validateDecimalValueEntered = (value) => {
    // console.log('validateDecimalValueEntered:  value = ' + value);

    if (isNaN(value) || !/[0-9]|\./.test(value)) {
      // console.log('### NOT A GOOD VALUE ###');

      setNotify({
        isOpen: true,
        message: 'Delivery & Service Fee entered is not valid.  Please enter a numeric value.',
        type: 'error',
      });

      return false;
    } else {
      // console.log('### GOOD VALUE ###');
      return true;
    }
  };

  return (
    <>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {!isLoading && !isError ? (
        <Box className={classes['form-container']}>
          <form onSubmit={submitHandler}>
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'space-between',
              }}
            >
              <FormControls.InputControl
                type='text'
                name='fee'
                label={deliveryFeeLabel}
                required
                inputRef={deliveryFeeRef}
                defaultValue={deliveryFee}
                inputProps={{ type: 'number', min: '0', max: '100000', step: '0.01' }}
                className={classes['mui-control']}
              />

              <Box sx={{ display: 'flex', gap: '10px' }}>
                <FormControls.ButtonControl text='Save' type='submit' color='primary' />
                <FormControls.ButtonControl
                  text='Cancel'
                  onClick={onCancel}
                  type='button'
                  color='neutral200'
                />
              </Box>
            </Box>
          </form>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default DeliveryFeeEditForm;

import React from 'react';
import { Box, Rating, Typography } from '@mui/material';

const RestaurantRatingWidget = (props) => {
  const { rating, numberOfRatings } = props;

  return (
    <>
      {rating && numberOfRatings ? (
        <Box sx={{ display: 'flex', gap: '2rem', pt: '0.5rem' }}>
          <Rating name='rating' value={+rating} precision={0.5} readOnly size='medium' />
          <Typography>
            {numberOfRatings} {+numberOfRatings === 1 ? 'Review' : 'Reviews'}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default RestaurantRatingWidget;

import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import { displayTeamCharge, teamChargeLabel, deliveryFeeLabel } from '../../constants/global';

import { useFormatData } from '../Hooks/useFormatData';

// Font.register({
//   family: 'Times-Roman',
// });

const styles = StyleSheet.create({
  page: {
    fontSize: '13px',
    fontFamily: 'Times-Roman',
  },
  sideBySideSection: {
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'space-between',
  },
  section: {
    margin: 10,
    padding: 10,
    // flexGrow: 1,
  },
  subSection: {
    marginBottom: 10,
  },
  headerText: {
    fontFamily: 'Times-Bold',
    paddingBottom: 10,
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    // paddingTop: 8,
    // paddingBottom: 8,
    padding: 8,
  },
  header: {
    borderTop: 'none',
    // border: '1px solid red',
    fontFamily: 'Times-Bold',
  },
  subHeader: {
    fontFamily: 'Times-Bold',
    // borderTop: 'none',
    textAlign: 'center',
    width: '100%',
    borderTop: '2px solid #EEE',
    paddingTop: '10px',
  },
  subtotal: {
    fontFamily: 'Times-Bold',
    borderTop: 'none',
  },
  total: {
    fontFamily: 'Times-Bold',
    fontSize: '14px',
    borderTop: '2px solid #EEE',
  },
  orderend: {
    borderTop: '2px solid #EEE',
    paddingTop: '20px',
  },
  bold: {
    fontFamily: 'Times-Bold',
  },
  smalltext: {
    paddingTop: '2px',
    fontSize: '10px',
    // verticalAlign: 'bottom',
  },
  column1: {
    width: '10%',
    textAlign: 'right',
    paddingRight: '20px',
  },
  column2: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  // column3: {
  //   width: '20%',
  //   textAlign: 'right',
  //   paddingRight: '20px',
  // },
  column4: {
    width: '20%',
    textAlign: 'right',
  },
  column3wide: {
    width: '80%',
    textAlign: 'right',
    paddingRight: '20px',
  },
  rowFull: {
    width: '100%',
    textAlign: 'right',
  },
  itemName: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    flexWrap: 'wrap',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    fontSize: '12px',
  },
});

const PdfOrderDetailsSubtotal = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
    orderItems,
    additionalCharges,
    selectedRestaurant,
  } = props;

  // recordDetails.subtotal = null;
  // recordDetails.tax = null;
  // recordDetails.teamCharge = null;
  // recordDetails.total = null;

  // console.log('##### PdfOrderDetailsSubtotal - recordDetails #####', recordDetails);
  // console.log('orderItems', orderItems);

  const { FormatCurrencyForDisplay } = useFormatData();

  const showDeliveryInstructions = false;
  const showTeamInstructions = false;
  const showTeamContact = false;
  const showRestaurantPhoneNumber = true;
  const showRestaurantEmail = true;
  const showAdditionalChargesHeader = true;
  const showDescription = false;
  const showDetails = false;

  const orderNumber = recordDetails ? recordDetails.orderNumber : '';
  const orderStatus = recordDetails ? recordDetails.overallOrderStatus : '';

  let showTeamCharge = displayTeamCharge ? displayTeamCharge : false;
  let showTax = false;
  let showTotal = true;

  if (
    recordDetails.restaurantStatus === 'Scheduled' ||
    recordDetails.restaurantStatus === 'Completed'
  ) {
    showTeamCharge = true;
    showTax = true;
    showTotal = true;
  } else {
    showTax = false;
    showTotal = false;
  }

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.sideBySideSection}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Delivery Date</Text>
            {deliveryDateAndTimeForDisplay ? <Text>{deliveryDateAndTimeForDisplay}</Text> : null}
          </View>

          <View style={styles.section}>
            <Text style={styles.headerText}>Order Number</Text>
            {orderNumber ? <Text>{orderNumber}</Text> : null}
          </View>

          <View style={styles.section}>
            <Text style={styles.headerText}>Order Status</Text>
            {orderStatus ? <Text>{orderStatus}</Text> : null}
          </View>
        </View>

        <View style={styles.sideBySideSection}>
          <View style={styles.section}>
            <Text style={styles.headerText}>Delivery Address</Text>
            {recordDetails ? <Text>{recordDetails.teamName}</Text> : null}

            {deliveryLocationForDisplay ? (
              <>
                <Text>{deliveryLocationForDisplay.name}</Text>
                <Text>{deliveryLocationForDisplay.addressLine1}</Text>
                <Text>{deliveryLocationForDisplay.addressLine2}</Text>
              </>
            ) : null}
          </View>

          <View style={styles.section}>
            <Text style={styles.headerText}>Restaurant</Text>
            {selectedRestaurant ? <Text>{selectedRestaurant.name}</Text> : null}

            {selectedRestaurant ? (
              <>
                <Text>{selectedRestaurant.address}</Text>
                <Text>
                  {selectedRestaurant.city +
                    ', ' +
                    selectedRestaurant.state +
                    ', ' +
                    selectedRestaurant.zipcode}
                </Text>
                {showRestaurantPhoneNumber ? <Text>{selectedRestaurant.phone}</Text> : null}
                {showRestaurantEmail ? <Text>{selectedRestaurant.email}</Text> : null}
              </>
            ) : null}
          </View>

          {showTeamContact ? (
            <>
              {Array.isArray(orderContacts) && orderContacts.length > 0 ? (
                <View style={styles.section}>
                  <Text style={styles.headerText}>
                    {orderContacts.length === 1 ? 'Contact' : 'Contacts'}
                  </Text>

                  {orderContacts.map((record, index) => (
                    <View style={styles.subSection} key={index}>
                      <Text>{record.name}</Text>
                      <Text>{record.position}</Text>
                      <Text>{record.phone}</Text>
                      <Text>{record.email}</Text>
                    </View>
                  ))}
                </View>
              ) : null}
            </>
          ) : null}
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            <View style={[styles.row, styles.bold, styles.header]}>
              <Text style={styles.column1}>Qty</Text>
              <Text style={styles.column2}>Item</Text>
              <Text style={styles.column4}>Subtotal</Text>
            </View>

            {Array.isArray(orderItems) && orderItems.length > 0 ? (
              <>
                {orderItems.map((row, i) => (
                  <View key={i} style={styles.row} wrap={false}>
                    <Text style={styles.column1}>{row.quantity}</Text>
                    <View style={styles.column2}>
                      <View style={styles.itemName}>
                        <Text>{row.name}</Text>
                        {row.feeds ? (
                          <Text style={styles.smalltext}>
                            {row.servingSize
                              ? '(Feeds ' + row.feeds + ' - ' + row.servingSize + ') '
                              : '(Feeds ' + row.feeds + ')'}
                          </Text>
                        ) : null}
                      </View>

                      {showDescription && row.description ? (
                        <View style={styles.listItem}>
                          <Text>*</Text>
                          <Text>{row.description}</Text>
                        </View>
                      ) : null}

                      {showDetails && row.details ? (
                        <View style={styles.listItem}>
                          <Text>*</Text>
                          <Text>{row.details}</Text>
                        </View>
                      ) : null}

                      {row.specialInstructions ? (
                        <View style={styles.listItem}>
                          <Text>*</Text>
                          <Text>{row.specialInstructions}</Text>
                        </View>
                      ) : null}
                    </View>
                    <Text style={styles.column4}>{FormatCurrencyForDisplay(row.subtotal)}</Text>
                  </View>
                ))}
              </>
            ) : null}

            {Array.isArray(additionalCharges) && additionalCharges.length > 0 ? (
              <>
                {showAdditionalChargesHeader ? (
                  <View style={[styles.row, styles.subHeader]} wrap={false}>
                    <Text>Additional Charges</Text>
                  </View>
                ) : null}

                {additionalCharges.map((row, i) => (
                  <View key={i} style={styles.row} wrap={false}>
                    <Text style={styles.column1}>{row.quantity}</Text>
                    <View style={styles.column2}>
                      <Text style={styles.column2}>{row.name}</Text>
                      {row.description ? (
                        <View style={styles.listItem}>
                          <Text>*</Text>
                          <Text>{row.description}</Text>
                        </View>
                      ) : null}
                    </View>
                    {/* <Text style={styles.column2}>{row.name}</Text> */}
                    <Text style={styles.column4}>{FormatCurrencyForDisplay(row.subtotal)}</Text>
                  </View>
                ))}
              </>
            ) : null}

            {recordDetails.subtotalFormatted !== null &&
            recordDetails.subtotalFormatted !== 0 &&
            recordDetails.subtotalFormatted !== '' ? (
              <View style={[styles.row, styles.subtotal, styles.orderend]} wrap={false}>
                <Text style={styles.column3wide}>Subtotal</Text>
                <Text style={styles.column4}>{recordDetails.subtotalFormatted}</Text>
              </View>
            ) : null}

            {showTeamCharge &&
            recordDetails.teamChargeFormatted !== null &&
            recordDetails.teamChargeFormatted !== 0 &&
            recordDetails.teamChargeFormatted !== '' ? (
              <View style={[styles.row, styles.subtotal]} wrap={false}>
                <Text style={styles.column3wide}>{teamChargeLabel}</Text>
                <Text style={styles.column4}>{recordDetails.teamChargeFormatted}</Text>
              </View>
            ) : null}

            {recordDetails.deliveryFeeFormatted !== null &&
            recordDetails.deliveryFeeFormatted !== 0 &&
            recordDetails.deliveryFeeFormatted !== '$0.00' &&
            recordDetails.deliveryFeeFormatted !== '' ? (
              <View style={[styles.row, styles.subtotal]} wrap={false}>
                <Text style={styles.column3wide}>{deliveryFeeLabel}</Text>
                <Text style={styles.column4}>{recordDetails.deliveryFeeFormatted}</Text>
              </View>
            ) : null}

            {recordDetails.tipFormatted !== null &&
            recordDetails.tipFormatted !== 0 &&
            recordDetails.tipFormatted !== '' ? (
              <View style={[styles.row, styles.subtotal]} wrap={false}>
                <Text style={styles.column3wide}>Gratuity (for the driver)</Text>
                <Text style={styles.column4}>{recordDetails.tipFormatted}</Text>
              </View>
            ) : null}

            {showTax &&
            recordDetails.taxFormatted !== null &&
            recordDetails.taxFormatted !== 0 &&
            recordDetails.taxFormatted !== '' ? (
              <View style={[styles.row, styles.subtotal]} wrap={false}>
                <Text style={styles.column3wide}>Sales Tax</Text>
                <Text style={styles.column4}>{recordDetails.taxFormatted}</Text>
              </View>
            ) : null}

            {showTotal &&
            recordDetails.calculatedTotalFormatted !== null &&
            recordDetails.calculatedTotalFormatted !== 0 &&
            recordDetails.calculatedTotalFormatted !== '' ? (
              <View style={[styles.row, styles.total]} wrap={false}>
                <Text style={styles.column3wide}>Total</Text>
                <Text style={styles.column4}>{recordDetails.calculatedTotalFormatted}</Text>
              </View>
            ) : null}

            {recordDetails.taxFormatted === null ||
            recordDetails.taxFormatted === 0 ||
            recordDetails.taxFormatted === '' ? (
              <View style={[styles.row]} wrap={false}>
                <Text style={styles.rowFull}>* Sales Tax will be calculated at payment</Text>
              </View>
            ) : null}

            {/* {showTotal &&
            recordDetails.totalFormatted !== null &&
            recordDetails.totalFormatted !== 0 &&
            recordDetails.totalFormatted !== '' ? (
              <View style={[styles.row, styles.total]} wrap={false}>
                <Text style={styles.column3wide}>Total</Text>
                <Text style={styles.column4}>{recordDetails.totalFormatted}</Text>
              </View>
            ) : null} */}
          </View>
        </View>

        {showDeliveryInstructions ? (
          <View style={styles.section}>
            <Text style={styles.headerText}>Delivery Instructions</Text>
            {recordDetails ? <Text>{recordDetails.deliveryInstructions}</Text> : null}
          </View>
        ) : null}

        {showTeamInstructions ? (
          <View style={styles.section}>
            <Text style={styles.headerText}>Team Instructions</Text>
            {recordDetails ? <Text>{recordDetails.teamInstructions}</Text> : null}
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default PdfOrderDetailsSubtotal;

import { useCallback } from 'react';

export const useFormatData = () => {
  const FormatNumber2DecimalPlaces = useCallback((value) => {
    // let returnFormattedValue = typeof value === 'number' ? value.toFixed(2) : value;

    // return returnFormattedValue;

    let returnValue = parseFloat(value);

    returnValue = typeof returnValue === 'number' ? returnValue.toFixed(2) : value;

    return returnValue;
  }, []);

  const FormatCurrencyForDisplay = useCallback((value) => {
    // let returnValue = '';

    // returnValue = typeof value === 'number' ? '$' + value.toFixed(2) : value;

    let returnValue = parseFloat(value);

    returnValue = typeof returnValue === 'number' ? '$' + returnValue.toFixed(2) : value;

    return returnValue;
  }, []);

  return {
    FormatNumber2DecimalPlaces,
    FormatCurrencyForDisplay,
  };
};

import React from 'react';
import { Box, Typography } from '@mui/material';

const PageTitleAndButtons = (props) => {
  return (
    <Box className='title-and-button-holder'>
      <Typography variant='h1' component='h1'>
        {props.pageTitle}
      </Typography>
      <Box>{props.children}</Box>
    </Box>
  );
};

export default PageTitleAndButtons;

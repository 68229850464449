import React from 'react';
import { Box } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';

import PdfOrderForKitchen from './PdfOrderForKitchen';

const PrintOrderForKitchen = (props) => {
  const {
    deliveryDateAndTimeForDisplay,
    deliveryLocationForDisplay,
    recordDetails,
    orderContacts,
    orderItems,
    additionalCharges,
    selectedRestaurant,
    authCtx,
  } = props;

  return (
    <Box sx={{ pt: '2rem' }}>
      <PDFViewer className='pdf-viewer'>
        <PdfOrderForKitchen
          deliveryDateAndTimeForDisplay={deliveryDateAndTimeForDisplay}
          deliveryLocationForDisplay={deliveryLocationForDisplay}
          recordDetails={recordDetails}
          orderContacts={orderContacts}
          orderItems={orderItems}
          additionalCharges={additionalCharges}
          selectedRestaurant={selectedRestaurant}
          authCtx={authCtx}
        />
      </PDFViewer>
    </Box>
  );
};

export default PrintOrderForKitchen;

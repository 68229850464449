import React, { useContext, useState } from 'react';

import AuthContext from '../../../store/auth-context';

import {
  Box,
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const CheckboxListControl = (props) => {
  const authCtx = useContext(AuthContext);

  const globalDietaryOptions = authCtx.globalDietaryOptionsArray;
  // console.table(globalDietaryOptions);

  // const { name, label, value, onChange } = props;
  const { label, value, onChange } = props;

  // console.log(' - CheckboxListControl : props - ');
  // console.table(props);

  // console.log('value');
  // console.log(value);

  const valueArray = value ? value.split(', ') : [];

  // console.log('valueArray');
  // console.table(valueArray);

  // const [state, setState] = useState({
  //   dairyFree: false,
  //   glutenFree: false,
  //   lowCarb: false,
  //   vegan: false,
  //   vegetarian: false,
  // });

  const [options, setOptions] = useState(valueArray);

  const onChangeHandler = (event) => {
    // console.log(' - onChangeHandler - ');
    // console.log(' = event.target.value = ' + event.target.value);
    // setState({ ...state, [event.target.name]: event.target.checked });

    const selectedValue = event.target.value;

    const index = options.indexOf(selectedValue);
    // console.log(' = index = ' + index);
    // options.sort();
    let updatedOptions = [];
    if (index === -1) {
      // setOptions([...options, event.target.value]);
      updatedOptions = [...options, selectedValue];
      updatedOptions.sort();
      // setOptions(updatedOptions);
    } else {
      updatedOptions = options.filter((tag) => tag !== event.target.value);
      // setOptions(options.filter((tag) => tag !== event.target.value));
    }
    setOptions(updatedOptions);

    // const tagsString = updatedOptions.join(', ');
    // console.log('tagsString = ' + tagsString);

    onChange(updatedOptions);
  };

  // console.log(' = options = ');
  // console.log(options);

  // const { dairyFree, glutenFree, lowCarb, vegan, vegetarian } = state;
  // const error = [dairyFree, glutenFree, lowCarb, vegan, vegetarian].filter((v) => v).length !== 2;

  // console.log(' dairyFree = ' + dairyFree);
  // console.log(' glutenFree = ' + glutenFree);
  // console.log(' lowCarb = ' + lowCarb);
  // console.log(' vegan = ' + vegan);
  // console.log(' vegetarian = ' + vegetarian);

  const displayCheckboxItems = () => {
    return globalDietaryOptions.map((option, index) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={value.includes(option.title)}
            onChange={onChangeHandler}
            name={option.id}
          />
        }
        key={index}
        label={option.title}
        value={option.title}
      />
    ));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 0 }} component='fieldset' variant='standard' fullWidth>
        <FormLabel component='legend'>{label}</FormLabel>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
          {displayCheckboxItems()}
        </FormGroup>
        <FormHelperText>Pick all that apply</FormHelperText>

        {/* <div>{options.join(', ')}</div> */}
        {/* <div>Passed in Values</div> */}
        {/* <div>{value}</div> */}
      </FormControl>
    </Box>
  );
};

export default CheckboxListControl;

import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import FormControls from '../FormControls/FormControls';

const ConfirmationDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;

  const displayDialogTitle = false;

  return (
    <Dialog
      open={confirmDialog.isOpen}
      PaperProps={{ sx: { position: 'fixed', top: 30 } }}
      sx={{ p: '4rem' }}
    >
      {displayDialogTitle ? (
        <DialogTitle sx={{ textAlign: 'center' }}>
          <IconButton
            disableRipple={true}
            sx={{
              textAlign: 'center',
              color: 'var(--clr-error)',
              backgroundColor: 'var(--clr-error-super-light)',
              '&:hover': { backgroundColor: 'var(--clr-error-super-light)' },
            }}
          >
            <NotListedLocationIcon sx={{ fontSize: '8rem' }} />
          </IconButton>
        </DialogTitle>
      ) : null}

      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant='h6'>{confirmDialog.title}</Typography>
        {/* <Typography variant='subtitle2'>{confirmDialog.subTitle}</Typography> */}
        {/* <Typography>{confirmDialog.type}</Typography> */}

        {confirmDialog.type === 'Accept Order' ? (
          <Box align='left'>
            {/* <Typography variant='label' className='pb-10'>
              PLEASE NOTE
            </Typography> */}
            <Typography>
              Please Note: Ensure all details and special requests for the order have been
              negotiated and added prior to payment.
            </Typography>
          </Box>
        ) : (
          <Typography variant='subtitle2'>{confirmDialog.subTitle}</Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <FormControls.ButtonControl
          // text='Yes'
          text={confirmDialog.yesButton ? confirmDialog.yesButton : 'Yes'}
          size='sm'
          color='success'
          onClick={confirmDialog.onConfirm}
        />
        <FormControls.ButtonControl
          // text='No'
          text={confirmDialog.noButton ? confirmDialog.noButton : 'No'}
          size='sm'
          color='error'
          onClick={() => {
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

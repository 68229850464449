import React from 'react';

import { Box, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePickerControl = (props) => {
  const { name, label, value, onChange } = props;

  const convertToDefaultEventParameter = (name, value) => {
    // console.log(' - convertToDefaultEventParameter - ');
    // console.log(' - name = ' + name + ' -- value = ' + value + ' - ');
    return { target: { name, value } };
  };

  return (
    <Box sx={{ pt: 0 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          inputFormat='yyyy-MM-dd'
          name={name}
          value={value}
          // onChange={onChange}
          onChange={(date) => onChange(convertToDefaultEventParameter(name, date))}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerControl;

import React from 'react';
import { Button, Tooltip } from '@mui/material';

const ActionButtonControl = (props) => {
  const { size, color, toolTip, variant, children, onClick } = props;
  return (
    <Tooltip title={toolTip}>
      <Button
        variant={variant || 'contained'}
        size={size ? size : 'small'}
        color={color ? color : 'success'}
        onClick={onClick}
        sx={{ p: '0.5rem', mr: '1rem', minWidth: '1rem' }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default ActionButtonControl;

import React from 'react';
import { useParams } from 'react-router-dom';

import StandardPageWrapper from '../Layout/StandardPageWrapper';
import OrdersList from './OrdersList';
import OrderDetails from './OrderDetails';

const Orders = () => {
  const params = useParams();
  const orderUuid = params.orderUuid;

  return (
    <StandardPageWrapper>
      {orderUuid ? <OrderDetails orderUuid={orderUuid} /> : null}
      {!orderUuid ? <OrdersList statusFilter='New' /> : null}
    </StandardPageWrapper>
  );
};

export default Orders;
